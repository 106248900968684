import { api } from '../../api/apiSlice';

const guardianApi = api.injectEndpoints({
    endpoints: (builder) => ({
        addGuardian: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/users/private/addGurdian/${id}`,
                method: 'PATCH',
                body: [...data.users],
            }),
            // invalidatesTags: ['UserDetails'],
            invalidatesTags: ['UserDetails'],
        }),
        getGuardianDetails: builder.query({
            query: ({ studentId, guardianUniqueId }) => ({
                url: `/users/private/getUserGurdian/${studentId}/${guardianUniqueId}`,
            }),
        }),
        deleteGuardian: builder.mutation({
            query: ({ id, guardianId }) => ({
                url: `/users/private/removeGurdian/${id}`,
                method: 'PATCH',
                body: [...guardianId]
            }),
            // invalidatesTags: ['UserDetails'],
            invalidatesTags: ['UserDetails'],
        }),
        updateGuardian: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/users/private/updateGurdian/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['UserDetails'],
        }),
        findGurdian: builder.mutation({
            query: (data) => ({
                url: `/invite-user/find-guardian`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['UserDetails'],
        }),
        makePrimary: builder.mutation({
            query: (data) => ({
                url: `/users/private/makePrimary`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['UserDetails'],
        }),
    }),
});

export const {
    useAddGuardianMutation,
    useGetGuardianDetailsQuery,
    useDeleteGuardianMutation,
    useFindGurdianMutation,
    useUpdateGuardianMutation,
    useMakePrimaryMutation
} = guardianApi;
