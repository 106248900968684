import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';




const RelatedPayments = () => {

    const onClick = () => {
        alert('onclick working fine!');
    };


    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Amount', width: '20%', textAlign: 'left', },
        { id: 'calories', numeric: true, disablePadding: false, label: 'status', width: '30%', textAlign: 'left', sort: true, },
        { id: 'fat', numeric: true, disablePadding: false, label: 'Cycle', width: '20%', textAlign: 'left', sort: true, },
        { id: 'fat', numeric: true, disablePadding: false, label: 'Date', width: '40%', textAlign: 'left', sort: true, },
        { id: 'fat', numeric: true, disablePadding: false, label: '', width: '20%', textAlign: 'left', },

    ];

    const rows = [
        {
            cell: [
                { text: '$100', textcolor: "#001233", },
                { Status: true, background: '#EDEDEF', color: '#676C7B', linebackground: "#979DAC ", statusLine: true, fontSize: '12px', label: 'Due', },
                { text: 'Monthly', textcolor: "#001233", },
                { text: '13-Aug-2024', textcolor: "#001233", },
                { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
            ],

            //======= Action Date Start===== //
            action: [
                { label: 'View', onClick: onClick },
                { label: 'Edit', onClick: onClick },
                { label: 'Delete', onClick: onClick },
            ],
            //======= Action Date End===== //
        },

        {
            cell: [
                { text: '$100', textcolor: "#001233", },
                { Status: true, background: '#EDF7EA', color: '#001233', linebackground: "#41AB37", statusLine: true, fontSize: '12px', label: 'Paid', },
                { text: 'Monthly', textcolor: "#001233", },
                { text: '13-Aug-2024', textcolor: "#001233", },
                { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
            ],

            //======= Action Date Start===== //
            action: [
                { label: 'View', onClick: onClick },
                { label: 'Edit', onClick: onClick },
                { label: 'Delete', onClick: onClick },
            ],
            //======= Action Date End===== //
        },

    ];

    return (
        <>
            <Table
                headCells={headCells}
                rows={rows}
                footer={true}
                innerHeader={true}
                innerTitle={<span style={{ fontSize: '20px' }}>Related payments</span>}
                RowsPerPage={10}

                emptyTableTitle="No users added yet"
                emptyTableNote="No items available"
                btnLabel="ADD USERS"
                showButton={true}
                btnLink="/invited-users"
            //onClickEmptyCard={handleOpenAttendanceEntry}
            />

        </>
    );
};

export default RelatedPayments;
