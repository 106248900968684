import React, { useRef } from 'react';

import EditAbleTable from '../../../components/Table/EditAbleTable';
import { useEffect } from 'react';
import { useState } from 'react';
import { capitalizeFirstLetter } from '../../../components/hooks/HelperFunctions';
import { isValidEmail } from '../../../hooks/HelperFunctions';
import { useCheckEmailMutation, useCreateInviteUserMutation } from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import { useFetcher, useNavigate } from 'react-router-dom';
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from 'react-redux';
import DraggableDialog from '../../../components/hooks/Dialog';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';




function InviteNewUser(props) {

  const { setMatchColumns, setInviteUserButtonEnableDisable, roles, setActiveTab, importedUsers, setImportedUsers, selectedUserType, emailIndex, firstNameIndex, middleNameIndex, LastNameIndex, setSelectedUserType, setAlreadyEmailHas } = props


  const schoolInfo = useSelector(state => state.auth);

  const [checkEmail, checkEmailOptions] = useCheckEmailMutation();
  const [createUser, createUserOptions] = useCreateInviteUserMutation();
  const [loading, setLoading] = useState(false);
  useProtected(checkEmailOptions.error)
  useProtected(createUserOptions.error)
  useEffect(() => {
    setInviteUserButtonEnableDisable(true)
  }, [])

  let initialObject = {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    type: '',
    emailError: '',
    firstNameError: '',
    firstNameLengthError: "",
    lastNameError: '',
    lastNameLengthError: '',
    typeError: ''
  }

  const [usersData, setUsersData] = useState([initialObject]);
  const [checkedEmailSet, setCheckedEmailSet] = useState(new Set());
  //======= Leave this page dialog ===== //
  const [leaveDialog, setOpenDialogleave] = useState(false);
  const handleOpenleave = () => {
    setOpenDialogleave(true);
  };
  const handleCloseDialogleave = () => {
    setOpenDialogleave(false)
  };
  //======= Leave this page dialog ===== //



  // Store cursor position

  const inputRef = useRef(null);

  const [inputLast, setInputLast] = useState(null)
  const [inputCurrent, setInputCurrent] = useState(null)
  const [lastInputRef, setLastInputRef] = useState(null)
  const [lastSelectionStart, setLastSelectionStart] = useState(-1)
  const onChangeHandler = (e, fieldName, index) => {
    e.preventDefault()
    const { value } = e.target;

    setInputCurrent(e)
    setInputLast(inputCurrent)
    setLastSelectionStart(e.target.selectionStart)


    const updatedUsersData = [...usersData];
    const textData = updatedUsersData[index][fieldName]

    updatedUsersData[index][fieldName] = value;
    if (fieldName === 'type') {
      updatedUsersData[index]['role'] = (value === 'parent' || value === 'student') ? '' : updatedUsersData[index]['role']
    }
    setUsersData(prevUsersData => {
      const updatedUsersData = [...prevUsersData];
      updatedUsersData[index][fieldName] = value;
      // setInputLast(e);
      return updatedUsersData;
    });
  }
  const onChangeHandlerName = (e, fieldName, index) => {
    e.preventDefault()

    const { value } = e.target;
    if (!/^[A-Za-z\s]*$/.test(value)) {
      return;
    }

    setInputCurrent(e)
    setInputLast(inputCurrent)
    setLastSelectionStart(e.target.selectionStart)

    const textData = usersData[index][fieldName]


    setUsersData(prevUsersData => {
      const updatedUsersData = [...prevUsersData];
      updatedUsersData[index][fieldName] = value;
      return updatedUsersData;
      setInputLast(e.target);
    });
  };

  const handleremove = (index) => {
    const updatedUsersData = usersData?.filter((user, i) => {
      return i !== index
    })
    setUsersData(updatedUsersData);
  }


  const addNewUser = () => {
    const updatedUsersData = [...usersData, initialObject];
    setUsersData(updatedUsersData);
  }

  const [selectedLocation, setSelectedLocation] = useState('');
  const [rolesOptions, setRolesOptions] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (importedUsers?.length > 0) {
      let newUsersData = []
      importedUsers?.map((user) => {
        let newObj = { ...initialObject, type: selectedUserType }
        newObj.email = user[emailIndex] || ''
        newObj.firstName = user[firstNameIndex] || ''
        newObj.middleName = user[middleNameIndex] || ''
        newObj.lastName = user[LastNameIndex] || ''
        if (newObj.email) {
          newUsersData.push(newObj);
        }
      })
      setUsersData(newUsersData);
    }
  }, [importedUsers])


  useEffect(() => {
    setRows(converDataToCell(usersData))
  }, [usersData])


  useEffect(() => {
    let changesValueIndex = lastSelectionStart

    if (inputCurrent?.target?.selectionStart) {
      inputCurrent.target.setSelectionRange(changesValueIndex, changesValueIndex)
    }
  }, [rows, inputLast, inputCurrent, lastSelectionStart])


  const findFirstDifferenceIndex = (str1, str2) => {
    const minLength = Math.min(str1?.length, str2?.length);
    for (let i = 0; i < minLength; i++) {
      if (str1[i] !== str2[i]) {
        return i;
      }
    }
    // If they are the same up to the length of the shortest string
    if (str1?.length !== str2?.length) {
      return minLength;
    }
    return -1;
  }




  useEffect(() => {
    setRolesOptions(getPreparedArray(roles))
  }, [roles])

  const getPreparedArray = (data) => {
    return data?.map((item) => ({
      value: item?.name,
      label: capitalizeFirstLetter(item?.name)
    }));
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const checkEmailHandler = async (event, obj, index) => {
    let error = 0
    if (!isValidEmail(event.target.value) || event.target.value.trim() === '') {
      obj.emailError = "Enter a valid email address"
      error++
    }
    let result = await checkEmail({ email: (event.target.value === '') ? '***' : event.target.value })

    if (result?.data && result?.data?.error) {
      // setAlreadyEmailHas((prev) => prev + 1)
      obj.emailError = "Email already exists"
      error++
    }
    if (error === 0) obj.emailError = ""

    let newUserData = usersData.map((userObj, i) => {
      if (i === index) {
        return obj
      } else {
        return userObj
      }
    })
    setUsersData(newUserData)
  }

  const checkEmailOnSubmit = async (val, obj, index) => {
    let emailError = ""
    if (!isValidEmail(val) || val.trim() === '') {
      emailError = "Enter a valid email address"
      return emailError;
    }
    let result = await checkEmail({ email: (val === '') ? '***' : val })
    if (result?.data?.body?.data !== null && result?.data?.error) {
      emailError = "Email already exists"
      return emailError;
    } else {
      emailError = "";
    }
    return emailError;
  }



  const converDataToCell = (data) => {

    if (data?.length > 0) {
      let cells = data?.map((singleData, index) => {
        //selectedUserType from import users
        if (selectedUserType) {
          singleData.type = selectedUserType
        }
        // console.log(singleData)
        return {
          cell: [
            {
              TextField: true,
              type: "text",
              onChange: (e) => onChangeHandler(e, 'email', index),
              TextFieldValue: singleData?.email,
              placeholder: "e.g. abdullah@gmail.com",
              onBlur: (e) => checkEmailHandler(e, singleData, index),
              textTransform: "lowercase",
              error: (singleData?.email.length === 0 || singleData.emailError != "" ? singleData.emailError != "" ? "error" : "" : ""),
              ref: inputRef,
              // error: (singleData.emailError != "") ? "error" : "",
              errorTooltip: (singleData.emailError != "") ? true : false,
              tooltipMessage: (singleData.emailError != "") ? singleData.emailError : "",
              id: `email${index}`,
              autocomplete: "off"
            },
            {
              TextField: true,
              type: "text",

              onChange: (e) => onChangeHandlerName(e, 'firstName', index),
              TextFieldValue: singleData?.firstName,
              placeholder: "First name",
              textTransform: "capitalize",
              ref: inputRef,
              error: (singleData?.firstName.length === 0 || singleData?.firstNameLengthError ? singleData.firstNameError != "" || singleData.firstNameLengthError != "" ? "error" : "" : ""),
              id: `firstName${index}`
            },
            {
              TextField: true,
              type: "text",
              onChange: (e) => onChangeHandlerName(e, 'middleName', index),
              ref: inputRef,
              TextFieldValue: singleData?.middleName,
              placeholder: "Middle name",
              textTransform: "capitalize",
              id: `middleName${index}`
            },
            {
              TextField: true,
              type: "text",
              onChange: (e) => onChangeHandlerName(e, 'lastName', index),
              TextFieldValue: singleData?.lastName,
              ref: inputRef,
              placeholder: "Last name",
              textTransform: "capitalize",
              error: (singleData?.lastName.length === 0 || singleData?.lastNameLengthError ? singleData.lastNameError != "" || singleData.lastNameLengthError != "" ? "error" : "" : ""),
              id: `lastName${index}`
            },
            {
              SelectFiled: true,
              options: options,
              SelectHandleChanges: (e) => onChangeHandler(e, 'type', index),
              selectedOption: singleData.type,
              placeholder: "Select user type",
              SelectError: (singleData?.type.length === 0 ? singleData.typeError != "" ? "SelectError" : "" : ""),
              ref: inputRef,
              // error: (singleData?.type.length === 0 ? singleData.typeError != "" ? "error" : "" : ""),
              SelectError: (singleData?.type.length === 0 ? singleData.typeError != "" ? "SelectError" : "" : ""),
              // SelectError: 'SelectError',
              error: "error"
            },
            { RemoveButton: true, index, }

          ]
        }
      }
      )
      setSelectedUserType('')
      return cells
    } else {
      return [];
    }
  }

  // useEffect(() => {
  //   console.log(singleData, 345)
  // }, [singleData])

  const options = [
    {
      label: "Parent",
      value: "parent",
    },

    {
      label: "Student",
      value: "student",
    },
    {
      label: "Faculty",
      value: "faculty",
    },
    {
      label: "Staff",
      value: "staff",
    }
  ]


  const headCells = [
    { label: "Email", width: "25%", },
    { label: "First Name", width: "15%" },
    { label: "Middle Name", width: "15%" },
    { label: "Last Name", width: "15%" },
    { label: "User Type", width: "15%" },
    { label: "", width: "15%", },
  ];

  const isDataValidated = (data) => {
    // if (!data.schoolId) return false;
    for (const item of data.users) {
      for (const [key, val] of Object.entries(item)) {
        if ((key === 'email' || key === 'firstName' || key === 'lastName' || key === 'type') && !val) return false;
        // if ((key === 'email' || key === 'role') && !val) return false;
      }
    }
    return true;
  };

  const checkAll = async () => {
    let errorNumber = 0

    let newUsersArray = [...usersData]

    for (let i = 0; i < newUsersArray.length; i++) {
      let isEmailValid = await checkEmailOnSubmit(newUsersArray[i].email, newUsersArray, i)
      if (isEmailValid != "") {
        newUsersArray[i].emailError = isEmailValid
        errorNumber++;
      } else {
        newUsersArray[i].emailError = ""
      }
      if (newUsersArray[i].firstName === "") {
        newUsersArray[i].firstNameError = "Required"
        errorNumber++;
      } else {
        newUsersArray[i].firstNameError = ""
      }

      if (newUsersArray[i].firstName?.length > 100) {
        newUsersArray[i].firstNameLengthError = "Must not exceed 100 characters."
        toast.error("The first name can't have more than 100 characters.")
        errorNumber++;
      } else {
        newUsersArray[i].firstNameLengthError = ""
      }
      if (newUsersArray[i].lastName === "") {
        newUsersArray[i].lastNameError = "Required"
        errorNumber++;
      } else {
        newUsersArray[i].lastNameError = ""
      }
      if (newUsersArray[i].lastName?.length > 100) {
        newUsersArray[i].lastNameLengthError = "Must not exceed 100 characters."
        toast.error("The last name can't have more than 100 characters.")
        errorNumber++;
      } else {
        newUsersArray[i].lastNameLengthError = ""
      }
      if (newUsersArray[i].type === "") {
        newUsersArray[i].typeError = "Required"
        errorNumber++;
      } else {
        newUsersArray[i].typeError = ""
      }
    }
    setUsersData(newUsersArray)

    return (errorNumber > 0) ? false : true
  }

  const handleAddInviteUser = async () => {
    setLoading(true)
    if (!isTheActionPermitted('inviteUsers')) {
      toast.error("You don't have access to this feature")
      return false
    }
    let isValid = await checkAll();

    let usersNewData = usersData?.map(({ email, name, firstName, middleName, lastName, type }) => ({ email, name, firstName, middleName, lastName, userType: type }));

    const emailErrors = usersData?.filter((users) => users?.emailError === "Email already exists")
    setAlreadyEmailHas(emailErrors?.length);
    try {
      const data = {
        schoolId: schoolInfo?.user?.schoolId,
        invitedBy: schoolInfo?.user?._id,
        users: usersNewData
      };

      if (!isValid) {
        toast.error("Please fill all the required fields")
        setTimeout(() => {
          setLoading(false)
        }, 2000);

        return;
      }

      const result = await createUser(data);
      if (!result?.data?.error) {
        toast.success("Invitation sent successfully");

        setActiveTab(0)
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false)
  };

  const handleClose = () => {
    setLoading(false);
  };

  useEffect(() => { console.log(loading, 500) }, [loading])

  return (
    <div className="InviteNewUser">

      <EditAbleTable
        headCells={headCells}
        rows={rows}
        SubmitButtonLabel="Send invitation"
        handleAddNew={addNewUser}
        handleremove={handleremove}
        SubmitOnClick={handleAddInviteUser}
        usersData={usersData}

      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // open={checkEmailOptions.isLoading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      {/* Delete Group*/}
      <DraggableDialog
        openDialog={leaveDialog}
        handleCloseDialog={handleCloseDialogleave}
        //handleConfirmCloseDialog={deleteUserHandler}
        title="Leave this page?"
        body={<>It looks like you’re in the middle of inviting users. <br /> You will loose these info if you leave.</>}
        ModalFooter={true}
        actionButton="Leave"
        cancelButton="Stay"
      />

    </div>


  );
}

export default InviteNewUser;
