import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import '../../../assets/css/users.css';
import TextField from '../../../components/Form/TextField/TextField';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import { validateFormData } from '../../../utils/form';
import { truncateText } from "../../../hooks/HelperFunctions"

import {
  useGetCourseDetailsQuery,
  useCreateCourseMutation,
  useUpdateClassMutation,
  useDeleteCourseTeachersMutation
} from '../../../redux/features/classes/classApi';

import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import SelectField from '../../../components/Form/SelectField/SelectField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import SelectPrimaryTeacher from './SelectPrimaryTeacher';
import { FormHelperText, IconButton } from '@mui/material';

import MultipleSelect from '../../../components/Form/MultipleSelet/MultipleSelet';
import SingleSelect from '../../../components/Form/SingleSelect/SingleSelect';
import { useGetUsersByUserTypesMutation } from '../../../redux/features/users/userApi';











const CreateCourses = (props) => {
  const {
    schoolYearOptions,
    setSchoolYearOptions,
    classLevelOptions,
    setClassLevelOptions,
    schoolYear,
    setSchoolYear,
    classLevel,
    setClassLevel,
    handleClose,
    id,
    type,
    courseObject,
    setCourseObject,
    courseErrors,
    setCourseErrors,
    schoolYearList,
    subjectAreaOptions,
    isSameCourseCode
  } = props

  const [updateCourse, updateCourseOptions] = useUpdateClassMutation();
  const [createCourse, createCourseOptions] = useCreateCourseMutation();
  const [removeCourseTeachers, removeCourseTeachersOptions] = useDeleteCourseTeachersMutation()
  const [getUsersByUserTypes, getUsersByUserTypesOptions] = useGetUsersByUserTypesMutation();

  const {
    data: courseDetails,
    isLoading: isCourseDetailsLoading,
    isFetching: isCourseDetailsFetching,
    error: courseDetailsError,
  } = useGetCourseDetailsQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });



  useProtected(courseDetailsError);
  useProtected(createCourseOptions.error);
  useProtected(updateCourseOptions.error);
  useProtected(removeCourseTeachersOptions.error);

  // useProtected(updateCourseOptions.error);


  const [state, setState] = React.useState({
    checkedA: true,
  });



  const [name, setName] = useState('')
  const [room, setRoom] = useState('')
  const [teacher, setTeacher] = useState({})
  const [section, setSection] = useState('')
  const [building, setBuilding] = useState('')
  const [termOptions, setTermOptions] = useState([])
  const [tempTeacher, setTempTeacher] = useState({})
  const [primaryTeacher, setPrimaryTeacher] = useState('')
  const [courseTeachers, setCourseTeachers] = useState([])
  const [classCodePrefix, setClassCodePrefix] = useState('')
  const [classCodeNumber, setClassCodeNumber] = useState('')
  const [userTypesDatas, setUserTypesDatas] = useState([]);
  const [subjectArea, setSubjectArea] = useState([])

  const getUsersByUserTypesData = async () => {
    const result = await getUsersByUserTypes({ userTypes: "faculty,staff,owner,teacher", data: "" })
    if (result?.data?.body?.data) {

      setUserTypesDatas(result?.data?.body?.data)
    }
  }


  useEffect(() => {
    if (id != "" && courseDetails && !isCourseDetailsLoading && !isCourseDetailsFetching) {
      let data = courseDetails.body.data;
      let newObject = { ...data }

      setCourseObject({ ...newObject })
      // setName(data.name)
      // setSchoolYear(data.schoolYear)
      // setClassLevel(data.classLevel)
      // setSection(data.section)
      // setRoom(data.room)
      // setBuilding(data.building)
      // setClassCodePrefix(data.classCodePrefix)
      // setClassCodeNumber(data.classCodeNumber)
      // setPrimaryTeacher(data?.teachers?.find(teacher => teacher?.teacherType === 'primary_teacher')?.name)
      // setCourseTeachers(data?.teachers)
    }
  }, [id, courseDetails, isCourseDetailsLoading, isCourseDetailsFetching]);



  const [errors, setErrors] = useState({
    name: '',
    schoolYear: '',
    classLevel: '',
    classCodePrefix: '',
    classCodeNumber: ''
  });

  const handleInputChange = (e) => (setState) => {
    setErrors((prev) => {
      prev[e.target.name] = '';
      return prev;
    });
    if (e.target.name === "classCodePrefix") {
      let val = e.target.value.replace(/[^a-zA-Z]/g, '')
      setState(val.toUpperCase());
      return false
    }
    if (e.target.name === "classCodeNumber") {
      let val = e.target.value.replace(/[^0-9]/g, '')
      setState(val);
      return false
    }
    setState(e.target.value);
  };
  const handleOptionChange = (e, setState) => {
    setState(e.props.value)
  };

  const handleAddClass = async () => {
    let teacherObj = {
      name: `${teacher?.firstName} ${teacher?.lastName}`,
      id: teacher?._id || '',
      email: teacher?.email || '',
      role: teacher?.role || '',
      teacherType: 'primary_teacher'
    }
    let data = {
      name,
      schoolYear,
      classLevel,
      section,
      room,
      building,
      classCodePrefix,
      classCodeNumber,
      teachers: [teacherObj],
      status: 'draft'
    }
    if (id) delete data.status

    const isValid = validateFormData(data, setErrors, {
      name: 'Enter name',
      schoolYear: 'Enter school year',
      classLevel: 'Enter school grade',
      classCodePrefix: 'Enter class code prefix',
      classCodeNumber: 'Enter class code number',
    });
    if (!isValid) {
      return;
    }
    let result = null;
    if (id) {

      if ((Object.keys(teacher).length) > 0) {
        // let teachersExceptPrimary = courseDetails?.teachers?.filter(teacher => teacher.teacherType !== 'primary_teacher')

        courseTeachers?.map((teacherSingle) => {
          if (teacherSingle?.teacherType !== 'primary_teacher' && teacherSingle?.id !== teacherObj?.id) {
            data.teachers.push(teacherSingle)
          }
        })

      }
      result = await updateCourse({ ...data, id });

    } else {
      result = await createCourse(data);
    }

    if (!result?.data?.error) {
      if (id) {
        toast.success('Course updated');
      } else {
        toast.success('Course created');
      }

      setName('')
      setSchoolYear('')
      setClassLevel('')
      setSection('')
      setRoom('')
      setBuilding('')
      setClassCodePrefix('')
      setClassCodeNumber('')
      setPrimaryTeacher('')
      handleClose();
    } else {
      toast.error(result?.data?.message);
    }
  };


  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Twenty",
      value: "Twenty",
    },

    {
      label: "Twentydd",
      value: "Twentyd",
    },

  ]

  const setTeacherHandler = () => {
    if (Object?.keys(tempTeacher)?.length < 1) {
      toast.error("Please select at least one user")
      return false
    }
    handleClosePrimaryTeacherDrawer()
    setTeacher(tempTeacher)
    setPrimaryTeacher(tempTeacher?.name ? tempTeacher?.name : `${tempTeacher?.firstName} ${tempTeacher?.lastName}`)
  }

  // Add Primary Teacher //
  const [openPrimaryTeacherDrawer, setOpenPrimaryTeacher] = useState(false);
  const handlePrimaryTeacherDrawerOpen = () => {
    setOpenPrimaryTeacher(true);
    getUsersByUserTypesData()
  };
  const handleClosePrimaryTeacherDrawer = () => {
    setOpenPrimaryTeacher(false);
  };

  const getDataMap = (dataArray, primaryField, labelField, additionalField = "", separator = " ", conditionalField = "", conditionalValue = "", skipData = false) => {
    const result = [];
    if (typeof dataArray !== 'undefined') {
      dataArray.forEach(element => {

        if (typeof element[labelField] !== 'undefined' && element[labelField] !== null) {
          const label = additionalField === "" ? `${element[labelField]}` : `${element[labelField]}${separator}${element[additionalField]}`

          if (conditionalField?.length > 0) {
            if (skipData) {
              if (element[conditionalField] != conditionalValue) {
                result.push({ value: `${element[primaryField]}`, label: label });
              }
            } else {
              if (element[conditionalField] == conditionalValue) {
                result.push({ value: `${element[primaryField]}`, label: label });
              }
            }

          } else {
            result.push({ value: `${element[primaryField]}`, label: label });
          }

        }
      });
    }
    return result;
  }



  // === Multi Select == //
  const [value, setvalue] = React.useState([]);
  const handleChange = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setvalue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let newObject = { ...courseObject, [fieldName]: typeof value === 'string' ? value.split(',') : value }
    setCourseObject(newObject)
  };
  // === Multi Select == //
  const [academicYear, setAcademicYear] = React.useState([]);
  const handleChangeAcademicYear = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setAcademicYear(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let newObject = { ...courseObject, [fieldName]: typeof value === 'string' ? value.split(',') : value }
    setCourseObject(newObject)
  };
  // === Multi Select == //
  const [academicTerm, setAcademicTerm] = React.useState([]);
  const handleChangeAcademicTerm = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setAcademicTerm(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let newObject = { ...courseObject, [fieldName]: typeof value === 'string' ? value.split(',') : value }
    setCourseObject(newObject)
  };

  const setLabelAsValueForMultiSelect = (classLevelOptions) => {
    let optionArray = classLevelOptions?.map(option => {
      return option.label
    })
    return optionArray
  }

  const home = [
    '1',
    '2',
    '3',
  ];

  // === Multi Select End== //

  const getPreparedArray = (data) => {
    return data.map((item) => ({
      value: item.termName,
      label: item.termName,
    }));
  };

  const onChangeHandler = (val, e, fieldName) => {
    console.log(val, e, fieldName,)
    if (fieldName === 'acededemicYear') {
      let schoolYearObject = schoolYearList?.body?.data?.find(schoolYearSingle => schoolYearSingle.name === e.target.value)
      setTermOptions(getPreparedArray(schoolYearObject?.terms || []))

    }

    let newObject = { ...courseObject, [fieldName]: e.target.value }
    setCourseObject(newObject)
  }
  const maxClassRoomSize = 100000;
  const onChangeHandlerClassRoomSize = (val, e, fieldName) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue > maxClassRoomSize) {
      e.preventDefault();

      toast.warn(`The maximum allowed number of students is ${maxClassRoomSize}`);
      return;
    }
    let newObject = { ...courseObject, [fieldName]: e.target.value };
    setCourseObject(newObject);

  }

  const onChangeHandlerForSecondDepthCodePrefix = (val, e, parentFieldName, fieldName) => {
    const inputValue = e.target.value;
    if (!/^[A-Za-z]*$/.test(inputValue)) {
      return;
    }

    let newObject = {
      ...courseObject,
      [parentFieldName]: {
        ...courseObject[parentFieldName],
        [fieldName]: inputValue?.toUpperCase()
      }
    };
    setCourseObject(newObject);
  };
  const onChangeHandlerForSecondDepth = (val, e, parentFieldName, fieldName) => {
    let newObject = {
      ...courseObject,
      [parentFieldName]: {
        ...courseObject[parentFieldName],
        [fieldName]: e.target.value?.toUpperCase()
      }
    }
    setCourseObject(newObject)
  }

  useEffect(() => {
    let newObject = { ...courseObject, primaryTeacher: teacher }
    setCourseObject(newObject)
  }, [teacher])


  useEffect(() => {
    console.log(courseObject, 345)
  }, [courseObject])

  // === Multi Select term == //
  const [valueTerm, setvalueTerm] = React.useState([]);
  const handleChangeTerm = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setvalueTerm(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let newObject = { ...courseObject, [fieldName]: typeof value === 'string' ? value.split(',') : value }
    setCourseObject(newObject)
  };
  useEffect(() => {
    const termLabels = termOptions.map((item) => item.label);
    const filterTermData = valueTerm?.every((term) => termLabels?.includes(term))
    let data = []
    if (!filterTermData) {
      setvalueTerm(data);
    }
  }, [termOptions])

  console.log(courseErrors?.subjectArea, 54)

  return (



    <>

      {isCourseDetailsLoading ||
        createCourseOptions.isLoading ||
        updateCourseOptions.isLoading ? (
        <Loader
          height="80px"
          width="80px"
          borderWidth="8px"
          isFullWidth={true}
          isOverlay={true}
        />
      ) : (
        <>
          <div className='FormFieldContainer'>
            {/* <div className='FloatLabelCol' style={{ marginTop: Object.keys(courseObject?.subjectArea).length === 0 && '-24px' }}>Subject Area<span className='requiredAsterisk'>*</span></div> */}
            <div className='FloatLabelCol' style={{ marginTop: courseErrors?.subjectArea === "Required" && '-24px' }}>Subject Area<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>
              {/* <SelectField
                label="Select subject area"
                name="schoolYear"
                options={subjectAreaOptions}
                selectedOption={(id != "" && courseObject?.subjectArea?.subjectArea) ? courseObject?.subjectArea?.subjectArea : courseObject?.subjectArea}
                helperText={courseErrors?.subjectArea}
                error={courseErrors?.subjectArea}
                handleChange={(val) => onChangeHandler(val, val, 'subjectArea')}
              />

              <SingleSelect
                Placeholder="Select subject area"
                option={setLabelAsValueForMultiSelect(schoolYearOptions)}
                // handleChange={(e) => handleChangeAcademicYear(e, 'acededemicYear')}
                handleChange={(e) => onChangeHandler(e, e, "acededemicYear")}
                value={courseObject.acededemicYear}
                helperText={courseErrors?.subjectArea}
                error={courseErrors?.subjectArea}
                errorHelperText={courseErrors?.subjectArea}
              /> */}
              <SingleSelect
                Placeholder="Select subject area"
                option={setLabelAsValueForMultiSelect(subjectAreaOptions)}

                handleChange={(e) => onChangeHandler(e, e, "subjectArea")}
                value={(id !== "" && courseObject?.subjectArea?.subjectArea) ? courseObject?.subjectArea?.subjectArea : courseObject?.subjectArea}
                helperText={courseObject?.subjectArea?.length === 0 ? courseErrors?.subjectArea : ""}
                error={Object.keys(courseObject?.subjectArea).length === 0 ? courseErrors?.subjectArea : ""}
                errorHelperText={Object.keys(courseObject?.subjectArea).length === 0 ? courseErrors?.subjectArea : ""}
              />
            </div>
          </div>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol' style={{ marginTop: courseErrors?.name === "Required" && '-24px' }}>Course Name<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>
              <TextField
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                placeholder="Enter course name"
                count={60}
                name={'name'}
                required={true}
                helperText={courseObject.name?.length === 0 ? courseErrors?.name : ""}
                error={courseObject.name?.length === 0 ? courseErrors?.name : ""}
                value={courseObject.name}
                handleChange={(val, e) => onChangeHandler(val, e, 'name')}

              />
            </div>
          </div>


          <div className='FormFieldContainer alignTop'>
            <div className='FloatLabelCol' style={{ marginTop: '14px' }}>Course Code<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>

              <Grid container spacing={2}>

                <Grid item md={6} xs={12}>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter code prefix"
                    count={4}
                    name={'courseCode_codePrefix'}
                    required={true}
                    type="text"
                    helperText={courseObject?.courseCode?.codePrefix.length === 0 ? courseErrors?.courseCode_codePrefix : ""}
                    error={courseObject?.courseCode?.codePrefix.length === 0 ? courseErrors?.courseCode_codePrefix : ""}
                    value={courseObject?.courseCode?.codePrefix}
                    showErrorMsg={false}
                    handleChange={(val, e) => onChangeHandlerForSecondDepthCodePrefix(val, e, 'courseCode', 'codePrefix')}
                    inputProps={{
                      pattern: "[A-Za-z]*", // Accepts only alphabets
                      title: "Please enter alphabets only" // Error message if pattern is not matched
                    }}
                  />

                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter code"
                    count={4}
                    name={'courseCode_code'}
                    number="number"
                    required={true}
                    helperText={courseObject?.courseCode?.code?.length === 0 ? courseErrors?.courseCode_code : ""}
                    error={courseObject?.courseCode?.code?.length === 0 ? courseErrors?.courseCode_code : ""}
                    value={courseObject?.courseCode?.code}
                    showErrorMsg={false}
                    handleChange={(val, e) => onChangeHandlerForSecondDepth(val, e, 'courseCode', 'code')}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className='PreviewSection'>
                    Course code preview:&nbsp; <span>{courseObject?.courseCode?.codePrefix} {courseObject?.courseCode?.code}</span>
                  </div>
                  {(courseErrors?.sectionCode_codePrefix && courseErrors?.sectionCode_code) ? <p style={{ color: "#d32f2f" }}>Required</p> : ""}

                  {isSameCourseCode() && <p style={{ color: "#d32f2f" }}>Course code already exists</p>}
                </Grid>

              </Grid>

            </div>
          </div>

          {(id == "") &&
            <>
              <div className='divider'></div>

              <div className='FormHeader'>
                <h1>Section</h1>
                <p>You can add additional section from the course details screen.</p>
              </div>


              <div className='FormFieldContainer'>
                <div className='FloatLabelCol' style={{ marginTop: courseErrors?.sectionName === "Required" && '-24px' }}>Section Name<span className='requiredAsterisk'>*</span></div>
                <div className='FormFieldCol'>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter section name"
                    count={20}
                    name={'section'}
                    required={true}
                    helperText={courseObject?.sectionName?.length === 0 ? courseErrors?.sectionName : ''}
                    error={courseObject?.sectionName?.length === 0 ? courseErrors?.sectionName : ""}
                    value={courseObject.sectionName}
                    handleChange={(val, e) => onChangeHandler(val, e, 'sectionName')}
                  />
                </div>
              </div>


              <div className='FormFieldContainer'>
                <div className='FloatLabelCol' style={{ marginTop: courseErrors?.classLevel === "Required" && '-24px' }}>Class/Level<span className='requiredAsterisk'>*</span></div>
                <div className='FormFieldCol'>
                  <MultipleSelect
                    Placeholder="Select class/level"
                    option={setLabelAsValueForMultiSelect(classLevelOptions)}
                    handleChange={(e) => handleChange(e, 'classLevel')}
                    value={value}
                    helperText={value?.length === 0 ? courseErrors?.classLevel : ''}
                    error={value?.length === 0 ? courseErrors?.classLevel : ""}
                    errorHelperText={value?.length === 0 ? courseErrors?.classLevel : ""}
                  />
                </div>
              </div>


              <div className='FormFieldContainer'>
                <div className='FloatLabelCol' style={{ marginTop: courseErrors?.acededemicYear === "Required" && '-24px' }}>Academic Year<span className='requiredAsterisk'>*</span></div>
                <div className='FormFieldCol'>

                  <SingleSelect
                    Placeholder="Select academic year"
                    option={setLabelAsValueForMultiSelect(schoolYearOptions)}
                    // handleChange={(e) => handleChangeAcademicYear(e, 'acededemicYear')}
                    handleChange={(e) => onChangeHandler(e, e, "acededemicYear")}
                    value={courseObject.acededemicYear}
                    helperText={Object.keys(courseObject.acededemicYear).length === 0 || courseObject.subjectArea === "" ? courseErrors?.acededemicYear : ""}
                    error={Object.keys(courseObject.acededemicYear).length === 0 ? courseErrors?.acededemicYear : ""}
                    errorHelperText={Object.keys(courseObject.acededemicYear).length === 0 ? courseErrors?.acededemicYear : ""}
                  />
                  {/* <SelectField
                    label="Select academic year"
                    Placeholder="Select class/level"
                    options={schoolYearOptions}
                    selectedOption={courseObject.acededemicYear}
                    helperText={courseErrors?.acededemicYear}
                    error={courseErrors?.acededemicYear}
                    handleChange={(val) => onChangeHandler(val, val, 'acededemicYear')}
                  /> */}
                </div>
              </div>

              <div className='FormFieldContainer'>
                <div className='FloatLabelCol'>Academic Term</div>
                <div className='FormFieldCol'>
                  {/* <SingleSelect
                    Placeholder="Select term"
                    option={setLabelAsValueForMultiSelect(termOptions)}
                    handleChange={(val, e) => onChangeHandler(val, val, 'acededemicTerm')}
                    value={courseObject.acededemicTerm}
                    helperText={courseErrors?.acededemicTerm}
                    error={courseErrors?.acededemicTerm}
                    errorHelperText={courseErrors?.acededemicTerm}
                  // disabled={courseObject.acededemicYear}
                  // disabled={true}
                  /> */}
                  <MultipleSelect
                    Placeholder="Select term"
                    option={setLabelAsValueForMultiSelect(termOptions)}
                    handleChange={(e) => handleChangeTerm(e, 'academicTerm')}
                    value={valueTerm}
                    helperText={courseErrors?.acededemicTerm}
                    error={courseErrors?.acededemicTerm}
                    errorHelperText={courseErrors?.acededemicTerm}
                  />

                  {/* <SelectField
                    label="Select term"
                    name="acededemicTerm"
                    options={termOptions}
                    selectedOption={courseObject.acededemicTerm}
                   }
                    handleChange={(val, e) => onChangeHandler(val, val, 'acededemicTerm')}
                  /> */}
                </div>
              </div>

              <div className='FormFieldContainer alignTop'>
                <div className='FloatLabelCol' style={{ marginTop: '14px' }}>Section Code<span className='requiredAsterisk'>*</span></div>
                <div className='FormFieldCol'>

                  <Grid container spacing={2}>

                    <Grid item md={6} xs={12}>
                      <TextField
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        placeholder="Enter code prefix"
                        count={4}
                        name={'code_prefix'}
                        required={true}
                        helperText={courseObject?.sectionCode?.codePrefix?.length === 0 ? courseErrors?.sectionCode_codePrefix : ''}
                        error={courseObject?.sectionCode?.codePrefix?.length === 0 ? courseErrors?.sectionCode_codePrefix : ''}
                        value={courseObject?.sectionCode?.codePrefix}
                        showErrorMsg={false}
                        handleChange={(val, e) => onChangeHandlerForSecondDepth(val, e, 'sectionCode', 'codePrefix')}
                      />

                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        placeholder="Enter code"
                        count={4}
                        name={'classCodeNumber'}
                        number="number"
                        required={true}
                        helperText={courseObject?.sectionCode?.code?.length === 0 ? courseErrors?.sectionCode_code : ''}
                        error={courseObject?.sectionCode?.code?.length === 0 ? courseErrors?.sectionCode_code : ''}
                        value={courseObject?.sectionCode?.code}
                        showErrorMsg={false}
                        handleChange={(val, e) => onChangeHandlerForSecondDepth(val, e, 'sectionCode', 'code')}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <div className='PreviewSection'>
                        Section code preview: <span>{courseObject?.sectionCode?.codePrefix} {courseObject?.sectionCode?.code}</span>
                      </div>
                    </Grid>

                  </Grid>
                  {/* {((courseErrors?.sectionCode_codePrefix) || (courseErrors?.sectionCode_code)) && (
                    <FormHelperText sx={{ color: '#ba1b1a' }} >Required</FormHelperText>
                  )} */}

                  {(courseErrors?.sectionCode_codePrefix && courseErrors?.sectionCode_code) ? <FormHelperText sx={{ color: '#ba1b1a' }} >Required</FormHelperText> : ""}

                </div>
              </div>


              <div className='FormFieldContainer alignTop'>
                <div className='FloatLabelCol' style={{ paddingTop: "17px" }}>Primary Teacher<span className='requiredAsterisk'>*</span></div>
                <div className='FormFieldCol'>
                  <div className='PrimaryTeacher' onClick={handlePrimaryTeacherDrawerOpen}>
                    <div className='PrimaryTeacherinfo'>
                      <SearchRoundedIcon className='iconSearch' />
                      <input type='text' />
                      <div className='FieldTextBox'>
                        <span>{(id && Object.keys(teacher).length === 0) && primaryTeacher} {(Object.keys(teacher)?.length) > 0 && `${truncateText(`${teacher?.firstName} ${teacher?.lastName}`, 30)}`}</span> <FiberManualRecordRoundedIcon />
                        {((Object.keys(teacher)?.length) === 0 && !id) && <span style={{ color: '#ACAAAF', fontWeight: 400 }}>Select primary teacher</span>}
                        {(!id || teacher) && teacher?.userUniqueID}
                      </div>
                    </div>
                    {((Object.keys(teacher)?.length) > 0) && <IconButton onClick={(e) => setTeacher({})} className='iconClose'><CancelRoundedIcon /></IconButton>}
                  </div>
                  <div className='FormHeader'>
                    {
                      Object.keys(courseObject.primaryTeacher)?.length === 0 || courseObject.primaryTeacher?.length === 0 ? <>{courseErrors?.primaryTeacher != "" ? <p style={{ color: "#d32f2f" }}>{courseErrors?.primaryTeacher}</p> : <p>You can add additional team from course details.</p>}</> : ""
                    }

                  </div>
                </div>
              </div>

              <div className='FormFieldContainer'>
                <div className='FloatLabelCol' style={{ marginTop: courseErrors?.classRoomSize === "Required" && '-26px' }}>Class Capacity<span className='requiredAsterisk'>*</span></div>
                <div className='FormFieldCol'>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter max number of students"
                    number="number"
                    name={'classRoomSize'}
                    required={true}
                    helperText={courseObject?.classRoomSize?.length === 0 ? courseErrors?.classRoomSize : ''}
                    error={courseObject?.classRoomSize?.length === 0 ? courseErrors?.classRoomSize : ''}
                    value={courseObject?.classRoomSize}
                    handleChange={(val, e) => onChangeHandlerClassRoomSize(val, e, 'classRoomSize')}
                  //value={courseData.Id}
                  //handleChange={handleChanges("Id")}
                  />
                </div>
              </div>
            </>}



        </>
      )}





      {/* Primary TeacherDrawer */}
      <SideDrawer
        open={openPrimaryTeacherDrawer}
        handleDrawerClose={handleClosePrimaryTeacherDrawer}
        title="Select Primary Teacher"
        sideOpen="right"
        ButtonLabel="Select"
        cancelButtonLabel="Cancel"
        BackArrow={true}
        CloseArrowHide={true}
        FooterWithoutTab={true}
        clickSaveButton={(e) => { setTeacherHandler() }}
        padding="0"
        body={
          <>
            <SelectPrimaryTeacher setTeacher={setTeacher} setTempTeacher={setTempTeacher} userTypesDatas={userTypesDatas} />
          </>
        }
      />

      <DrawerFooterActions
        isLoading={
          createCourseOptions.isLoading ||
          updateCourseOptions.isLoading
        }
        handleClose={handleClose}
        handleAction={handleAddClass}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={id ? 'Update' : 'Create Course'}
      />
    </>
  );
};

export default CreateCourses;
