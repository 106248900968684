import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "../../../../assets/css/filter.css";
import dayjs from "dayjs";
import { toTitleCase } from "../../../../utils/helpers";
import CheckBox from "../../../../components/Form/CheckBox/CheckBox";

const AttendanceFilter = (props) => {
    const { statuses, setStatuses, streams, setStream, userTypes, setUserTypes, startDate, endDate, setStartDate, setEndDate, roles, attendanceCodeOptionsUnfiltered, attendanceCodeOptions } = props


    const changeStreamHandler = (e, value) => {
        let newStreams = [...streams]
        if (e.target.checked) {
            if (!streams.includes(value)) {
                newStreams.push(value);
                setStream(newStreams)
            }
        } else {
            if (streams.includes(value)) {
                newStreams = streams.filter((stream) => stream !== value)
                setStream(newStreams)
            }
        }

    }
    // console.log(attendanceCodeOptions, attendanceCodeOptionsUnfiltered)
    const showStreamsTypes = () => {
        let streamCheckboxes = []
        attendanceCodeOptionsUnfiltered.map((attendanceCodeOption) => {
            streamCheckboxes.push(<CheckBox
                label={toTitleCase(attendanceCodeOption.code)}
                checked={streams.includes(attendanceCodeOption.code)}
                onChange={(e) => changeStreamHandler(e, attendanceCodeOption.code)}
            />)
        })
        return streamCheckboxes

    }

    // const showStreamsTypes = () => {
    //     let streamCheckboxes = []
    //     attendanceCodeOptions.map((attendanceCodeOption) => {
    //         streamCheckboxes.push(<CheckBox
    //             label={toTitleCase(attendanceCodeOption.label)}
    //             checked={streams.includes(attendanceCodeOption.label)}
    //             onChange={(e) => changeStreamHandler(e, attendanceCodeOption.label)}
    //         />)
    //     })
    //     return streamCheckboxes
    // }

    const changeUersTypes = (e, value) => {
        let newUersTypes = [...userTypes]
        if (e.target.checked) {
            if (!userTypes.includes(value)) {
                newUersTypes.push(value)
                setUserTypes(newUersTypes)
            }
        } else {
            if (userTypes.includes(value)) {
                newUersTypes = userTypes.filter((userTypes) => userTypes !== value)
                setUserTypes(newUersTypes)
            }
        }
    }
    const showUserTypes = () => {
        let userTypeCheckboxes = []
        roles.map((role) => {
            userTypeCheckboxes.push(<CheckBox
                label={toTitleCase(role.name)}
                checked={userTypes.includes(role.name)}
                onChange={(e) => changeUersTypes(e, role.name)}
            />)
        })
        return userTypeCheckboxes
    }

    return (

        <div className="FilterMainCont">
            <Accordion  >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Processing date
                    </Typography>
                    {(userTypes?.length > 0) && <Typography className="FilterCount">{userTypes?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {(roles?.length > 0) &&
                        showUserTypes()
                    }

                </AccordionDetails>
            </Accordion>

            {/* <Accordion expanded> */}
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className="AccTitle">
                        Payment Method
                    </Typography>
                    {(streams?.length > 0) && <Typography className="FilterCount">{streams?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {(attendanceCodeOptionsUnfiltered?.length > 0) &&
                        showStreamsTypes()
                    }
                    {/* {(attendanceCodeOptions?.length > 0) &&
                        showStreamsTypes()
                    } */}

                </AccordionDetails>
            </Accordion>

            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className="AccTitle">
                        Status
                    </Typography>
                    {(streams?.length > 0) && <Typography className="FilterCount">{streams?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {(attendanceCodeOptionsUnfiltered?.length > 0) &&
                        showStreamsTypes()
                    }
                    {/* {(attendanceCodeOptions?.length > 0) &&
                        showStreamsTypes()
                    } */}

                </AccordionDetails>
            </Accordion>

            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className="AccTitle">
                        Payment Plan
                    </Typography>
                    {(streams?.length > 0) && <Typography className="FilterCount">{streams?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {(attendanceCodeOptionsUnfiltered?.length > 0) &&
                        showStreamsTypes()
                    }
                    {/* {(attendanceCodeOptions?.length > 0) &&
                        showStreamsTypes()
                    } */}

                </AccordionDetails>
            </Accordion>

        </div>
    );
};

export default AttendanceFilter;
