import React, { useEffect, useState } from 'react';
import styles from './Notification.module.css';
import Button from '@mui/material/Button';
import { FaRegEdit } from "react-icons/fa";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';
import AddNotification from './AddNotification';

const Notification = (props) => {
    const {
        feeObject,
        setFeeObject,
        feeErrorObject,
        setFeeErrorObject
    } = props

    const initialNotificationObject = {
        "title": "Notification 1",
        "smsEnabled": false,
        "emailEnabled": false,
        "emailTemplate": "",
        "smsTemplate": "",
        "notifyBeforeDays": 1
    }

    const [openAddNotification, setOpenAddNotification] = useState(false)
    const [editNotificationIndex, setEditNotificationIndex] = useState('')
    const [notificationObject, setNotificationObject] = useState(initialNotificationObject)

    const options = [
        {
            "label": "1 day before payment processing date",
            "value": 1
        },
        {
            "label": "2 days before payment processing date",
            "value": 2
        },
        {
            "label": "3 days before payment processing date",
            "value": 3
        },
        {
            "label": "4 days before payment processing date",
            "value": 4
        },
        {
            "label": "5 days before payment processing date",
            "value": 5
        },
        {
            "label": "6 days before payment processing date",
            "value": 6
        },
        {
            "label": "7 days before payment processing date",
            "value": 7
        }
    ]

    useEffect(() => {

        if (editNotificationIndex != "" || editNotificationIndex === 0) {
            let found = feeObject?.notifications[editNotificationIndex]
            setNotificationObject(found)
        }
    }, [editNotificationIndex])

    const handleDrawerOpenAddNotification = () => {
        setOpenAddNotification(true);
    };
    const handleDrawerCloseAddNotification = () => {
        setOpenAddNotification(false);
    };

    const editNotificationHandler = (index) => {
        setEditNotificationIndex(index)
        handleDrawerOpenAddNotification(true)

    }

    const removeNotificationHandler = (index) => {
        let newNotifications = feeObject?.notifications?.filter((notification, i) => i != index).map((notification, i) => ({ ...notification, title: `Notification ${i + 1}` }))

        setFeeObject({ ...feeObject, notifications: newNotifications })
    }

    const showNotificationBeforeDay = (index) => {
        return options?.find((option) => option?.value == feeObject?.notifications[index]?.notifyBeforeDays).label
    }

    const showNotifications = () => {
        let notificationComponents = []

        feeObject?.notifications?.map((notification, index) => {
            notificationComponents?.push(<div className={styles.NotificationCard}>
                <div className={styles.NotificationCardLeft} key={index}>
                    <div className={styles.NotificationCardTitle}>
                        <h1>{notification?.title}</h1>
                        {notification?.emailEnabled && <div className={styles.ChipBox}><span></span>Email</div>}
                        {notification?.smsEnabled && <div className={styles.ChipBox}><span></span>SMS</div>}
                    </div>
                    <div className={styles.NotificationCardDescription}>{showNotificationBeforeDay(index)}</div>
                </div>
                <div className={styles.NotificationCardRight}>
                    {(index > 0 && !feeObject?.permanentlyClosed) && <Button className={styles.NotificationActionButton} onClick={(e) => removeNotificationHandler(index)}><CloseIcon /> Remove</Button>}
                    {!feeObject?.permanentlyClosed && <Button className={styles.NotificationActionButton} onClick={(e) => editNotificationHandler(index)}><FaRegEdit /> Edit</Button>}
                </div>
            </div>)
        })
        return notificationComponents
    }

    const addEditNotificationHandler = () => {
        const { notifications } = feeObject
        let newNotifications = [...notifications]

        let newNotificationObject = { ...notificationObject }

        if (editNotificationIndex != "" || editNotificationIndex === 0) {
            newNotificationObject.title = `Notification ${parseInt(editNotificationIndex) + 1}`
            newNotifications[editNotificationIndex] = newNotificationObject
        } else {
            newNotificationObject.title = `Notification ${feeObject?.notifications?.length + 1}`
            newNotifications.push(newNotificationObject)
        }
        setFeeObject({ ...feeObject, notifications: newNotifications })

        setEditNotificationIndex('')

        handleDrawerCloseAddNotification(false)

        setNotificationObject(initialNotificationObject)
    }

    const closeNotificationDrawerHandler = () => {
        handleDrawerCloseAddNotification()
        setNotificationObject(initialNotificationObject)
        setEditNotificationIndex('')
    }

    return (
        <>
            <div className={styles.NotificationContainer}>
                {showNotifications()}
                {feeObject?.notifications?.length < 3 && <div className={styles.MoreNotificationButtonContainer}>
                    {!feeObject?.permanentlyClosed && <Button className={styles.NotificationActionButton} onClick={handleDrawerOpenAddNotification}><AddIcon /> More Notification</Button>}
                </div>}


            </div>



            <SideDrawer
                title={notificationObject?.title}
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={openAddNotification}
                handleDrawerClose={(e) => closeNotificationDrawerHandler()}
                FooterWithoutTab={true}
                ButtonLabel="Save"
                clickSaveButton={(e) => addEditNotificationHandler()}
                // drawerWidth="755px"
                BackArrow={true}
                CloseArrowHide={true}
                body={
                    <div>
                        <AddNotification
                            options={options}
                            feeObject={feeObject}
                            setFeeObject={setFeeObject}
                            feeErrorObject={feeErrorObject}
                            setFeeErrorObject={setFeeErrorObject}
                            notificationObject={notificationObject}
                            setNotificationObject={setNotificationObject}
                            editNotificationIndex={editNotificationIndex}
                            setEditNotificationIndex={setEditNotificationIndex}
                        />
                    </div>
                }
            />

        </>
    );
};

export default Notification;
