import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import SelectField from '../../../../components/Form/SelectField/SelectField';
import PriceTextField from '../../../../components/Form/PriceTextField/PriceTextField';
import TextField from '../../../../components/Form/TextField/TextField';
import Buttons from '../../../../components/Form/Button/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const Zelle = () => {

    const [age, setAge] = React.useState('');
    const handleChanges = (event) => {
        setAge(event.target.value);
    };

    const options = [
        {
            label: "Due",
            value: "Due",
        },

        {
            label: "Paid",
            value: "Paid",
        },

        {
            label: "Partially Paid",
            value: "Partially Paid",
        },

    ]


    const [values, setValues] = React.useState({
        amount: '',
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };


    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <SelectField
                        label="Payment status"
                        required={true}
                        FieldLabel={true}
                        options={options}
                        handleChange={handleChanges}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PriceTextField
                        label="Amount paid"
                        required={true}
                        startAdornment="$"
                        labelStatus={true}
                        TooltipText="Compare-at price"
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                        label="Collected/Verified by"
                        validation={false}
                    // value={courseData.Id}
                    // handleChange={handleChanges("Id")}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Grid item xs={12} sm={12} md={12}>

                        <div className="FieldLabel" style={{ color: '#1B1B1F' }}>Receipt/Screenshot</div>  {/* please check message > Email.js file this upload button already functional there */}

                        <Buttons
                            label="Upload"
                            buttonColor=""
                            color="#5423FF"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            fontWeight={700}
                            //onClick={(e) => handleDrawerOpenSelectMedia2()}
                            // onClick={() => {
                            //   setOpen(false);
                            //   setImageFromCropper(null)
                            //   setImageFromCropperSrc(null)
                            //   setImageFromCropperName("")
                            // }
                            // }
                            border="#5423FF 1px solid"
                            textTransform="uppercase"
                            icon={<AddRoundedIcon />}
                        />

                        {/* {(errors?.messageAttachment != "") && <div class="UplaodTextHelper">{errors?.messageAttachment}</div>} */}
                    </Grid>
                </Grid>


                <Grid item xs={12} md={12}>
                    <TextField
                        label="Additional Note"
                        validation={false}
                        CharAlignMid="CharAlignMid"
                        count={100}
                    // value={courseData.Id}
                    //handleChange={handleChanges("Id")}
                    />
                </Grid>

            </Grid>

        </>
    );
};

export default Zelle;
