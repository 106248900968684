import React from 'react';

const LogoHeader = () => {
    return (
        <>
            <div style={{ width: '100%', marginBottom: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src="https://web-assets.keeperedu.com/assets/logos/keeperedu-logos/keeper-education-management-logo-bg-wht.png" alt="DeenSchool Logo" width="260px" />
            </div>
        </>
    );
};

export default LogoHeader;