import React, { useEffect, useState } from "react";
import { Avatar, Badge, Box } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Status from "../../../components/Status/Status";
// import About from "./GuardianParentsDetailsSteps/About";
// import Contact from "./GuardianParentsDetailsSteps/Contact";
// import { useGetSingleParentDetailsQuery } from "../../../redux/features/parentPortal/students/studentsApi";
// import { getProfileAvatarFromName } from "../../../utils/helpers";
// import useProtected from "../../../hooks/useProtected";
import { capitalizeWords, truncateText } from "../../../hooks/HelperFunctions";
import GurdiansDetailsAbout from "./GurdiansDetailsAbout";
import GuardiansDetailsContact from "./GuardiansDetailsContact";
import Crown from '../../../assets/imgs/vip-crown-fill.svg';
import { styled } from '@mui/material/styles';
import "../../../assets/css/users.css";
import { useDeleteGuardianMutation, useGetGuardianDetailsQuery } from "../../../redux/features/users/guardianApi";
import useProtected from "../../../hooks/useProtected";
import { getProfileAvatarFromName } from "../../../utils/helpers";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        padding: '0px',
        backgroundColor: '#fff',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            content: '""',
        },
    },
}));

const tabsStyle = {
    width: '100%',
    "& .MuiTabs-flexContainer": { width: '100%' },
    "& .Mui-selected": { color: '#5423FF !important' },
    "& .MuiTabs-indicator": { background: '#5423FF !important' }
}

const GuardiansDetailsDrawer = ({ closeDrawer, userId, guardianUniqueId, userDetailsStatus, setUserDetailsStatus, userDetailsRelationship, setUserDetailsRelationship, updateGuardianOptions, setGuardianID }) => {


    // ================= Table OD and EVEN Code ========//
    const { data, isLoading, error } = useGetGuardianDetailsQuery({ studentId: userId, guardianUniqueId }, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        skip: !guardianUniqueId
    });
    const [deleteGuardian, deleteGuardianOptions] = useDeleteGuardianMutation();


    useProtected(error);
    useProtected(deleteGuardianOptions?.error);

    const [userDetails, setUserDetails] = useState({
        _id: "",
        firstName: "",
        lastName: "",
        email: "",
        preferredContact: "",
        homePhone: "",
        workPhone: "",
        cellPhone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        addressLine1: "",
        addressLine2: "",
        ext: "",
        occupation: "",
        preferredLanguage: "",
        highestEducation: "",
        userUniqueID: "",
        idPicture: "",
        profilePic: "",
        isPrimary: ""
    });
    const [status, setStatus] = useState(userDetailsStatus);
    const [relationship, setRelationship] = useState(userDetailsRelationship);

    useEffect(() => {
        if (!isLoading && !error && data) {
            let getData = data?.body?.data
            setStatus(getData?.gurdian?.status);
            setUserDetailsStatus(getData?.gurdian?.status)
            setRelationship(getData?.gurdian?.relationShip);
            setUserDetailsRelationship(getData?.gurdian?.relationShip)
            setUserDetails({
                ...userDetails,
                _id: getData?.gurdianDetails?._id,
                firstName: getData?.gurdianDetails?.firstName,
                lastName: getData?.gurdianDetails?.lastName,
                email: getData?.gurdianDetails?.email,
                userUniqueID: getData?.gurdianDetails?.userUniqueID,
                preferredContact: getData?.gurdianDetails?.preferredContact,
                homePhone: getData?.gurdianDetails?.homePhone,
                workPhone: getData?.gurdianDetails?.workPhone,
                cellPhone: getData?.gurdianDetails?.cellPhone,
                city: getData?.gurdianDetails?.city,
                state: getData?.gurdianDetails?.state,
                ext: getData?.gurdianDetails?.ext,
                zip: getData?.gurdianDetails?.zip,
                addressLine1: getData?.gurdianDetails?.addressLine1,
                addressLine2: getData?.gurdianDetails?.addressLine2,
                address: getData?.gurdianDetails?.address ? getData?.gurdianDetails?.address?.label : "",
                occupation: getData?.gurdianDetails?.occupation,
                preferredLanguage: getData?.gurdianDetails?.preferredLanguage,
                highestEducation: getData?.gurdianDetails?.highestEducation,
                idPicture: getData?.gurdianDetails?.idPicture,
                profilePic: getData?.gurdianDetails?.profilePic,
                isPrimary: getData?.gurdian?.isPrimary,
            });
            setGuardianID(getData?.gurdianDetails?._id)
        }
    }, [data, isLoading, error]);


    const [tabsValue, setTabsValue] = useState(0);
    const [value, setValue] = useState(status);

    const staticStatustData = [
        {
            Label: "Status",
            SubLabel: capitalizeWords(status),
            Color: "#41AB37",
            line: true,
        },
        {
            Label: "Preferred Contact",
            SubLabel: userDetails?.preferredContact || (
                "N/A"
            ),
            Color: "#7D8597",
            line: false,
        },

    ];

    const handleChanges = (event, value) => {
        console.log(event.target.value, value);
    };
    const handleChange = (event, newValue) => {
        setTabsValue(newValue);
    };

    useEffect(() => {
        console.log(relationship, 500)
    }, [relationship])

    return (
        <div>
            <div className="guardianParentDetailsContainer">
                <div className="guardianImageContainer">
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

                        badgeContent={userDetails?.isPrimary ?
                            <img src={Crown} alt='crown svg' /> : null
                        }
                    >
                        <Avatar
                            sx={{ width: 130, height: 130 }}
                            alt={userDetails?.firstName}
                            src={
                                userDetails?.idPicture ||
                                    userDetails?.profilePic
                                    ? userDetails?.idPicture ||
                                    userDetails?.profilePic
                                    : getProfileAvatarFromName(userDetails?.firstName)
                            }
                        ></Avatar>
                    </StyledBadge>
                    {/* <Avatar
                        sx={{ width: 160, height: 160 }}
                        alt={'avatar'}
                        src={
                            ""
                        }
                    ></Avatar> */}
                </div>
                <div className="guardianInfoContainer">
                    <h2>
                        {capitalizeWords(
                            truncateText(
                                `${userDetails?.firstName} ${userDetails?.lastName}`,
                                30
                            )
                        )}

                        <FiberManualRecordIcon
                            sx={{ fontSize: "12px", color: "#66BD50" }}
                        />
                    </h2>
                    <div className="P_imgSectionInfoSub">
                        <p>
                            {`ID: ${userDetails?.userUniqueID}` || (
                                <span style={{ color: "#BBBFC9" }}>No data available</span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className="guardianParentStatusContainer">
                {/* <Status
                    MainTitle={true}
                    Title={`head`}
                    BackLinkLabel="title"
                    Backlink="#"
                    StaticStatus={true}
                    StatusLine={false}
                    BottomLine={false}
                    staticStatustData={statustData}
                    guardianParentsDetails={staticStatustData}
                /> */}
                <Status
                    MainTitle={false}
                    Title={""}
                    BackLinkLabel=""
                    //   BackLinkLabelOnClick={(e) => navigate('/courses')}
                    statustData={staticStatustData}
                    Value={value}
                    onChange={(e) => handleChanges(e)}
                    StaticStatus={true}
                    staticStatustData={staticStatustData}
                />
            </div>

            <div style={{ marginTop: '20px', padding: '0px 24px' }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabsValue}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        sx={tabsStyle}
                    >
                        <Tab label="About" {...a11yProps(0)} sx={{ flex: 1 }} />
                        <Tab label="Contact" {...a11yProps(1)} sx={{ flex: 1 }} />
                    </Tabs>
                </Box>

                <div className="TsbPanelContainer" style={{ padding: "0" }}>
                    <CustomTabPanel value={tabsValue} index={0} >
                        <GurdiansDetailsAbout userDetails={userDetails} setUserDetailsStatus={setUserDetailsStatus} setRelationship={setRelationship} setUserDetailsRelationship={setUserDetailsRelationship} relationship={relationship} userId={userId} guardianUniqueId={guardianUniqueId} closeDrawer={closeDrawer} />
                    </CustomTabPanel>

                    <CustomTabPanel value={tabsValue} index={1}>
                        <GuardiansDetailsContact userDetails={userDetails} />
                    </CustomTabPanel>
                </div>
            </div>
        </div>
    );
};

export default GuardiansDetailsDrawer;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ overflow: 'auto' }}>
                    <Box sx={{ overflow: 'auto' }}>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
