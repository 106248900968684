import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import SelectIndividuals from './SelectIndividuals';
import SelectClass from './SelectClass';
import { RxPerson } from 'react-icons/rx';
import { RiGroupLine } from "react-icons/ri";
import EventIcon from '@mui/icons-material/Event';
import Table from '../../../../components/Table/Table';
import { IoSchoolOutline } from "react-icons/io5";
import SelectGroups from './SelectGroups';
import DraggableDialog from '../../../../components/hooks/Dialog';
import { useGetUsersByRoleQuery } from '../../../../redux/features/users/userApi';
import useProtected from '../../../../hooks/useProtected';
import { useGetAllGroupsQuery } from '../../../../redux/features/groups/groupsApi';

const BillAppliesTo = (props) => {

    let {
        feeObject,
        classList,
        setFeeObject,
        feeErrorObject,
        classesSelected,
        schoolYearClasses,
        setFeeErrorObject,
        setClassesSelectedIds,
        setSchoolYearClassesOptions
    } = props

    const {
        data,
        isError,
        isLoading,
        error

    } = useGetUsersByRoleQuery('student', { refetchOnMountOrArgChange: true });

    const {
        data: groupsData,
        isError: groupsIsError,
        isLoading: groupsIsLoading,
        error: groupsError
    } = useGetAllGroupsQuery(undefined, { refetchOnMountOrArgChange: true })

    useProtected(error);
    useProtected(groupsError)

    const [rows, setRows] = useState([])
    const [groups, setGroups] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [addedUsers, setAddedUsers] = useState([])
    const [addedGroups, setAddedGroups] = useState([])
    const [studentList, setStudentList] = useState([])
    const [removeStudentId, setRemoveStudentId] = useState('')
    const [addedClassLevels, setAddedClassLevels] = useState([])
    const [schoolYearOptions, setSchoolYearOptions] = useState([])
    const [openRecipientseDrawer, setRecipientse] = useState(false)
    const [addedUsersToUpdate, setAddedUsersToUpdate] = useState([])
    const [totalStudentsNumber, setTotalStudentsNumber] = useState(0)
    const [totalGroupsStudents, setTotalGroupsStudents] = useState(0)
    const [addedGroupsToUpdate, setAddedGroupsToUpdate] = useState([])
    const [totalClassesStudents, setTotalClassesStudents] = useState(0)
    const [addedClassLevelsToUpdate, setAddedClassLevelsToUpdate] = useState([])



    const handleOpenStudentClassList = () => setRecipientse(true)

    useEffect(() => {
        if (!isError && !isLoading) {
            setStudentList(data?.body?.data)
        }
    }, [data, isError, isLoading])


    //======= Remove dialog ===== //
    const [openDialogRemove, setOpenDialogRemove] = useState(false);
    const handleOpenRemove = () => {
        setOpenDialogRemove(true);
    };
    const handleCloseRemove = () => {
        setOpenDialogRemove(false)
    };
    //======= Remove dialog ===== //


    useEffect(() => {
        if (classesSelected?.length > 0) {
            setClassesSelectedIds(classesSelected)
        } else {
            setClassesSelectedIds([])
        }
    }, [classesSelected])




    const handleAddUsers = () => {
        setRecipientse(false);

        let individualStudentsIDs = [...addedUsers]
        let classesStudentsIds = []
        let groupStudentsIDs = []

        let allStudentsUniqueIdFromClassLevel = schoolYearClasses
            .filter(item => addedClassLevels.includes(item?._id))
            .flatMap(item => item.classStudents.map(student => student.userUniqueID))

        let allStudentsUniqueIdFromGroup = groups
            .filter(item => addedGroups.includes(item?._id))
            .flatMap(item => item.users.filter((user) => user?.userType == 'student')
                .map(student => student.userUniqueID))


        allStudentsUniqueIdFromClassLevel?.map((studentUniqueId) => {
            let found = studentList?.find((student) => student?.userUniqueID == studentUniqueId)
            classesStudentsIds?.push(found?._id)
        })

        allStudentsUniqueIdFromGroup?.map((studentUniqueId) => {
            let found = studentList?.find((student) => student?.userUniqueID == studentUniqueId)
            groupStudentsIDs?.push(found?._id)
        })



        const mergedIds = Array.from(new Set([...individualStudentsIDs, ...classesStudentsIds, ...groupStudentsIDs]));

        const { billTo } = feeObject
        let newBillTo = { ...billTo }


        const mergedIdsWithPrevious = Array.from(new Set([...newBillTo.studentIds, ...mergedIds]));
        const mergedClassLevelIdsWithPrevious = Array.from(new Set([...newBillTo.classLevelIds, ...addedClassLevels]));
        const mergedGroupIdsWithPrevious = Array.from(new Set([...newBillTo.groupIds, ...addedGroups]));



        newBillTo.studentIds = mergedIdsWithPrevious
        newBillTo.groupIds = mergedGroupIdsWithPrevious
        newBillTo.classLevelIds = mergedClassLevelIdsWithPrevious


        setFeeObject({ ...feeObject, billTo: newBillTo })

        setAddedUsers([])
        setAddedGroups([])
        setAddedClassLevels([])
        setAddedUsersToUpdate([])
        setAddedGroupsToUpdate([])
        setAddedClassLevelsToUpdate([])

    }

    const handleCloseRecipients = () => {
        setAddedUsersToUpdate([])
        setAddedUsers([])
        setAllUsers([])
        setAddedClassLevelsToUpdate([])
        setAddedClassLevels([])
        // setMessageObject({ ...messageObject, to: [], toGroups: [] })
        setRecipientse(false);



    }


    useEffect(() => {
        let allUser = [...addedUsersToUpdate]
        addedClassLevelsToUpdate?.map((singleGroup) => {
            if (singleGroup.users.length > 0) {
                allUser = [...allUser, ...singleGroup.users]
            }
        })
        const uniqueData = {};
        allUser.forEach(item => {
            uniqueData[item.userUniqueID] = item;
        });
        const result = Object.values(uniqueData);

        setAllUsers(result)
    }, [addedUsersToUpdate, addedClassLevelsToUpdate])




    useEffect(() => {

        let totalClassStudents = 0

        schoolYearClasses?.map((schoolYearClass) => {
            addedClassLevels?.map((group) => {
                if (schoolYearClass?._id == group) {

                    let studentsInfo = []
                    schoolYearClass?.classStudents?.map((student) => {
                        let found = studentList?.find((singleStudent) => singleStudent?.userUniqueID == student?.userUniqueID)
                        if (found) {
                            studentsInfo.push(found)
                        }
                    })
                    let studentsNotAdded = studentsInfo?.filter((stu) => !feeObject?.billTo?.studentIds?.includes(stu?._id))


                    totalClassStudents += studentsNotAdded?.length
                }
            })
        })
        setTotalClassesStudents(totalClassStudents)




    }, [addedClassLevels])

    useEffect(() => {
        let totalGroupStudents = 0

        addedGroups?.map((groupId) => {
            groups?.map((group) => {
                if (group?._id == groupId) {
                    let groupStudents = group?.users?.filter((user) => user?.userType == 'student')
                    let studentsNotAdded = groupStudents?.filter((stu) => !feeObject?.billTo?.studentIds?.includes(stu?.id))
                    totalGroupStudents += studentsNotAdded.length
                }
            })
        })

        setTotalGroupsStudents(totalGroupStudents)
    }, [addedGroups, groups])

    useEffect(() => {
        let totalNumberOfStudents = 0
        totalNumberOfStudents = totalClassesStudents + addedUsers?.length + totalGroupsStudents
        setTotalStudentsNumber(totalNumberOfStudents)
    }, [totalClassesStudents, addedUsers, addedClassLevels, totalGroupsStudents])






    const tabData = [
        {
            lebel: "SELECT INDIVIDUALS",
            icon: <RxPerson />,
            filterCountStatus: true,
            filterCountNumber: (addedUsers?.length > 0) ? addedUsers.length : '',
        },

        {
            lebel: "SELECT CLASS/LEVEL",
            icon: <IoSchoolOutline />,
            filterCountStatus: true,
            filterCountNumber: (totalClassesStudents > 0) ? totalClassesStudents : '',
        },

        {
            lebel: "SELECT GROUPS",
            icon: <RiGroupLine />,
            filterCountStatus: true,
            filterCountNumber: (totalGroupsStudents > 0) ? totalGroupsStudents : '',
        },
    ]


    const panelTab = [
        {
            id: 0,
            panleBody: <SelectIndividuals
                feeObject={feeObject}
                classList={classList}
                addedUsers={addedUsers}
                setAddedUsers={setAddedUsers}
                addedUsersToUpdate={addedUsersToUpdate}
                totalStudentsNumber={totalStudentsNumber}
                setAddedUsersToUpdate={setAddedUsersToUpdate}
            />,

            // SaveButtonLabel: (allUsers?.length > 1 || messageData?.body?.data?.to?.length > 1) ? `Select ${allUsers?.length} Users` : `Select ${allUsers?.length} User`,

            SaveButtonLabel: totalStudentsNumber > 1 ? `Select ${totalStudentsNumber} Students` : `Select ${totalStudentsNumber} Students`,
            clickSaveButton: handleAddUsers,
            CancelButtonLabel: 'Cancel',
        },

        {
            id: 1,
            panleBody: <SelectClass
                feeObject={feeObject}
                addedUsers={addedUsers}
                studentList={studentList}
                addedClassLevels={addedClassLevels}
                schoolYearClasses={schoolYearClasses}
                totalStudentsNumber={totalStudentsNumber}
                setAddedClassLevels={setAddedClassLevels}
                totalClassesStudents={totalClassesStudents}
                addedClassLevelsToUpdate={addedClassLevelsToUpdate}
                setAddedClassLevelsToUpdate={setAddedClassLevelsToUpdate}
            />,
            // SaveButtonLabel: ((allUsers?.length > 0 && allUsers?.length > 1) || (messageData?.body?.data?.toGroups.length > 0 && messageData?.body?.data?.toGroups.length > 1)) ? `Select ${allUsers?.length} Users` : `Select ${allUsers?.length} User`,
            SaveButtonLabel: totalStudentsNumber > 1 ? `Select ${totalStudentsNumber} Users` : `Select ${totalStudentsNumber} User`,
            clickSaveButton: handleAddUsers,
            CancelButtonLabel: 'Cancel',
        },


        {
            id: 2,
            panleBody: <SelectGroups
                groups={groups}
                feeObject={feeObject}
                groupsData={groupsData}
                addedUsers={addedUsers}
                studentList={studentList}
                addedGroups={addedGroups}
                setAddedUsers={setAddedUsers}
                setAddedGroups={setAddedGroups}
                addedUsersToUpdate={addedUsersToUpdate}
                addedGroupsToUpdate={addedGroupsToUpdate}
                totalStudentsNumber={totalStudentsNumber}
                setAddedUsersToUpdate={setAddedUsersToUpdate}
                setAddedGroupsToUpdate={setAddedGroupsToUpdate}
            />,

            // SaveButtonLabel: (allUsers?.length > 1 || messageData?.body?.data?.to?.length > 1) ? `Select ${allUsers?.length} Users` : `Select ${allUsers?.length} User`,

            SaveButtonLabel: totalStudentsNumber > 1 ? `Select ${totalStudentsNumber} Students` : `Select ${totalStudentsNumber} Students`,
            clickSaveButton: handleAddUsers,
            CancelButtonLabel: 'Cancel',
        },
    ];




    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', width: '60%', textAlign: 'left', },
        { id: 'calories', numeric: true, disablePadding: false, label: 'ID', width: '20%', textAlign: 'left', },
        { id: 'calories', numeric: true, disablePadding: false, label: 'Class/Level', width: '20%', textAlign: 'left', },
        { id: 'calories', numeric: true, disablePadding: false, label: '', width: '15%', textAlign: 'left', },
    ];


    // const rows = [
    //     {
    //         cell: [
    //             { text: 'Abdullah al-Razi', textcolor: "#001233" },
    //             { text: '0111069', textcolor: "#001233" },
    //             {
    //                 text: 'Class 1', textcolor: "#001233", tooltipViewTextEnd: true, TextWithLinkText: '1 more',
    //                 tooltipText: <div>
    //                     <li>Class 1</li>
    //                     <li>Class 2</li>
    //                 </div>,
    //             },
    //             { Button: true, buttonLabel: 'REMOVE', buttonColor: '#fff', buttonBorder: '#E8EAED 1px solid', buttonTextcolor: '#5C677D', onClick: handleOpenRemove, },

    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Abdullah al-Razi', textcolor: "#001233" },
    //             { text: '0111069', textcolor: "#001233" },
    //             { text: 'Class 1', textcolor: "#001233" },
    //             { Button: true, buttonLabel: 'REMOVE', buttonColor: '#fff', buttonBorder: '#E8EAED 1px solid', buttonTextcolor: '#5C677D', onClick: handleOpenRemove, },

    //         ],
    //     },


    // ];

    const removeSingleStudent = (studentId) => {
        setRemoveStudentId(studentId)
        handleOpenRemove()
    }

    const removeStudentHandler = () => {
        let studentId = removeStudentId;
        const { billTo } = feeObject
        let newBillTo = { ...billTo }
        let newStudentIds = newBillTo?.studentIds?.filter((studentId) => studentId != removeStudentId)
        newBillTo.studentIds = newStudentIds

        setFeeObject({ ...feeObject, billTo: newBillTo })

        handleCloseRemove()
        setRemoveStudentId('')
    }

    useEffect(() => {
        if (!groupsIsLoading && !groupsIsError) {
            setGroups(groupsData?.body?.data)
        }
    }, [groupsData, groupsIsLoading, groupsIsError]);

    useEffect(() => {
        let newRows = []

        feeObject?.billTo?.studentIds?.map((studentId) => {
            let found = studentList?.find((student) => student?._id == studentId)
            if (found) {
                let thirdObject = { text: 'N/A', textcolor: "#001233" }
                if (found?.classLevelIds?.length == 1) {
                    let foundClass = classList?.find((classObj) => classObj?._id == found?.classLevelIds[0])
                    thirdObject = { text: foundClass?.name || "N/A", textcolor: "#001233" }
                } else if (found?.classLevelIds?.length > 1) {
                    let classesToShow = []
                    found?.classLevelIds?.map((classLevelId, index) => {
                        let foundClass = classList?.find((classObj) => classObj?._id == classLevelId)
                        if (foundClass && index > 0) {
                            classesToShow?.push(<li>{foundClass?.name}</li>)
                        }
                    })
                    let foundFirstClass = classList?.find((classObj) => classObj?._id == found?.classLevelIds[0])
                    thirdObject = {
                        text: foundFirstClass?.name || "N/A", textcolor: "#001233", tooltipViewTextEnd: true, TextWithLinkText: found?.classLevelIds?.length > 1 && `${classesToShow?.length} more`,
                        tooltipText: <div>{classesToShow}</div>,
                    }
                }
                newRows.push({
                    cell: [
                        { text: `${found?.firstName} ${found?.lastName}`, textcolor: "#001233" },
                        { text: found?.userUniqueID || "N/A", textcolor: "#001233" },
                        thirdObject,
                        { Button: true, disabled: feeObject?.permanentlyClosed || false, buttonLabel: 'REMOVE', buttonColor: '#fff', buttonBorder: '#E8EAED 1px solid', buttonTextcolor: '#5C677D', onClick: (e) => (feeObject?.permanentlyClosed ? console.log('disabled') : removeSingleStudent(found?._id)) },



                    ],
                })
            }
        })

        setRows(newRows)


    }, [feeObject?.billTo?.studentIds, studentList, classList])




    return (
        <>
            <div className='DrawerFormContainer'>


                <div className='DrawerTableFull'>
                    <Table
                        innerHeader={true}
                        AddButtonInner={!feeObject?.permanentlyClosed}
                        addLabelInner="Add Students"
                        InnerButtonColor="#004FE0"
                        InnerButtonTextColor="#fff"
                        InnderButtonIcon={<AddRoundedIcon />}
                        onClickInnerButton={handleOpenStudentClassList}
                        headCells={headCells}
                        rows={rows}
                        footer={true}
                        RowsPerPage={20}
                        SelectedCounter={true}
                        SelectedValueCounter={`${feeObject?.billTo?.studentIds?.length || 0} students selected`}

                        emptyTableTitle="No students added yet"
                        emptyTableNote="Added students will be visible here"

                    />
                </div>


                {/* Select Students*/}
                <SideDrawer
                    open={openRecipientseDrawer}
                    handleDrawerClose={handleCloseRecipients}
                    title="Select Students"
                    sideOpen="right"
                    cancelButtonLabel="CANCEL"
                    FooterWithoutTab={false}
                    panelTab={panelTab}
                    tabData={tabData}
                    TabsStatus={true}
                    TabsPannelStatus={true}
                    padding="0"
                    drawerWidth="740px"
                    BackArrow={true}
                    CloseArrowHide={true}
                />


                {/* Remove*/}
                <DraggableDialog
                    openDialog={openDialogRemove}
                    handleCloseDialog={handleCloseRemove}
                    title="Remove student?"
                    body={<div>Are you sure you want to remove student from this fee/plan?<br />Payment will stop from the next cycle.</div>}
                    ModalFooter={true}
                    handleConfirmCloseDialog={(e) => removeStudentHandler()}
                    actionButton="Confirm"
                />



            </div>

        </>
    );
};

export default BillAppliesTo;
