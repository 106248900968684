import { api } from '../../api/apiSlice';

const transactionApi = api.injectEndpoints({
        endpoints: (builder) => ({
                getAllTransactions: builder.query({
                        query: () => ({
                                url: '/payments/admin/findAll',
                        }),
                        providesTags: ['Transactions'],
                }),
                getPaymentDetails: builder.query({
                        query: (id) => ({
                                url: `/payments/admin/getPaymentDetails/${id}`,
                        }),
                        providesTags: ['Transactions'],
                }),
                transactionsSearch: builder.mutation({
                        query: (data) => ({
                                url: `/payments/admin/searchTransactions`,
                                method: 'POST',
                                body: data,
                        }),
                        invalidatesTags: ['Transactions'],
                }),

        }),
});

export const {
        useGetAllTransactionsQuery,
        useTransactionsSearchMutation,
        useLazyGetPaymentDetailsQuery
} = transactionApi;