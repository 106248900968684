import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const PageTitle = () => {
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState("");

    useEffect(() => {
        const path = location.pathname;
        let name = "";

        //course part start
        if (path === "/classes-level" || path === "/classes-level-details/:class_level_id") {
            name = "Academics > Class/Level";
        } else if (
            path === "/courses" ||
            path === "/course-details" ||
            path === "/course-details/:course_id" || path === "/section-details/:course_id/:section_id"
        ) {
            name = "Academics > Courses";
        } else if (
            path === "/subject-area"
        ) {
            name = "Academics > Subject Area";
        } else if (
            path === "/academic-years"
        ) {
            name = "Academics > Academic-Years";
        }
        else if (
            path === "/calendars" || path === "/school-calendar/:id" || path === "/school-calendar-categories"
        ) {
            name = "Academics > Calendars";
        }
        else if (
            path === "/academics-settings"
        ) {
            name = "Academics > Settings";
        }


        //attendance part start
        else if (
            path === "/"
        ) {
            name = "Attendance > Main Attendance";
        }
        else if (
            path === "/attendance-history" || path === "/attendance-history-details"
        ) {
            name = "Attendance > Attendance History";
        }
        else if (
            path === "/attendance-code"
        ) {
            name = "Attendance > Attendance Code";
        }
        else if (
            path === "/attendance-settings"
        ) {
            name = "Attendance > Attendance Settings";
        }


        //user part start
        else if (
            path === "/users" ||
            path === "/users/:id"
        ) {
            name = "Users > User List";
        }
        else if (
            path === "/groups"
        ) {
            name = "Users > User-Groups";
        }
        else if (
            path === "/invite-users"
        ) {
            name = "Users > Invite-Users";
        }
        else if (
            path === "/roles-permissions"
        ) {
            name = "Users > Roles & Permissions";
        }
        else if (
            path === "/application-listing"
        ) {
            name = "Users > Applications";
        }


        //messages part start
        else if (
            path === "/messages"
        ) {
            name = "Messages > Sent";
        }
        else if (
            path === "/templates"
        ) {
            name = "Messages > Templates";
        }
        else if (
            path === "/categories"
        ) {
            name = "Messages > Categories";
        }


        //media-library part start
        else if (path === "/media-library-table-view") {
            name = "Media";
        }
        //Settings part start
        else if (path === "/settings") {
            name = "Settings";
        }
        //profile part start
        else if (path === "/profile") {
            name = "Profile";
        }

        //Sign in part start
        else if (path === "/signin") {
            name = "Sign in";
        } else {
            name = "KeeperEDU | Best Education Management System"
        }

        // Set the tab name along with the company name
        setPageTitle(
            `${name}`
        );
    }, [location]);

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>;
            <meta name="DeenSchool - Secured Portal for Education Management"
                content="DeenSchool is all-in-one, cloud based education management platform for Islamic K-12 & Weekend Schools.
      One secured platform for students, parents, faculty & staff in Islamic schools."/>
        </Helmet>
    );
};

export default PageTitle;