import React, { useEffect, useState } from 'react';

import MultipleSeletChip from '../../../../components/Form/MultipleSeletChip/MultipleSeletChip';
import { useGetAllCouponsQuery } from '../../../../redux/features/coupons/couponApi';
import useProtected from '../../../../hooks/useProtected';
import { getDataMap } from '../../../../hooks/HelperFunctions';
import CheckBox from '../../../../components/Form/CheckBox/CheckBox';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '../../../../components/Form/Tooltip/Tooltip';


const Discount = ({ feeObject, setFeeObject, feeErrorObject, setFeeErrorObject }) => {

    const {
        data,
        isError,
        isLoading,
        error
    } = useGetAllCouponsQuery(undefined, { refetchOnMountOrArgChange: true });
    useProtected(error);
    const [coupons, setCoupons] = useState([]);
    const [couponsOptions, setCouponsOptions] = useState([]);
    const [selectedCouponsOptions, setSelectedCouponsOptions] = useState([]);
    useEffect(() => {
        if (!isLoading && !isError) {
            setCoupons(data?.body?.data)
        }
    }, [data, isLoading, isError]);

    useEffect(() => {
        setCouponsOptions(getDataMap(
            coupons,
            "code",
            "code"
        ))
    }, [coupons])
    useEffect(() => {
        if (feeObject?.discountCodes?.length > 0) {
            const formattedArray = feeObject?.discountCodes?.map((option) => {
                return {
                    label: option,
                    value: option
                }
            })
            setSelectedCouponsOptions(formattedArray)
        } else {
            setSelectedCouponsOptions([])
        }
    }, [feeObject?.discountCodes])

    useEffect(() => {
        let filterOptions = couponsOptions?.filter((data) =>
            !selectedCouponsOptions?.some((option) => option?.value === data?.value))
        setCouponsOptions(filterOptions)
    }, [selectedCouponsOptions])

    useEffect(() => {
        const unselectedOptions = coupons.filter(coupon =>
            !selectedCouponsOptions.some(option => option.value === coupon.code)
        ).map(coupon => ({ label: coupon.code, value: coupon.code }));

        setCouponsOptions(unselectedOptions);
    }, [selectedCouponsOptions, coupons]);

    const handleChangeWithData = (fieldName, fieldData) => {
        setFeeObject({ ...feeObject, [fieldName]: fieldData.map(option => option.value) });
        setSelectedCouponsOptions(fieldData)
    };
    const onChangeCheck = (e) => {
        setFeeObject({
            ...feeObject, applyCouponAutomatically: e.target.checked
        });
    }

    return (
        <>
            <div className='DrawerFormContainer' style={{ marginTop: '-6px' }}>

                {/* ==== Name Start ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel' style={{ marginTop: '-12px' }}>
                        Select coupon code(s)
                    </div>

                    <div className='FloatingFormField'>
                        <MultipleSeletChip
                            placeholder={selectedCouponsOptions.length !== 0 ? "" : "Select"}
                            handleChange={(event, fieldData) =>
                                handleChangeWithData("discountCodes", fieldData)
                            }
                            count={50}
                            options={couponsOptions}
                            selectedOption={selectedCouponsOptions}
                            CharAlignMid="CharAlignMid"
                            disabled={feeObject?.permanentlyClosed || false}
                        />
                        <div style={{ marginTop: '8px', display: 'inline-block' }}>
                            <CheckBox
                                checked={feeObject?.applyCouponAutomatically}
                                label={
                                    <span style={{ marginTop: '-16px', display: 'inline-flex', alignItems: "center", }}> Apply the coupon automaticaly
                                        <div style={{ position: "relative", top: "4px", }}>
                                            <Tooltip
                                                TooltipText="Enable this option to automatically apply this coupon to payment(s) at checkout."
                                                Tooltip={<HelpOutlineOutlinedIcon style={{ color: "#5C677D", marginLeft: "8px", display: 'inline', fontSize: "16px", }} />}
                                            />
                                        </div>
                                    </span>
                                }
                                onChange={(e) => onChangeCheck(e)}
                                color="primary"
                                disabled={feeObject?.permanentlyClosed || false}
                            />
                        </div>
                    </div>

                </div>
                {/* ==== Name End ===*/}
            </div>

        </>
    );
};

export default Discount;
