import { useState } from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import SelectField from '../../../components/Form/SelectField/SelectField';
import DraggableDialog from '../../../components/hooks/Dialog';
import { countries } from '../../../assets/data/countries';
import { capitalizeWords } from '../../../hooks/HelperFunctions';
import { useDeleteGuardianMutation, useMakePrimaryMutation } from '../../../redux/features/users/guardianApi';
import { toast } from 'react-toastify';
import useProtected from '../../../hooks/useProtected';

const tableLeftCellStyle = { color: '#5C677D', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }
const tableRightCellStyle = { color: '#001233', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }
const noDataSpanStyle = { color: '#BBBFC9', fontSize: '16px', fontWeight: 400 }
const BoxContainerStyle = {
    padding: '16px',
    marginTop: '12px',
    border: '1px solid #E8EAED',
    borderRadius: '16px',
    "&>h4": {
        fontSize: '18px',
        fontWeight: 600,
        color: '#001233',
        lineHeight: '24px'
    },
    "&>p": {
        lineHeight: '24px'
    },
    "& .MuiButtonBase-root": { p: 0, m: 0, color: '#5423FF', fontWeight: 700 }
}

const GurdiansDetailsAbout = ({ userId, guardianUniqueId, userDetails, relationship, setUserDetailsStatus, setRelationship, setUserDetailsRelationship, closeDrawer }) => {
    const [status, setStatus] = useState(relationship);
    const [primaryGuardianRemove, setPrimaryGuardianRemove] = useState(false)
    const [guardianRemove, setGuardianRemove] = useState(false)
    const [makePrimaryGuardian, setMakePrimaryGuardian] = useState(false)
    const [makePrimary, makePrimaryOptions] = useMakePrimaryMutation()
    const [deleteGuardian, deleteGuardianOptions] = useDeleteGuardianMutation();

    useProtected(deleteGuardianOptions?.error);
    useProtected(makePrimaryOptions?.error);

    const attendance = [
        {
            label: 'Father',
            value: 'Father',
        },
        {
            label: 'Mother',
            value: 'Mother',
        },
        {
            label: 'Brother',
            value: 'Brother',
        },
        {
            label: 'Sister',
            value: 'Sister',
        },
        {
            label: 'Step-father',
            value: 'Step-father',
        },
        {
            label: 'Step-mother',
            value: 'Step-mother',
        },
        {
            label: 'Sibling',
            value: 'Sibling',
        },
        {
            label: 'Aunt',
            value: 'Aunt',
        },
        {
            label: 'Uncle',
            value: 'Uncle',
        },
        {
            label: 'Cousin',
            value: 'Cousin',
        },
        {
            label: 'Grandparent',
            value: 'Grandparent',
        },
    ];

    const handleChanges = (e) => (setState) => {
        if (e.target.name === 'status') {
            setStatus(e.target.value);
            setUserDetailsStatus(e.target.value);
        } else if (e.target.name === "relationShip") {
            setRelationship(e.target.value);
            setUserDetailsRelationship(e.target.value);
        }
    };
    //===================== DeleteGuardian ====================================//
    const handleDeleteGuardian = async () => {
        const result = await deleteGuardian({ id: userId, guardianId: [guardianUniqueId] });
        if (result?.data && !result?.data?.error) {
            toast.success("Guardian/Parent removed successfully");
            // setRole('');
            closeDrawer()
            setGuardianRemove(false)
        } else {
            toast.error(result.data.message);
        }
    };
    //===================== DeleteGuardian ====================================//

    //===================== MakePrimaryGuardian ====================================//
    const handleMakePrimaryGuardian = async () => {
        const result = await makePrimary({ userId: userId, gurdianId: userDetails?._id });
        if (result?.data && !result?.data?.error) {
            toast.success("Primary guardian/parent updated");
            // setRole('');
            closeDrawer()
            setMakePrimaryGuardian(false)
        } else {
            toast.error(result.data.message ? result.data.message : "Something went wrong");
        }
    }
    //===================== MakePrimaryGuardian ====================================//


    const handleNavigate = () => {
        window.location.href = `/users/${userDetails?._id}`
    }

    return (
        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
            <TableContainer component={Paper} sx={{ mt: 2, "&.MuiPaper-root": { boxShadow: 'none' } }}>
                <Table aria-label="simple table" sx={{ width: '100% ' }} >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: '18px', fontWeight: '600', color: '#1B1B1F' }}>About</TableCell>
                            <TableCell align="right">
                                <Button variant='text' sx={{ p: 0, m: 0, color: '#5423FF' }} onClick={handleNavigate}>View more details</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left" sx={tableLeftCellStyle}>Relationship</TableCell>
                            <TableCell align="left" sx={tableRightCellStyle}>
                                <SelectField
                                    FieldLabel={false}
                                    selectPlaceholder={relationship ? "" : "Select relationship"}
                                    selectedOption={relationship === "guardian" ? "" : relationship}
                                    value={relationship === "guardian" ? "" : relationship}
                                    options={attendance}
                                    handleChange={(e) => handleChanges(e)(setStatus)}
                                    name={'relationShip'}
                                //   helperText={errors?.status}
                                //   error={Boolean(errors?.status)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" sx={tableLeftCellStyle}>Address</TableCell>
                            <TableCell align="left" sx={tableRightCellStyle}> {(userDetails?.addressLine1
                                ? `${`${userDetails?.addressLine1},` || ""} ${`${userDetails?.city},` || ""
                                } ${userDetails?.state || ""} ${userDetails?.zip || ""
                                } ${userDetails?.country
                                    ? countries.find(
                                        (item) =>
                                            item.value === userDetails?.country
                                    ).label
                                    : ""
                                }`
                                : userDetails?.address) || (
                                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                                )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" sx={tableLeftCellStyle}>Highest Education</TableCell>
                            <TableCell align="left" sx={tableRightCellStyle}>{userDetails?.highestEducation || (
                                <span style={{ color: "#BBBFC9" }}>No data available</span>
                            )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" sx={tableLeftCellStyle}>Occupation</TableCell>
                            <TableCell align="left" sx={tableRightCellStyle}> {capitalizeWords(userDetails?.occupation) || (
                                <span style={{ color: "#BBBFC9" }}>No data available</span>
                            )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" sx={tableLeftCellStyle}>Preferred Language</TableCell>
                            <TableCell align="left" sx={tableRightCellStyle}>{capitalizeWords(
                                userDetails?.preferredLanguage
                            ) || (
                                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                                )}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={BoxContainerStyle}>
                <Typography variant='h4'>Remove guardian/parent</Typography>
                <Typography variant='p' sx={{ lineHeight: '26px' }}>Once a parent is removed they won't be able to access this student’s information.</Typography><br />
                <Button variant='text' onClick={() => userDetails?.isPrimary ? setPrimaryGuardianRemove(true) : setGuardianRemove(true)}>Remove Guardian/parent</Button><br />

            </Box>
            {
                !userDetails?.isPrimary && (
                    <Box sx={BoxContainerStyle}>
                        <Typography variant='h4'>Make primary guardian/parent</Typography>
                        <Typography variant='p' sx={{ lineHeight: '26px' }}>Make this user primary guardian/parent of [Student Name].</Typography><br />
                        <Button variant='text' onClick={() => setMakePrimaryGuardian(true)}>Make Primary</Button>
                    </Box>
                )
            }


            {/* * === Modal === * */}
            <DraggableDialog
                openDialog={primaryGuardianRemove}
                handleCloseDialog={() => setPrimaryGuardianRemove(false)}
                title="Primary guardian/parent is not removeable"
                body={
                    <div>To remove a primary guardian/parent, another one needs to be assigned as primary. Make another guardian/parent primary from their details screen.</div>
                }
                ModalFooter={true}
                oneButton={true}
                cancelButton={<span style={{ color: '#5423FF' }}>Okay</span>}
                // handleConfirmCloseDialog={handleClosefgfdg}
                actionButton="Remove"
            />
            <DraggableDialog
                openDialog={guardianRemove}
                handleCloseDialog={() => setGuardianRemove(false)}
                title="Remove guardian/parent"
                body={
                    <div>Are you sure you want to remove this guardian/parent?
                        Once you delete it, you can't get it back.</div>
                }
                ModalFooter={true}
                oneButton={false}
                cancelButton="Cancel"
                handleConfirmCloseDialog={handleDeleteGuardian}
                actionButton={<span style={{ color: '#5423FF' }}>Remove</span>}
            />
            {
                !userDetails?.isPrimary && (<DraggableDialog
                    openDialog={makePrimaryGuardian}
                    handleCloseDialog={() => setMakePrimaryGuardian(false)}
                    title="Make primary guardian/parent"
                    body={
                        <div>Are you sure you want to make [Shakil Mahmud] guardian/parent?
                            Starting from the next payment cycle, all future bills will be addressed to the new primary guardian/parent.</div>
                    }
                    ModalFooter={true}
                    oneButton={false}
                    cancelButton="Cancel"
                    handleConfirmCloseDialog={handleMakePrimaryGuardian}
                    actionButton={<span style={{ color: '#5423FF' }}>Confirm</span>}
                />)
            }

        </div>
    )
}

export default GurdiansDetailsAbout