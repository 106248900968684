import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';
import HorizontalTab from '../../../../components/HorizontalTab/HorizontalTab';
import Overview from './Overview';
import Billing from './Billing';
import Discount from './Discount';
import EmptyCard from '../../../../components/EmptyCard/EmptyCard';
import { useAddFeesMutation, useDeleteFeeMutation, useGetAllFeesQuery, useLazyGetFeeDetailsQuery, useUpdateFeesMutation } from '../../../../redux/features/fees/feeApi';
import useProtected from '../../../../hooks/useProtected';
import dayjs from 'dayjs';
import { useGetSchoolYearListQuery } from '../../../../redux/features/school_years/schoolYearApi';
import { useGetClassesBySchoolYearIdMutation, useGetClassLevelListQuery } from '../../../../redux/features/classes/classApi';
import { toast } from 'react-toastify';
import DraggableDialog from '../../../../components/hooks/Dialog';
import { isNonAlphabetic } from '../../../../hooks/HelperFunctions';
import moment from 'moment-timezone';
import { Cycle } from '../../../../assets/imgs/icons/Cycle';
import Notification from './Notification';
import BillAppliesTo from './BillAppliesTo';

import TextField from '../../../../components/Form/TextField/TextField';
import { useNavigate } from 'react-router-dom';








const FeesAndPlansListing = (props) => {

    const { setOpenCreatedNew, studentList } = props;

    const {
        data,
        isError,
        isLoading,
        error
    } = useGetAllFeesQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    const {
        data: schoolYearData,
        isError: schoolYearIsError,
        isLoading: schoolYearIsLoading,
        error: schoolYearError
    } = useGetSchoolYearListQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    const {
        data: classesData,
        isError: classesIsError,
        isLoading: classesIsLoading,
        error: classesError
    } = useGetClassLevelListQuery(undefined, {
        refetchOnMountOrArgChange: true
    })

    const [getFeeDetailsDataLazy, {
        data: feeDetailsData,
        isLoading: feeDetailsLoading,
        isError: feeDetailsIsError,
        error: feeDetailsError
    }] = useLazyGetFeeDetailsQuery()


    const [addFee, addFeeOptions] = useAddFeesMutation()
    const [updateFee, updateFeeOptions] = useUpdateFeesMutation()
    const [deleteFees, deleteFeesOptions] = useDeleteFeeMutation()
    const [getClassesBySchoolYearId, getClassesBySchoolYearIdOptions] = useGetClassesBySchoolYearIdMutation()


    useProtected(error);
    useProtected(classesError);
    useProtected(schoolYearError);
    useProtected(feeDetailsError);
    useProtected(addFeeOptions.error);
    useProtected(updateFeeOptions.error);
    useProtected(deleteFeesOptions.error);
    useProtected(getClassesBySchoolYearIdOptions.error);

    const [asc, setAsc] = useState(true)
    const [rows, setRows] = useState([]);
    const [editId, setEditId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deletedId, setDeletedId] = useState('')
    const [editFeeId, setEditFeeId] = useState(null)
    const [classList, setClassList] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [dateError, setDateError] = useState(false)
    const [feeDetails, setFeeDetails] = useState(null)
    const [feesAndPlans, setFeesAndPlans] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [classesSelected, setClassesSelected] = useState([])
    const [billingTypeValue, setBillingTypeValue] = useState(0)
    const [studentsSelected, setStudentsSelected] = useState([])
    const [schoolYearClasses, setSchoolYearClasses] = useState([]);
    const [classesSelectedIds, setClassesSelectedIds] = useState([]);
    const [confirmTextFieldError, setConfirmTextFieldError] = useState('')
    const [schoolYearClassesOptions, setSchoolYearClassesOptions] = useState([])

    let initialFeeObject = {
        "name": "",
        "description": "",
        "amount": 0,
        "type": "recurring", //recurring, onetime
        "dueDate": dayjs(),
        "discount": 0,
        "discountCodes": [],
        "applyCouponAutomatically": false,
        "billTo": {
            "academicYearId": "",
            "academicYearName": "",
            "classLevelIds": [],
            "groupIds": [],
            'studentIds': []
        },
        "processPaymentOn": 1,
        "billingCycle": "Monthly", //weekly, monthly, every_3_months, every_6_months, yearly,custom
        "billingCycleCustom": {
            "custom_number": 1,
            "custom_type": "Months" //days, weeks, months, years
        },
        "billingDurationStartDate": dayjs(),
        "billingCycleCount": 1,
        "firstPaymentProcessingDate": dayjs(),
        "notifications": [
            {
                "title": "Notification 1",
                "smsEnabled": false,
                "emailEnabled": false,
                "emailTemplate": "",
                "smsTemplate": "",
                "notifyBeforeDays": 1
            }
        ],
        "status": "inactive"
    }

    const initialFeeErrorObject = {
        "name": "",
        "type": "",
        "amount": "",
        "dueDate": "",
        "billingCycle": "",
        "billingDurationStartDate": "",
        "billingCycleCount": "",
        "studentIds": ""
    }

    const [feeObject, setFeeObject] = useState(initialFeeObject)
    const [feeErrorObject, setFeeErrorObject] = useState(initialFeeErrorObject)
    const [confirmTextFieldValue, setConfirmTextFieldValue] = useState('')

    const editPlanHandler = (editId) => {
        setEditFeeId(editId)
        setOpenCreatedNew(true)
        getFeeDetailsDataLazy(editId)
    }


    const navigate = useNavigate()
    const convertDataToCellRows = (arr) => {
        if (arr.length > 0) {
            return arr.map((item) => {
                // let couponStatus = item?.status;
                // let couponBackground = item?.status == 'active' ? '#EDF7EA' : '#FCE7E9';
                // let couponColor = item?.status == 'active' ? '#001233' : '#E63946';
                // let couponLineBackground = item?.status == 'active' ? '#41AB37' : '#BA1A1A';
                let feeType = item?.type
                let statusObj = { Status: true, background: '#F7E4E4', color: '#001233', linebackground: "#BA1A1A", statusLine: true, label: 'N/A', }
                if (item?.status == 'active') {
                    statusObj = { Status: true, background: '#EDF7EA', color: '#001233', linebackground: "#41AB37", statusLine: true, label: 'Active', }
                } else if (item?.status == 'inactive') {
                    statusObj = { Status: true, background: '#F7E4E4', color: '#001233', linebackground: "#BA1A1A", statusLine: true, label: 'Inactive', }
                }

                return ({
                    cell: [
                        { text: item?.name || "N/A" },
                        { text: item?.amount ? `$${item?.amount}` : "N/A" },
                        {
                            text: feeType === 'onetime' ? "One-off" :
                                <span className="paymentDateContainer">
                                    <Cycle color="#7D8597" height="16px" />
                                    {
                                        item?.billingCycle
                                    }
                                </span>
                        },
                        { text: item?.billTo?.academicYearName || 'N/A' },
                        // { text: item?.billTo?.academicYearName || 'N/A', chip: 'Current', chipStatus: true, },
                        { text: item?.discountCodes ? item?.discountCodes?.join(',') : "N/A" },
                        statusObj,
                        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

                    ],

                    //======= Action Date Start===== //
                    action: [
                        { label: 'Edit Payment', onClick: () => { editPlanHandler(item?._id) } },
                        { label: 'View Transactions', onClick: () => navigate(`/transactions?feesID=${item?._id}`) },
                        { label: 'Delete', ActionLinkTextColor: "#BA1A1A", onClick: () => handleOpenDeleteFeePlan(item._id) },
                        // { label: 'Delete', onClick: () => handleOpenDelete(item._id) },
                    ],
                    //======= Action Date End===== //
                })
            })

        } else {
            return [];
        }
    }


    useEffect(() => {
        if (!isLoading && !isError) {
            setRows(convertDataToCellRows(data?.body?.data));
            setFeesAndPlans(data?.body?.data);
        }
    }, [data, isLoading, isError]);

    useEffect(() => {
        if (!schoolYearIsLoading && !schoolYearIsError) {
            setSchoolYearList(schoolYearData?.body?.data);
        }
    }, [schoolYearData, schoolYearIsLoading, schoolYearIsError]);

    useEffect(() => {
        if (!classesIsLoading && !classesIsError) {
            setClassList(classesData?.body?.data);
        }
    }, [classesData, classesIsLoading, classesIsError]);

    useEffect(() => {
        if (!feeDetailsLoading && !feeDetailsIsError) {

            if (feeDetailsData?.body?.data) {
                let foundData = feeDetailsData?.body?.data
                let dataToSet = {
                    ...feeObject,
                    type: foundData?.type, //recurring, onetime
                    name: foundData?.name,
                    billTo: foundData?.billTo,
                    amount: foundData?.amount,
                    status: foundData?.status,
                    discount: foundData?.discount,
                    dueDate: dayjs(foundData?.dueDate),
                    description: foundData?.description,
                    billingCycle: foundData?.billingCycle, //weekly, monthly, every_3_months, every_6_months, yearly,custom
                    discountCodes: foundData?.discountCodes,
                    notifications: foundData?.notifications,
                    processPaymentOn: foundData?.processPaymentOn,
                    permanentlyClosed: foundData?.permanentlyClosed,
                    billingCycleCount: foundData?.billingCycleCount,
                    transactionStarted: foundData?.transactionStarted,
                    billingCycleCustom: foundData?.billingCycleCustom,
                    applyCouponAutomatically: foundData?.applyCouponAutomatically,
                    billingDurationStartDate: dayjs(foundData?.billingDurationStartDate),
                    firstPaymentProcessingDate: dayjs(foundData?.firstPaymentProcessingDate),

                }

                setFeeDetails(feeDetailsData?.body?.data)
                setFeeObject(dataToSet)
            }
        } else {
            setFeeObject(initialFeeObject)
            setFeeErrorObject(initialFeeErrorObject)
        }
    }, [feeDetailsData, feeDetailsLoading, feeDetailsIsError]);




    ///=================================== sorting part start =============//
    const ascendingOrDescendingSortingByName = (field) => {
        let sortedUsers = [...feesAndPlans]
        if (asc) {
            sortedUsers.sort((a, b) => a[field]?.localeCompare(b[field]));
        } else {
            sortedUsers.sort((a, b) => b[field]?.localeCompare(a[field]));
        }

        setRows(convertDataToCellRows(sortedUsers));
        setAsc(!asc)
    }
    const ascendingOrDescendingSortingBySubjectArea = (field) => {
        let sortedPlans = [...feesAndPlans]
        const alphabeticYears = sortedPlans?.filter((year) => !isNonAlphabetic(year?.billTo?.academicYearName?.charAt(0)))
        const nonAlphabeticYears = sortedPlans?.filter((year) => isNonAlphabetic(year?.billTo?.academicYearName?.charAt(0)))
        // Sort alphabetic years
        alphabeticYears.sort((a, b) => a?.billTo?.academicYearName.localeCompare(b?.billTo?.academicYearName));

        // Sort non-alphabetic years
        nonAlphabeticYears.sort((a, b) => a?.billTo?.academicYearName.localeCompare(b?.billTo?.academicYearName));
        if (asc) {
            sortedPlans = [...nonAlphabeticYears, ...alphabeticYears];
        } else {
            sortedPlans = [...alphabeticYears?.reverse(), ...nonAlphabeticYears?.reverse()];
        }
        setRows(convertDataToCellRows(sortedPlans));
        setAsc(!asc)
    }
    ///=================================== sorting part end =============//
    const onClick = () => {
        alert('onclick working fine!');
    };



    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', width: '25%', textAlign: 'left', },
        { id: 'name', numeric: false, disablePadding: true, label: 'Amount', width: '10%', textAlign: 'left', },
        { id: 'feeType', numeric: false, disablePadding: true, label: 'Cycle', width: '10%', textAlign: 'left', sort: "sort", headerOnClick: () => ascendingOrDescendingSortingByName('feeType'), },
        { id: 'billTo', numeric: false, disablePadding: true, label: 'Academic year', width: '25%', textAlign: 'left', sort: "sort", headerOnClick: () => ascendingOrDescendingSortingBySubjectArea('billTo'), },
        { id: 'name', numeric: false, disablePadding: true, label: 'Coupon', width: '20%', textAlign: 'left', },
        { id: 'name', numeric: false, disablePadding: true, label: 'Status', width: '20%', textAlign: 'left', },
        { id: 'name', numeric: false, disablePadding: true, label: '', width: '10%', textAlign: 'left', },

    ];


    //======= Delete dialog part start ===== //
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDelete = (id) => {

        setOpenDialog(true);
        setDeletedId(id)

    };
    const handleCloseDeleteDialog = () => {
        setOpenDialog(false);
    };
    const handleConfirmCourseDeleteDialog = async () => {
        try {
            const result = await deleteFees(deletedId)
            if (!result?.data?.error) {
                toast.success("Fee/plan deleted")
            } else {
                toast.error(result?.data?.message);
            }
        } catch (error) {
            toast.error("Error deleting course. Please try again.");
        }
        setOpenDialog(false);
        handleCloseDeleteFeePlang()
    };
    //======= Delete dialog part end ===== //



    //======= Delete Delete fee/plan? start ===== //
    const [openDialogDeleteFeePlan, setOpenDialogDeleteFeePlan] = useState(false);
    const handleOpenDeleteFeePlan = (id) => {

        setOpenDialogDeleteFeePlan(true);
        setDeletedId(id)

    };
    const handleCloseDeleteFeePlang = () => {
        setOpenDialogDeleteFeePlan(false);
    };
    const handleConfirmCourseDeleteFeePlan = async () => {
        try {
            const result = await deleteFees(deletedId)
            if (!result?.data?.error) {
                toast.success("Fee/plan deleted")
            } else {
                toast.error(result?.data?.message);
            }
        } catch (error) {
            toast.error("Error deleting Fee/plan. Please try again.");
        }
        setOpenDialogDeleteFeePlan(false);
    };
    //======= Delete Delete fee/plan? end ===== //







    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showErrorAsterisk = () => {
        return (<span style={{
            width: '5px', height: '5px', borderRadius: '100px',
            display: 'inline-block', backgroundColor: '#BA1A1A', position: 'relative', top: '-10px', left: '3px'
        }}></span>)
    }

    const showOverviewErrorDotIfAvailable = () => {
        if (feeErrorObject?.name) {
            return showErrorAsterisk()
        } else {
            return ''
        }

    }
    const showBillingErrorDotIfAvailable = () => {
        if (feeErrorObject?.type ||
            feeErrorObject?.amount ||
            feeErrorObject?.dueDate ||
            feeErrorObject?.billingStartDate ||
            feeErrorObject?.billingEndDate ||
            feeErrorObject?.billingCycle
        ) {
            return showErrorAsterisk()
        } else {
            return ''
        }
    }
    const showBillingAppliesErrorDotIfAvailable = () => {
        if (
            feeErrorObject?.studentIds
        ) {
            return showErrorAsterisk()
        } else {
            return ''
        }
    }

    const tabLabel = [
        {
            label: <div>Overview {showOverviewErrorDotIfAvailable()}</div>,
        },
        {
            label: <div>Billing {showBillingErrorDotIfAvailable()}</div>,
        },
        {
            label: <div>Bill/Applies to{showBillingAppliesErrorDotIfAvailable()}</div>,
        },
        {
            label: <div>Discount </div>,
        },

        {
            label: <div>Notification</div>,
        },
    ]
    const tabPanel = [
        {
            id: 0,
            panel: <Overview
                feeObject={feeObject}
                setFeeObject={setFeeObject}
                feeErrorObject={feeErrorObject}
                setFeeErrorObject={setFeeErrorObject} />,
        },
        {
            id: 1,
            panel: <Billing
                editFeeId={editFeeId}
                feeObject={feeObject}
                feeDetails={feeDetails}
                studentList={studentList}
                setFeeObject={setFeeObject}
                feeErrorObject={feeErrorObject}
                schoolYearList={schoolYearList}
                classesSelected={classesSelected}
                billingTypeValue={billingTypeValue}
                setFeeErrorObject={setFeeErrorObject}
                schoolYearClasses={schoolYearClasses}
                setClassesSelected={setClassesSelected}
                setBillingTypeValue={setBillingTypeValue}
                setStudentsSelected={setStudentsSelected}
                setSchoolYearClasses={setSchoolYearClasses}
                setClassesSelectedIds={setClassesSelectedIds}
                getClassesBySchoolYearId={getClassesBySchoolYearId}
                schoolYearClassesOptions={schoolYearClassesOptions}
                setSchoolYearClassesOptions={setSchoolYearClassesOptions}
            />,
        },

        {
            id: 2,
            panel: <BillAppliesTo
                feeObject={feeObject}
                classList={classList}
                setFeeObject={setFeeObject}
                feeErrorObject={feeErrorObject}
                schoolYearList={schoolYearList}
                classesSelected={classesSelected}
                billingTypeValue={billingTypeValue}
                setFeeErrorObject={setFeeErrorObject}
                schoolYearClasses={schoolYearClasses}
                setClassesSelected={setClassesSelected}
                setBillingTypeValue={setBillingTypeValue}
                setStudentsSelected={setStudentsSelected}
                setSchoolYearClasses={setSchoolYearClasses}
                setClassesSelectedIds={setClassesSelectedIds}
                getClassesBySchoolYearId={getClassesBySchoolYearId}
                schoolYearClassesOptions={schoolYearClassesOptions}
                setSchoolYearClassesOptions={setSchoolYearClassesOptions}
            />,
        },

        {
            id: 3,
            panel: <Discount
                feeObject={feeObject}
                setFeeObject={setFeeObject}
                feeErrorObject={feeErrorObject}
                setFeeErrorObject={setFeeErrorObject} />,
        },

        {
            id: 4,
            panel: <Notification
                feeObject={feeObject}
                setFeeObject={setFeeObject}
                feeErrorObject={feeErrorObject}
                setFeeErrorObject={setFeeErrorObject} />,
        },

    ]

    useEffect(() => {
        let { billTo } = feeObject

        let newBillTo = { ...billTo }
        newBillTo['classLevelIds'] = classesSelectedIds
        // setFeeObject({ ...feeObject, billTo: newBillTo })

    }, [classesSelectedIds])

    useEffect(() => {
        let { billTo } = feeObject

        let newBillTo = { ...billTo }
        newBillTo['studentIds'] = studentsSelected
        // setFeeObject({ ...feeObject, billTo: newBillTo })
    }, [studentsSelected])

    useEffect(() => {
        console.log('feeErrorObject', feeErrorObject)
    }, [feeErrorObject])


    const isFeeValid = async () => {

        let error = 0
        let errorObject = { ...feeErrorObject }

        const trimmedName = feeObject.name.trim();

        if (trimmedName == "") {
            error++
            errorObject = { ...errorObject, name: "Required" }
            if (feeObject?.status == "inactive") {
                toast.error("Please enter the fee/plan name to save it as inactive")
            }

        } else {
            errorObject = { ...errorObject, name: "" }
        }

        if (feeObject?.type == "") {
            if (feeObject?.status == "active") {
                error++
            }
            errorObject = { ...errorObject, type: "Required" }

        } else {
            errorObject = { ...errorObject, type: "" }
        }

        if (feeObject?.amount == "") {
            if (feeObject?.status == "active") {
                error++
            }
            errorObject = { ...errorObject, amount: "Required" }
        } else if (parseFloat(feeObject?.amount) > 999999.00) {
            if (feeObject?.status == "active") {
                error++
            }
            errorObject = { ...errorObject, amount: "Amount must be no more than $999,999.99" }
        } else {
            errorObject = { ...errorObject, amount: "" }
        }

        if (value == 1) {
            if (feeObject?.dueDate == "") {
                if (feeObject?.status == "active") {
                    error++
                }
                errorObject = { ...errorObject, dueDate: "Required" }
            } else {
                errorObject = { ...errorObject, dueDate: "" }
            }
        } else {
            if (feeObject?.billingDurationStartDate == "") {
                if (feeObject?.status == "active") {
                    error++
                }
                errorObject = { ...errorObject, billingDurationStartDate: "Required" }
            } else {
                errorObject = { ...errorObject, billingDurationStartDate: "" }
            }

            if (feeObject?.billingCycleCount == "") {
                if (feeObject?.status == "active") {
                    error++
                }
                errorObject = { ...errorObject, billingCycleCount: "Required" }
            } else {
                errorObject = { ...errorObject, billingCycleCount: "" }
            }



            if (feeObject?.billingCycle == "") {
                if (feeObject?.status == "active") {
                    error++
                }
                errorObject = { ...errorObject, billingCycle: "Required" }
            } else {
                errorObject = { ...errorObject, billingCycle: "" }
            }


        }

        setFeeErrorObject({ ...feeErrorObject, ...errorObject })

        return error > 0 ? false : true
    }

    useEffect(() => {
        console.log('feeObject: ', feeObject)

        if (feeObject.billingStartDate && feeObject.billingEndDate) {
            const startDate = dayjs(feeObject.billingStartDate);
            const endDate = dayjs(feeObject.billingEndDate);
            if (startDate.isAfter(endDate)) {
                setFeeErrorObject({ ...feeErrorObject, billingEndDate: "Billing end date cannot be before billing start date" })
                setDateError(true)
            } else {
                setFeeErrorObject({ ...feeErrorObject, billingEndDate: "" })
                setDateError(false)
            }

        }

    }, [feeObject])
    useEffect(() => {
        let val = (billingTypeValue == 0) ? 'recurring' : 'onetime'
        setFeeObject({ ...feeObject, type: val })
    }, [billingTypeValue])
    const getProcessedData = async () => {

        const {
            dueDate,
            billingDurationStartDate,
            billingCycleCount,
            processPaymentOn,
            billingCycle,
            billingCycleCustom,
            billTo,
            ...rest
        } = feeObject
        let newProcessedData = { ...rest }

        let newBillTo = { ...billTo }
        newProcessedData.billTo = newBillTo
        newProcessedData.type = billingTypeValue == 0 ? 'recurring' : 'onetime'

        if (billingTypeValue == 0) {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const currentMonth = new Date(feeObject.billingDurationStartDate).getMonth()
            const currentYear = new Date(feeObject.billingDurationStartDate).getFullYear()

            const currentMonthShort = (currentMonth == 11) ? months[0] : months[currentMonth + 1];
            const currentYearUpdated = (currentMonthShort == 'Dec') ? currentYear + 1 : currentYear;

            newProcessedData.billingDurationStartDate = feeObject.billingDurationStartDate
            newProcessedData.billingCycleCount = feeObject.billingCycleCount
            newProcessedData.processPaymentOn = feeObject.processPaymentOn
            newProcessedData.billingCycle = feeObject.billingCycle
            newProcessedData.billingCycleCustom = feeObject.billingCycleCustom
            newProcessedData.firstPaymentProcessingDate = dayjs(`${feeObject?.processPaymentOn + 1}-${currentMonthShort}-${currentYearUpdated}`)

        } else {
            newProcessedData.dueDate = feeObject.dueDate
        }



        return newProcessedData


    }

    const handleSaveFeeAndPlans = async () => {
        let data = { ...feeObject }

        let isValid = await isFeeValid()

        if (!isValid || dateError) {

            if (feeObject?.status == 'active') {
                toast.error("Required fields are missing")
            }
            return false
        }
        let processedData = await getProcessedData()


        let result = (editFeeId === "") ? await addFee(processedData) : await updateFee({ ...processedData, id: editFeeId })

        if (result?.data && result?.data?.error === false) {
            (editFeeId === "") ? toast.success(`Payment added`) : toast.success('Payment updated')
            if (editFeeId === "") {
                setClassesSelected([])
                setSchoolYearClassesOptions([])
            }
            // handleDrawerCloseAddCalendar()
            props?.CloseDrawer()

            setEditFeeId(null)
            setFeeObject(initialFeeObject)
            // setDuplicateId('')
            setFeeErrorObject(initialFeeErrorObject)
        } else {
            toast.error(result?.data?.message)
        }

    }
    const saveFeeAndPlansHandler = async () => {
        if (feeObject?.transactionStarted && feeObject?.status == "inactive" && !feeObject?.permanentlyClosed) {
            setConfirmTextFieldValue('')
            setOpenInactive(true)
        } else {
            handleSaveFeeAndPlans()
        }
    }

    const closeCreateFeeHandler = () => {
        props.CloseDrawer()
        if (editFeeId === "") {
            setClassesSelected([])
            setSchoolYearClassesOptions([])
        }
        // handleDrawerCloseAddCalendar()
        props?.CloseDrawer()

        setEditId('')
        // setDuplicateId('')
        setFeeObject(initialFeeObject)
        setFeeErrorObject(initialFeeErrorObject)
        setEditFeeId(null)
        getFeeDetailsDataLazy(0)
    }

    //======= Inactive dialog ===== //
    const [openInactive, setOpenInactive] = useState(false);
    const handleOpenInactive = () => {
        setOpenInactive(true);
    };
    const handleCloseInactive = () => {
        setConfirmTextFieldValue('')
        setConfirmTextFieldError('')
        setOpenInactive(false)
    };
    //======= Inactive dialog ===== //


    const handleConfirmInactivateFee = () => {
        if (confirmTextFieldValue == 'CONFIRM') {
            handleSaveFeeAndPlans()
            handleCloseInactive()
            setConfirmTextFieldValue('')
            setConfirmTextFieldError('')
        } else {
            setConfirmTextFieldError('Please type "CONFIRM" to proceed')
        }
    }
    return (
        <>
            <Table
                headCells={headCells}
                rows={rows}
                Checkbox={true}
                footer={true}
                RowsPerPage={20}
                isDataLoading={isLoading || schoolYearIsLoading}
            />

            {(rows?.length < 1) && <EmptyCard
                title="No plans added yet"
                description="Added plans will be visible here"
                showButton={true}
                btnLabel="Create new"
                onClick={props.EmptyCardOnClick}
            />}



            <SideDrawer
                title={editFeeId != "" ? `Edit Payment/Fee` : `Create Payment/Fee`}
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={(props.OpenDrawer)}
                handleDrawerClose={(e) => closeCreateFeeHandler()}
                FooterWithoutTab={true}
                ButtonLabel="Save"
                clickSaveButton={saveFeeAndPlansHandler}
                body={
                    <div style={{ margin: '-24px -24px 0 -24px' }}>
                        <div className='createPaymentDrawerTab'>
                            <HorizontalTab
                                tabLabel={tabLabel}
                                tabPanel={tabPanel}
                                value={value}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                }
            />

            {/* Delete Group*/}
            <DraggableDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDeleteDialog}
                title="Delete course?"
                body={<div>Are you sure you want to delete this fees & plans?<br /> Once you delete it, you can't get it back.</div>}
                ModalFooter={true}
                handleConfirmCloseDialog={handleConfirmCourseDeleteDialog}
                actionButton="Delete"
            />


            {/* Delete Group*/}
            <DraggableDialog
                openDialog={openDialogDeleteFeePlan}
                handleCloseDialog={handleCloseDeleteFeePlang}
                title="Delete fee/plan?"
                body={<div>Are you sure you want to delete this fee/plan?<br /> Once you delete it, you can't get it back.</div>}
                ModalFooter={true}
                handleConfirmCloseDialog={handleConfirmCourseDeleteDialog}
                actionButton="Delete"
            />


            {/* Inactive Confirmation*/}
            <DraggableDialog
                // openDialog={true}
                openDialog={openInactive}
                handleCloseDialog={handleCloseInactive}
                title="Change status to inactive"
                body={<div>Are you sure you want to make this fee/plan permanently inactive? <br></br>All future transactions will be canceled. This action cannot be undone.
                    <p style={{ paddingTop: "16px", paddingBottom: "12px" }}>Type <span style={{ color: "#BA1A1A" }}>CONFIRM</span> to proceed.</p>
                    <TextField
                        value={confirmTextFieldValue}
                        handleChange={(val) => setConfirmTextFieldValue(val)}
                        error={confirmTextFieldError?.length > 0 ? true : false}
                        helperText={confirmTextFieldError}
                    // handleChange={(e) => setConfirmTextFieldValue(e.target.value)}
                    />
                </div>}
                ModalFooter={true}
                handleConfirmCloseDialog={handleConfirmInactivateFee}
                actionButton={<div style={{ color: "#BA1A1A" }}>Confirm</div>}
            />

        </>
    );
};

export default FeesAndPlansListing;
