import React, { useEffect, useState } from 'react';
import TextField from '../../../../components/Form/TextField/TextField';
import TextArea from '../../../../components/Form/TextArea/TextArea';
import TextAreaCustom from '../../../../components/Form/TextArea/TextAreaCustom';


import SelectFieldMulticolor from '../../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor';


const Overview = (props) => {
    let { feeObject, setFeeObject, feeErrorObject, setFeeErrorObject } = props


    const onChangeHandler = (val, e, fieldName) => {

        setFeeObject({ ...feeObject, [fieldName]: val })
        setFeeErrorObject({ ...feeErrorObject, [fieldName]: "" })
    }


    const statustData = [
        { value: "inactive", statuscolor: "#BA1A1A", select: "Inactive" },
        { value: "active", statuscolor: "#41AB37", select: "Active" },
    ];

    const handleChange = (event, value) => {
        console.log(event.target.value, value);

        setFeeObject({ ...feeObject, status: event.target.value })
    };


    return (
        <>
            <div className='DrawerFormContainer'>


                {/* ==== Name Start ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel' style={{ marginTop: feeErrorObject?.name !== "" && '-28px' }}>
                        Status<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <SelectFieldMulticolor
                            labelinsideStatus={true}
                            statustData={statustData}
                            defaultValue={feeObject?.status}
                            value={feeObject?.status}
                            handleChange={handleChange}
                            height="52px"
                            width="auto"
                            Status={true}
                            textcolor="#1B1B1F"
                            disabled={feeObject?.permanentlyClosed || false}
                        />
                    </div>

                </div>
                {/* ==== Name End ===*/}

                {/* ==== Name Start ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel' style={{ marginTop: feeErrorObject?.name !== "" && '-28px' }}>
                        Name<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <TextField
                            value={feeObject?.name}
                            placeholder="Enter payment/fee name"
                            helperText={feeErrorObject?.name}
                            error={feeErrorObject?.name === "" ? false : true}
                            handleChange={(val, e) => onChangeHandler(val, e, 'name')}
                            count={50}
                            CharAlignMid="CharAlignMid"
                            disabled={feeObject?.permanentlyClosed || false}
                        />
                    </div>

                </div>
                {/* ==== Name End ===*/}


                {/* ==== Description Start ===*/}
                <div className='FloatingFormContainer'>

                    <div className='FloatingFormLabel' style={{ marginTop: '12px' }}>
                        Description
                    </div>

                    <div className='FloatingFormField'>
                        <TextAreaCustom
                            value={feeObject?.description}
                            // placeholder="Enter payment/fee name"
                            handleChange={(e) => onChangeHandler(e.target.value, e, 'description')}
                            count={100}
                            CharAlignMid="CharAlignMid"
                            disabled={feeObject?.permanentlyClosed || false}
                        />
                    </div>

                </div>
                {/* ==== Description End ===*/}


            </div>

        </>
    );
};

export default Overview;
