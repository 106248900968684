import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';




const PaymentMethod = () => {

    const onClick = () => {
        alert('onclick working fine!');
    };

    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Account', width: '50%', textAlign: 'left', },
        { id: 'calories', numeric: true, disablePadding: false, label: 'Account holder', width: '50%', textAlign: 'left', },
        { id: 'fat', numeric: true, disablePadding: false, label: '', width: '20%', textAlign: 'left', },

    ];

    const rows = [
        {
            cell: [
                {
                    textBold: <div style={{ display: "flex", alignItems: "center" }}>
                        <AccountBalanceIcon style={{ color: "#5C677D", marginRight: "8px", fontSize: "20px" }} />Checking - 9213</div>
                },
                { text: 'Shayan Mahmud ', textcolor: "#001233", },

            ],


        },
    ];

    return (
        <>

            <Table
                headCells={headCells}
                rows={rows}
                footer={true}
                innerHeader={true}
                innerTitle="Payment method"
                RowsPerPage={10}

                emptyTableTitle="No users added yet"
                emptyTableNote="No items available"
                btnLabel="ADD USERS"
                showButton={true}
                btnLink="/invited-users"
            //onClickEmptyCard={handleOpenAttendanceEntry}


            />

        </>
    );
};

export default PaymentMethod;
