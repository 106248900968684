import React, { useEffect, useState } from 'react';

import MultipleSeletChip from '../../../../components/Form/MultipleSeletChip/MultipleSeletChip';
import { useGetAllCouponsQuery } from '../../../../redux/features/coupons/couponApi';
import useProtected from '../../../../hooks/useProtected';
import { getDataMap } from '../../../../hooks/HelperFunctions';
import CheckBox from '../../../../components/Form/CheckBox/CheckBox';
import SwitchButtonIOS from '../../../../components/Form/SwitchButtonIOS/SwitchButtonIOS';
import SelectField from '../../../../components/Form/SelectField/SelectField';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SelectTemplate from '../../messages/SelectTemplate';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';


const AddNotification = (props) => {
    const {
        options,
        feeObject,
        setFeeObject,
        feeErrorObject,
        setFeeErrorObject,
        notificationObject,
        setNotificationObject,
        editNotificationIndex,
        setEditNotificationIndex,
    } = props

    const {
        data,
        isError,
        isLoading,
        error
    } = useGetAllCouponsQuery(undefined, { refetchOnMountOrArgChange: true });

    useProtected(error);

    const [coupons, setCoupons] = useState([]);
    const [customText, setCustomText] = useState(null)
    const [openTemplate, setTemplate] = useState(false)
    const [tempTemplate, setTempTemplate] = useState({})
    const [couponsOptions, setCouponsOptions] = useState([]);
    const [selectedCouponsOptions, setSelectedCouponsOptions] = useState([]);
    const [templateCopyTo, setTemplateCopyTo] = useState('')

    const handleOpenTemplate = () => setTemplate(true);
    const handleCloseTemplate = () => setTemplate(false);









    useEffect(() => {
        if (!isLoading && !isError) {
            setCoupons(data?.body?.data)
        }
    }, [data, isLoading, isError]);

    useEffect(() => {
        setCouponsOptions(getDataMap(
            coupons,
            "code",
            "code"
        ))
    }, [coupons])
    useEffect(() => {
        if (feeObject?.discountCodes?.length > 0) {
            const formattedArray = feeObject?.discountCodes?.map((option) => {
                return {
                    label: option,
                    value: option
                }
            })
            setSelectedCouponsOptions(formattedArray)
        } else {
            setSelectedCouponsOptions([])
        }
    }, [feeObject?.discountCodes])

    useEffect(() => {
        let filterOptions = couponsOptions?.filter((data) =>
            !selectedCouponsOptions?.some((option) => option?.value === data?.value))
        setCouponsOptions(filterOptions)
    }, [selectedCouponsOptions])

    useEffect(() => {
        const unselectedOptions = coupons.filter(coupon =>
            !selectedCouponsOptions.some(option => option.value === coupon.code)
        ).map(coupon => ({ label: coupon.code, value: coupon.code }));

        setCouponsOptions(unselectedOptions);
    }, [selectedCouponsOptions, coupons]);

    const handleChangeWithData = (fieldName, fieldData) => {
        setFeeObject({ ...feeObject, [fieldName]: fieldData.map(option => option.value) });
        setSelectedCouponsOptions(fieldData)
    };
    const onChangeCheck = (e) => {
        setFeeObject({
            ...feeObject, applyCouponAutomatically: e.target.checked
        });
    }


    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };


    const selectFieldChangeHandler = (e, fieldName) => {
        setNotificationObject({ ...notificationObject, [fieldName]: e.target.value })
    }

    useEffect(() => {
        console.log('notificationObject : ', notificationObject)
    }, [notificationObject])

    const notificationChangeHandler = (e, fieldName) => {
        setNotificationObject({ ...notificationObject, [fieldName]: e.target.checked })
    }

    const handleSelectTemplate = () => {
        setCustomText(tempTemplate?.content)
        handleCloseTemplate()
        setTempTemplate({})
    }

    useEffect(() => {
        if (templateCopyTo == 'sms') {
            setNotificationObject({ ...notificationObject, smsTemplate: customText })
        } else if (templateCopyTo == 'email') {
            setNotificationObject({ ...notificationObject, emailTemplate: customText })
        }
    }, [customText, templateCopyTo])

    const selectTemplateHandler = (templateFor) => {
        setTemplateCopyTo(templateFor)
        handleOpenTemplate()
    }

    return (
        <>
            <div className='DrawerFormContainer' style={{ marginTop: '-6px' }}>

                {/* ==== Select time ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel' style={{ marginTop: '29px' }}>
                        Select time
                    </div>

                    <div className='FloatingFormField'>
                        <SelectField
                            options={options}
                            handleChange={(e) => selectFieldChangeHandler(e, 'notifyBeforeDays')}
                            selectedOption={notificationObject?.notifyBeforeDays}
                        />
                    </div>

                </div>
                {/* ==== Select time End===*/}

                {/* ==== Select time ===*/}
                <div className='FloatingFormContainer'>

                    <div className='FloatingFormLabel' style={{ paddingTop: "16px" }}>
                        Select method
                    </div>

                    <div className='FloatingFormField'>
                        <div className='SwitchButtonContainer'>
                            <SwitchButtonIOS
                                title="Email"
                                handleChange={(e) => notificationChangeHandler(e, 'emailEnabled')}
                                //disabled
                                checked={notificationObject?.emailEnabled}
                            />

                            {notificationObject?.emailEnabled && <div className='SelectBoxTempSMS' onClick={(e) => selectTemplateHandler('email')}>
                                <div className={`SelectBoxTempSMSLeft ${notificationObject?.emailTemplate != '' && 'SelectedText'}`}>{notificationObject?.emailTemplate != '' ? notificationObject?.emailTemplate?.substring(0, 20) : 'Select template'}</div>
                                <div className='SelectBoxTempSMSRight'><ArrowForwardIosIcon /></div>
                            </div>}

                        </div>
                        <div className='SwitchButtonContainer'>
                            <SwitchButtonIOS
                                title="SMS"
                                handleChange={(e) => notificationChangeHandler(e, 'smsEnabled')}
                                //disabled
                                checked={notificationObject?.smsEnabled}
                            />

                            {notificationObject?.smsEnabled && <div className='SelectBoxTempSMS' onClick={(e) => selectTemplateHandler('sms')}>
                                <div className={`SelectBoxTempSMSLeft ${notificationObject?.smsTemplate != '' && 'SelectedText'}`}>{notificationObject?.smsTemplate != '' ? notificationObject?.smsTemplate?.substring(0, 20) : 'Select template'}</div>
                                <div className='SelectBoxTempSMSRight'><ArrowForwardIosIcon /></div>
                            </div>}

                        </div>
                    </div>

                </div>
                {/* ==== Select time End===*/}


            </div>

            {/* Select Template*/}

            <SideDrawer
                open={openTemplate}
                handleDrawerClose={handleCloseTemplate}
                clickSaveButton={handleSelectTemplate}
                title="Select Template"
                sideOpen="right"
                cancelButtonLabel="Cancel"
                ButtonLabel="SELECT"
                BackArrow={true}
                CloseArrowHide={true}
                FooterWithoutTab={true}
                padding="0"
                body={< SelectTemplate
                    tempTemplate={tempTemplate}
                    setTempTemplate={setTempTemplate} />}
            />

        </>
    );
};

export default AddNotification;
