import React, { useState } from 'react';
import { Button, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import Table from '../../../components/Table/Table';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import AddUsers from './AddUsers';
import AddNewGroup from './AddNewGroup';
import DraggableDialog from '../../../components/hooks/Dialog';
import {
  useGetGroupDetailsQuery,
  useAddGroupUsersMutation,
  useDeleteGroupUsersMutation,
  useDeleteGroupMutation,
  useGetUserSearchInGroupResultMutation,
  useLazyGetGroupDetailsQuery
} from '../../../redux/features/groups/groupsApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useProtected from '../../../hooks/useProtected';
import { toTitleCase } from '../../../utils/helpers';
import Filter from '../../filter/Filter';
import { useGetRoleListQuery } from '../../../redux/features/roles/roleApi';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { capitalizeWords, truncateText } from '../../../hooks/HelperFunctions';



const Grade = () => {
  const { group_id } = useParams();
  const { data, isLoading, isError, error } = useGetGroupDetailsQuery(group_id);

  const [getGroupDetailsLazy, {
    data: lazyData,
    isLoading: lazyIsLoading,
    isError: lazyIsError,
    error: lazyError,
  }] = useLazyGetGroupDetailsQuery(group_id, {
    refetchOnMountOrArgChange: true,
  });



  const [deleteGroup, deleteGroupOptions] = useDeleteGroupMutation();
  const [addGroupUsers, addGroupUsersOptions] = useAddGroupUsersMutation();
  const [getSearchedUserList, options] = useGetUserSearchInGroupResultMutation();
  const [deleteGroupUsers, deleteGroupUsersOptions] = useDeleteGroupUsersMutation();

  // const { data: roleData, isError: roleIsError, isLoading: roleIsLoading, error: roleError } = useGetRoleListQuery(undefined, {
  //   refetchOnMountOrArgChange: true
  // })
  const { data: roleData, isError: roleIsError, isLoading: roleIsLoading, error: roleError } = useGetUserTypesQuery(undefined, {
    refetchOnMountOrArgChange: true
  })

  useProtected(roleError);
  useProtected(options.error);
  useProtected(deleteGroupOptions.error);
  useProtected(addGroupUsersOptions.error);
  useProtected(deleteGroupUsersOptions.error);


  const [groupUsers, setGroupUsers] = useState([]);
  const [groupUsersToUpdate, setGroupUsersToUpdate] = useState([]);
  const [selectedActiveGroupUsers, setSelectedActiveGroupUsers] = useState([]);
  const [selectedToBlank, setSelectedToBlank] = useState(false)

  const [selectedGroup, setSelectedGroup] = useState('');
  const [createIdDrawer, setCreateIdDrawer] = useState(false);

  const handleOpenDrawer = () => setCreateIdDrawer(true);
  const handleCloseDrawer = () => setCreateIdDrawer(false);

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    if (!isTheActionPermitted('manageUserGroups')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
  };
  const navigate = useNavigate();
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  //======= Delete dialog ===== //

  //======= Remove  from group dialog ===== //
  const [openDialogRemoveGroup, setOpenRemoveGroup] = useState(false);
  const handleOpenRemoveGroup = () => {
    if (!isTheActionPermitted('manageUserGroups')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenRemoveGroup(true);
  };
  const handleCloseRemoveGroup = () => {
    setOpenRemoveGroup(false);
  };
  //======= Remove from group dialog  ===== //

  ///================================ add part start ============================///
  const [OpenAddUsers, setAddUsers] = useState(false);
  const handleOpenAddUsers = () => {
    if (!isTheActionPermitted('manageUserGroups')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setAddUsers(true);
  }
  const handleCloseAddUsers = () => {
    setAddUsers(false)
    setGroupUsers([])
  };


  const addUsersToGroup = async () => {
    if (groupUsersToUpdate?.length === 0) {
      toast.error("Please select at least one user");
      return false
    }
    let data = {
      groupId: group_id,
      users: groupUsersToUpdate
    };
    const result = await addGroupUsers(data);
    // console.log(result?.data?.body?.data?.users)
    if (!result?.data?.error) {
      toast.success('Users added to group successfully');
      setAddUsers(false)
      setGroupUsers([])
      setGroupUsersToUpdate([])
    } else {
      toast.error(result?.data?.message);
    }
  }
  ///================================ add part end ============================///
  useEffect(() => {
    console.log('korma', selectedActiveGroupUsers)
  }, [selectedActiveGroupUsers])

  //================================delete part start =====================================//

  const deleteUsersFromGroup = async () => {
    // if (!isTheActionPermitted('manageUserGroups')) {
    //   toast.error("You don't have access to this feature")
    //   return false
    // }
    setSelectedToBlank(true)
    let data = {
      groupId: group_id,
      users: selectedActiveGroupUsers
    };
    const result = await deleteGroupUsers(data);
    if (result?.data) {
      toast.success(`User${selectedActiveGroupUsers?.length > 1 ? "s" : ""}  removed`);
      setOpenDialog(false)
      handleCloseRemoveGroup()
    } else {
      toast.error(result?.data?.message);
    }
    setSelectedToBlank(false)
    setSelectedActiveGroupUsers([])
    // navigate(`/groups`)
  }

  const deleteGroupHandler = async () => {
    setSelectedToBlank(true)
    const result = await deleteGroup(group_id);
    if (result?.data) {
      toast.success('Group deleted');
      setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    setSelectedToBlank(false)
    setSelectedActiveGroupUsers([])
    navigate(`/groups`)
  }
  //================================delete part start =====================================//

  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([])
  const [streams, setStream] = useState([])
  const [endDate, setEndDate] = useState('')
  const [statuses, setStatuses] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [startDate, setStartDate] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchResult, setIsSearchResult] = useState(false)
  const [groupDetails, setGroupDetails] = useState({})
  useEffect(() => {
    if (!isLoading && !isError) {
      setGroupDetails(data.body.data)
      setRows(convertDataToCellRows(data.body.data.users));
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (!lazyIsLoading && !lazyIsError) {
      console.log(lazyData, 'haidoaisdfoajdf')
      setGroupDetails(lazyData?.body?.data)
      setRows(convertDataToCellRows(lazyData?.body?.data?.users));
    }
  }, [lazyData, lazyIsLoading, lazyIsError]);

  useEffect(() => {
    if (!roleIsLoading && !roleIsError) {
      setRoles(roleData?.body?.data);
    }
  }, [roleData, roleIsLoading, roleIsError]);

  const convertDataToCellRows = (arr) => {

    if (arr?.length > 0) {
      return arr.map((item) => ({
        cell: [
          { Check: true, width: '36px', user_id: item.userUniqueID, checked: true },
          { textBold: <span style={{ fontWeight: '700' }}>{truncateText(item?.name || "N/A", 35)}</span>, user_id: item.userUniqueID, },
          { text: item?.userUniqueID || "N/A" },
          { text: item?.userType ? toTitleCase(item?.userType) : "N/A" },
          { text: item?.email || "N/A" },



          {
            Status: true,
            background: item.status === 'active' ? '#EDF7EA' : '#FCE7E9',
            color: item.status === 'active' ? '#66BD50' : '#E63946',
            label: item?.status ? toTitleCase(item?.status) : 'N/A',
            fontSize: '12px',
            fontWeight: 600
          },
          {
            Button: true,
            buttonLabel: 'Details',
            buttonTextcolor: '#004FE0',
            buttonLink: `/users/${item?.id}`,
          },
        ]
      }));
    } else {
      return [];
    }
  }

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'USER TYPE',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'right',
    },
  ];


  const tabData = [
    {
      title: 'Users',
    },
    {
      title: 'Visitors (comin soon)',
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const breadcrumbsData = [
    {
      label: 'Groups',
      link: '/groups',
      icon: <PersonOutlineRoundedIcon fontSize="small" />,
    },

    {
      label: 'Details',
    },
  ];

  // console.log(selectedActiveGroupUsers)

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setSelectedActiveGroupUsers(prevUsers => [...prevUsers, item[0].user_id]);
    } else {
      // Remove the user from the array
      setSelectedActiveGroupUsers(prevUsers => prevUsers?.filter(user => user !== item[0].user_id));
    }

  }
  const handleOpenAddGroup = (id) => {
    if (!isTheActionPermitted('manageUserGroups')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setSelectedGroup(id);
    setCreateIdDrawer(true);
  };


  const status = [
    { id: 1, label: 'In Progress' },
    { id: 2, label: 'Dropped' },
    { id: 3, label: 'Blocked' },
    { id: 4, label: 'Completed' },
    { id: 5, label: 'Unavailable' },
  ];


  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setSearchTerm("")
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ groupId: group_id, term: searchTerm });
    if (result?.data) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
      setSearchTerm("")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part end ====================== //

  // Filter //
  // dates start
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];
  const oneMonthsAgo = new Date(currentDate);
  oneMonthsAgo.setMonth(currentDate.getMonth() - 1);
  const formattedOneMonthAgoStartDate = oneMonthsAgo.toISOString().split('T')[0]
  // dates end
  const [defaultDate, setDefaultDate] = useState(true);
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
    if (defaultDate) {
      setStartDate((formattedOneMonthAgoStartDate))
      setEndDate(new Date(formattedDate))
    }

  };
  const handleCloseFilterDrawer = async () => {
    setOpenFilter(false);
  };

  const handleCancelFilterDrawer = async () => {
    setOpenFilter(false);
    setDefaultDate(true);
    setStatuses([])
    setStream([])
    setUserTypes([])
    setStartDate('')
    setEndDate('')
    getGroupDetailsLazy(group_id)

    // const result = await getSearchedUserList({ groupId: group_id });
    // console.log({ result });
    // if (result?.data) {
    //   setRows(convertDataToCellRows(result?.data?.body?.data));
    // }
    console.log('arif : from close drawer');
  }

  // const currentDate = new Date();
  // const formattedDate = currentDate.toISOString().split('T')[0];
  // const oneMonthsAgo = new Date(currentDate);
  // oneMonthsAgo.setMonth(currentDate.getMonth() - 1);
  // const formattedOneMonthAgoStartDate = oneMonthsAgo.toISOString().split('T')[0]

  const filterHandler = async () => {

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];


    let startDateToSubmit = startDate
    let endDateToSubmit = endDate

    if (startDateToSubmit === "") startDateToSubmit = formattedDate
    if (endDateToSubmit === "") endDateToSubmit = formattedDate

    let startDateNew = new Date(startDateToSubmit)
    let endDateNew = new Date(endDateToSubmit)

    if (startDateNew > endDateNew) {
      startDateToSubmit = endDateNew.toISOString().split('T')[0]
    } else {
      startDateToSubmit = startDateNew.toISOString().split('T')[0]
    }
    endDateToSubmit = endDateNew.toISOString().split('T')[0]

    setStartDate(startDateToSubmit)
    setEndDate(endDateToSubmit)

    let dataToSubmit = {
      groupId: group_id,
      joinStartDateRange: startDateToSubmit,
      joinEndDateRange: endDateToSubmit,
      status: statuses,
      stream: streams,
      role: userTypes,
    }

    const result = await getSearchedUserList(dataToSubmit);
    console.log({ result });
    if (result?.data) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    }
    // handleCloseFilterDrawer()
    setOpenFilter(false);
    // setStatuses([])
    // setStream([])
    // setUserTypes([])
    // setStartDate(new Date(formattedOneMonthAgoStartDate))
    // setEndDate(new Date(formattedDate))
    setIsSearchResult(false)

  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs breadcrumbsData={breadcrumbsData} link="/groups" />
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="UserDetails">
            <li>
              <div className="UserDetailsleftcol">
                <div className="UserDetailsDetail">
                  <span style={{ display: 'inline-block' }}>
                    <h1>{(groupDetails) && groupDetails?.name}</h1>
                  </span>
                  <p>{(groupDetails) && groupDetails?.description}</p>
                </div>
              </div>

              <div className="UserListingAction">
                <div align="left">
                  <div className="EditUserProfile">
                    <div className="ActionButton">
                      <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                      >
                        Actions <KeyboardArrowDownRoundedIcon />
                      </Button>
                    </div>

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      sx={{
                        "& .MuiMenuItem-root": {
                          fontSize: '14px',
                          fontWeight: 600,
                        }
                      }}
                    >
                      <Paper sx={{ width: 120, boxShadow: "none" }}>
                        <MenuItem onClick={() => { handleOpenAddGroup(groupDetails._id); handleClose() }}>Edit</MenuItem>
                        <MenuItem onClick={() => { handleOpenDelete(); handleClose() }}>Delete</MenuItem>
                      </Paper>
                    </Menu>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </Grid>
        <Grid item md={12} xs={12}>
          <div className="TableSection">
            <Table
              YearLabel="May 2023"
              headCells={headCells}
              rows={rows}
              filterCountStatus={(statuses?.length + streams?.length + userTypes?.length + (!defaultDate ? 1 : 0)) > 0}
              filterCountNumber={(statuses?.length + streams?.length + userTypes?.length + (!defaultDate ? 1 : 0))}
              footer={true}
              innerHeader={true}
              AddButtonInner={true}
              addLabelInner="Add users"
              InnerButtonColor="#0450E1"
              InnerButtonTextColor="#fff"
              InnderButtonIcon={<AddRoundedIcon />}
              FilterButtonDrawer={true}
              //onClickFilterButton={onClickFilterButton}
              onClickInnerButton={handleOpenAddUsers}
              showSearchBox={data?.body?.data?.users?.length > 0 ? true : false}
              // showSearchBox={true}
              CheckboxDeleteModal={true}
              CheckboxDelete={true}
              DeleteText="REMOVE FROM GROUP"
              DeleteButtonOnclick={handleOpenRemoveGroup}
              handleCheckItem={setSelectedActiveGroupUsers}
              selectedCheckList={selectedActiveGroupUsers}
              handleRowClick={handleRowClick}
              customCellObjectId="user_id"
              deleteUsersFromGroup="deleteUsersFromGroup"
              setSelectedToBlank={selectedToBlank}
              isDataLoading={isLoading}
              onClickFilterButton={handleFilterDrawerOpen}
              RowsPerPage={20}
              emptyTableTitle="No users added yet"
              emptyTableNote="Users of this group will be visible here"
              btnLabel="ADD USERS"
              showButton={true}
              btnStartIcon={<AddRoundedIcon fontSize='small' />}
              onClickEmptyCard={handleOpenAddUsers}
              handleSearchText={handleSearchText}
              handleSearch={handleSearch}
              setResetSearchTerm={setResetSearchTerm}
              setSearchTerm={setSearchTerm}
              resetsearchTerm={resetsearchTerm}
              pageNumberFromOutSide={pageNumberFromOutSide}
            />
          </div>
        </Grid>
      </Grid>

      {/* Add Users*/}
      <SideDrawer
        open={OpenAddUsers}
        handleDrawerClose={handleCloseAddUsers}
        title="Add Users"
        sideOpen="right"
        ButtonLabel={`Add ${groupUsers?.length || 0} user${groupUsers?.length > 1 ? "s" : ""}`}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        padding="0"
        drawerWidth="700px"
        clickSaveButton={(e) => addUsersToGroup()}
        LeftButton={true}
        ButtonLeft="Invite New User"
        ButtonLeftIcon={<AddRoundedIcon />}
        ButtonLeftbackground="transparent"
        ButtonLeftBorder="#E4E2E6 1px solid"
        ButtonLeftTextColor="#0450E1"
        LeftButtonlink="/invite-users?value=1"
        body={
          <>
            <AddUsers
              groupDetails={groupDetails}
              groupUsers={groupUsers}
              setGroupUsers={setGroupUsers}
              addGroupUsersOptions={addGroupUsersOptions}
              setGroupUsersToUpdate={setGroupUsersToUpdate}
              groupUsersToUpdate={groupUsersToUpdate}
            />
          </>
        }
      />

      <SideDrawer
        open={createIdDrawer}
        handleDrawerClose={() => {
          setSelectedGroup('');
          handleCloseDrawer();
        }}
        title={`${selectedGroup ? 'Update' : 'Add New'} Group`}
        buttonLabel="Save"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        body={
          <>
            <AddNewGroup
              closeDrawer={() => {
                setSelectedGroup('');
                handleCloseDrawer();
              }}
              id={selectedGroup}
            />
          </>
        }
      />

      {/* Delete Group*/}
      {/* <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={deleteGroupHandler}
        title="Delete group?"
        body={<>Are you sure you want to delete this group?<br />Once you delete it, you can't get it back.</>}
        ModalFooter={true}
        actionButton="DELETE"
        cancelButton="CANCEL"
      /> */}
      <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={deleteGroupHandler}
        title="Delete group?"
        body={<>Are you sure you want to delete this group?<br />Once you delete it, you can't get it back.</>}
      />

      {/* Remove From Group*/}
      <DraggableDialog
        openDialog={openDialogRemoveGroup}
        handleCloseDialog={handleCloseRemoveGroup}
        title="Remove user from group?"
        body={<div style={{ fontSize: '14px' }}>Are you sure you want to remove this user from the group?</div>}
        handleConfirmCloseDialog={deleteUsersFromGroup}
        ModalFooter={true}
        actionButton="Remove"
      />



      {/* Filter */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        handleDrawerCloseButtonBottom={handleCancelFilterDrawer}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        clickSaveButton={(e) => filterHandler()}

        body={
          <>
            <Filter
              label="Status"
              checkboxesData={status}
              statuses={statuses}
              setStatuses={setStatuses}
              streams={streams}
              setStream={setStream}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              roles={roles}
              defaultDate={defaultDate}
              setDefaultDate={setDefaultDate}
            />
          </>
        }
      />

    </>
  );
};

export default Grade;
