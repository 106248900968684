import React, { useState } from 'react';
import Table from '../../../../components/Table/Table';
import {
  useGetSearchResultMutation,
  useGetSearchAttendanceUsersMutation,
  useGetUserByAttendanceQuery,
  useGetUserListQuery,
  useGetUsersByRoleQuery
} from '../../../../redux/features/users/userApi';
import useProtected from '../../../../hooks/useProtected';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import DrawerFooterActions from '../../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../../utils/helpers';
import { toast } from 'react-toastify';
import { truncateText } from '../../../../hooks/HelperFunctions';
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import Filter from './Filter';

import { useGetRoleListQuery } from '../../../../redux/features/roles/roleApi';



const SelectIndividuals = ({
  classList,
  feeObject,
  addedUsers,
  selectUsers,
  selectedList,
  messageObject,
  setAddedUsers,
  editeSeletedData,
  selectedListJust,
  addedUsersToUpdate,
  totalStudentsNumber,
  setAddedUsersToUpdate,
  renderFullItem = false,
  handleCloseSelectUsers,
}) => {
  const { data, isError, isLoading, error } = useGetUsersByRoleQuery('student', { refetchOnMountOrArgChange: true });

  useProtected(error);

  const [getSearchedUserList, options] = useGetSearchResultMutation();

  const [rows, setRows] = useState([]);
  const [seletectedAddUsers, setSeletectedAddUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectUser = (item) => {
    if (renderFullItem) {
      selectUsers(rows.filter(innerItem => selectedUsers.includes(innerItem.item._id)).map(rowItem => rowItem.item))
    } else {
      selectUsers(selectedUsers);
    }
    handleCloseSelectUsers();
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.filter((item) => !feeObject?.billTo?.studentIds?.includes(item?._id)).sort((a, b) => a.firstName.localeCompare(b.firstName)).map((item) => {
        let forthObject = { text: 'N/A', textcolor: "#001233" }
        if (item?.classLevelIds?.length == 1) {
          let foundClass = classList?.find((classObj) => classObj?._id == item?.classLevelIds[0])
          forthObject = { text: foundClass?.name || "N/A", textcolor: "#001233" }
        } else if (item?.classLevelIds?.length > 1) {
          let classesToShow = []
          item?.classLevelIds?.map((classLevelId, index) => {
            let foundClass = classList?.find((classObj) => classObj?._id == classLevelId)
            if (foundClass && index > 0) {
              classesToShow?.push(<li>{foundClass?.name}</li>)
            }
          })
          let foundFirstClass = classList?.find((classObj) => classObj?._id == item?.classLevelIds[0])
          forthObject = {
            text: foundFirstClass?.name || "N/A", textcolor: "#001233", tooltipViewTextEnd: true, TextWithLinkText: item?.classLevelIds?.length > 1 && `${classesToShow?.length} more`,
            tooltipText: <div>{classesToShow}</div>,
          }
        }
        return ({
          item: item,
          cell: [
            {
              Check: true,
              checkBoxColor: 'secondary',
              width: "48px",
              padding: "0",
              user_id: item._id,
              checked: true,
              user: {
                id: item?._id,
                name: `${item?.firstName} ${item?.lastName}`,
                // name: truncatedName,
                email: item?.email,
                status: item?.status,
                role: item?.role,
                userUniqueID: item?.userUniqueID,
                gender: item?.gender,
                schoolId: item?.schoolId,
                firstName: item?.firstName || "",
                lastName: item?.lastName || "",
                middleName: item?.middleName || "",
                phone: item?.phone || "",
              }
            },
            {
              // textBold: `${item.firstName} ${item.lastName}`,
              textBold: truncateText(`${item.firstName} ${item.lastName}`, 30),
              user_id: item._id
              // handleSelect: () => handleSelectUser(item),
            },
            { text: item?.userUniqueID ? item.userUniqueID : 'N/A' },
            forthObject
          ],
        })
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      let activeStudents = data?.body?.data?.filter((student) => student?.status == 'active')
      setRows(convertDataToCellRows(activeStudents));
      setSeletectedAddUsers(data.body.data)
    }
  }, [data, isLoading, isError]);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '50%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '20%',
      textAlign: 'left',
    },

    {
      id: 'Class/Level',
      numeric: false,
      disablePadding: true,
      label: 'Class/Level',
      width: '30%',
      textAlign: 'left',
    },
  ];
  //-----search part start------//
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: "", userType: ['student'] });
    searchDataShowing(result)
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await getSearchedUserList({ term: searchTerm, userType: ['student'] });
    searchDataShowing(result)
  };
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      let activeStudents = result?.data?.body?.data?.filter((student) => student?.status == 'active')
      console.log(activeStudents)
      setRows(convertDataToCellRows(activeStudents));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  //-----search part end------//


  useEffect(() => {
    let newUsers = []
    addedUsers?.map((users) => {
      let foundUser = seletectedAddUsers.find((user) => user?._id === users || user?.id === users)
      if (foundUser) {
        newUsers.push({
          id: foundUser?._id,
          name: `${foundUser?.firstName} ${foundUser?.lastName}`,
          email: foundUser?.email,
          status: foundUser?.status,
          role: foundUser?.role,
          userUniqueID: foundUser?.userUniqueID,
          gender: foundUser?.gender,
          schoolId: foundUser?.schoolId,
          firstName: foundUser?.firstName || "",
          lastName: foundUser?.lastName || "",
          middleName: foundUser?.middleName || "",
          phone: foundUser?.phone || ""
        })
      }
    })
    setAddedUsersToUpdate(newUsers)
  }, [addedUsers, seletectedAddUsers])

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the user to the array
      setAddedUsers(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setAddedUsers(prevUsers => prevUsers.filter(user => user?.id !== item[0].user?.id));
    }
  }




  // FILTER //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const [attendanceCodeOptionsUnfiltered, setAttendanceCodeOptionsUnfiltered] = useState([]);
  const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);
  const [roles, setRoles] = useState([])

  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };


  const [streams, setStream] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const handleCloseFilterDrawer = async () => {
    setStream([])
    setUserTypes([])
    setOpenFilter(false);
    setRows(convertDataToCellRows(data?.body?.data));
  };

  const filterHandler = async () => {
    //const result = await searchAttendance({ term: searchTerm, date: filterDate, userType: userTypes, streams: streams });
    // console.log(result?.data?.body?.data);
    // if (result?.data?.body?.data) {
    //   setRows(convertDataToCellRows(result?.data?.body?.data));
    // }
    setOpenFilter(false);
  }


  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          link="/invited-users"
          isDataLoading={isLoading || options?.isLoading}
          pageNumberFromOutSide={pageNumberFromOutSide}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          showSearchBox={true}
          handleCheckItem={setAddedUsers}
          selectedCheckList={addedUsers}
          handleRowClick={handleRowClick}
          customCellObjectId="user_id"
          searchBoxPlaceholder="Search users"
          RowsPerPage={20}
          CheckboxDelete={true}
          FilterButtonDrawer={true}
          onClickFilterButton={handleFilterDrawerOpen}
          SelectedCounter={true}

          SelectedValueCounter={`${totalStudentsNumber} students selected`}
        />
      </div>

      <DrawerFooterActions
        isLoading={false}
        handleClose={handleCloseSelectUsers}
        handleAction={handleSelectUser}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={`Select ${selectedUsers.length > 0 ? selectedUsers.length : 0
          } users`}
      />


      {/* Filter */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        handleDrawerCloseButtonBottom={handleCloseFilterDrawer}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        BtnColor="#0450E1"
        BtnTextcolor="#fff"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        clickSaveButton={(e) => filterHandler()}
        drawerWidth="755px"
        BackArrow={true}
        CloseArrowHide={true}
        body={
          <>
            <Filter
              label="Status"
              // checkboxesData={status}
              streams={streams}
              setStream={setStream}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              roles={roles}
              attendanceCodeOptionsUnfiltered={attendanceCodeOptionsUnfiltered}
              attendanceCodeOptions={attendanceCodeOptions}

            />
          </>
        }
      />


    </>
  );
};

export default SelectIndividuals;
