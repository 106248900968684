import React from 'react'
import { Button } from '@mui/material'
import AppleStore from "../../../assets/imgs/store-button-image/apple-store.png"
import GoogleStore from "../../../assets/imgs/store-button-image/google-store.png"

const AboutDeenschool = () => {

    return (
        <div className='aboutDeenschoolContainer'>
            <img src="https://deenschool-website-assets.s3.amazonaws.com/assets/logos/keeperedu-logos/keeper-education-management-logo.png" alt="deeen school" />
            <span>KeeperEdu is the truly all-in-one, simple, and efficient education management platform for students, parents, faculty, and schools.
                Use the KeeperEDU - Scan app to take attendance efficiently.</span>
            <div className='storeButtonContainer'>
                <Button variant='text' sx={{ width: '210px' }} href="https://apps.apple.com/us/app/deenschool-scan/id6478858185">
                    <img src={AppleStore} alt="applestore button image" width="190px" />
                </Button>
                <Button variant='text' sx={{ width: 'object-fit' }} href="https://play.google.com/store/apps/details?id=com.afnanilab.deenscan&pli=1">
                    <img src={GoogleStore} alt="google play store button image" width="190px" />
                </Button>
            </div>
        </div>
    )
}

export default AboutDeenschool