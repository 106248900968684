import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import {
  useGetSearchResultMutation,
  useGetSearchAttendanceUsersMutation,
  useGetUserByAttendanceQuery,
  useGetUserListQuery
} from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import { truncateText } from '../../../hooks/HelperFunctions';

const SelectIndividuals = ({
  editeSeletedData,
  messageObject,
  setAddedUsersToUpdate,
  setAddedUsers,
  addedUsersToUpdate,
  addedUsers,
  selectUsers,
  handleCloseSelectUsers,
  selectedList,
  selectedListJust,
  renderFullItem = false
}) => {
  const { data, isError, isLoading, error } = useGetUserListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useProtected(error);

  const [getSearchedUserList, options] = useGetSearchResultMutation();

  const [rows, setRows] = useState([]);
  const [seletectedAddUsers, setSeletectedAddUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectUser = (item) => {
    if (renderFullItem) {
      selectUsers(rows.filter(innerItem => selectedUsers.includes(innerItem.item._id)).map(rowItem => rowItem.item))
    } else {
      selectUsers(selectedUsers);
    }
    handleCloseSelectUsers();
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {

        return ({
          item: item,
          cell: [
            {
              Check: true,
              checkBoxColor: 'secondary',
              width: "48px",
              padding: "0",
              user_id: item._id,
              checked: true,
              user: {
                id: item?._id,
                name: `${item?.firstName} ${item?.lastName}`,
                // name: truncatedName,
                email: item?.email,
                status: item?.status,
                userType: item?.userType,
                userUniqueID: item?.userUniqueID,
                gender: item?.gender,
                schoolId: item?.schoolId,
                firstName: item?.firstName || "",
                lastName: item?.lastName || "",
                middleName: item?.middleName || "",
                phone: item?.phone || "",
                homePhone: item?.homePhone || "",
                cellPhone: item?.cellPhone || ""
              }
            },
            {
              // textBold: `${item.firstName} ${item.lastName}`,
              textBold: truncateText(`${item.firstName} ${item.lastName}`, 30),
              user_id: item._id
              // handleSelect: () => handleSelectUser(item),
            },
            { text: item?.userUniqueID ? item.userUniqueID : 'N/A' },
            { text: item?.userType ? toTitleCase(item.userType) : 'N/A' },
            { text: '2nd' },
          ],
        })
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setSeletectedAddUsers(data.body.data)
    }
  }, [data, isLoading, isError]);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'User Type',
      width: '20%',
      textAlign: 'left',
    },

    {
      id: 'Class/Level',
      numeric: false,
      disablePadding: true,
      label: 'Class/Level',
      width: '30%',
      textAlign: 'left',
    },
  ];
  //-----search part start------//
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: "" });
    searchDataShowing(result)
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await getSearchedUserList({ term: searchTerm });
    searchDataShowing(result)
  };
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  //-----search part end------//


  useEffect(() => {
    let newUsers = []
    addedUsers?.map((users) => {
      let foundUser = seletectedAddUsers.find((user) => user?._id === users || user?.id === users)
      if (foundUser) {
        newUsers.push({
          id: foundUser?._id,
          name: `${foundUser?.firstName} ${foundUser?.lastName}`,
          email: foundUser?.email,
          status: foundUser?.status,
          userType: foundUser?.userType,
          userUniqueID: foundUser?.userUniqueID,
          gender: foundUser?.gender,
          schoolId: foundUser?.schoolId,
          firstName: foundUser?.firstName || "",
          lastName: foundUser?.lastName || "",
          middleName: foundUser?.middleName || "",
          phone: foundUser?.phone || "",
          homePhone: foundUser?.homePhone || "",
          cellPhone: foundUser?.cellPhone || ""
        })
      }
    })
    setAddedUsersToUpdate(newUsers)
  }, [addedUsers, seletectedAddUsers])

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the user to the array
      setAddedUsers(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setAddedUsers(prevUsers => prevUsers.filter(user => user?.id !== item[0].user?.id));
    }
  }



  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          link="/invited-users"
          isDataLoading={isLoading || options?.isLoading}
          pageNumberFromOutSide={pageNumberFromOutSide}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          showSearchBox={true}
          handleCheckItem={setAddedUsers}
          selectedCheckList={addedUsers}
          handleRowClick={handleRowClick}
          customCellObjectId="user_id"
          searchBoxPlaceholder="Search users"
          RowsPerPage={20}
          CheckboxDelete={true}
        />
      </div>
      <DrawerFooterActions
        isLoading={false}
        handleClose={handleCloseSelectUsers}
        handleAction={handleSelectUser}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={`Select ${selectedUsers.length > 0 ? selectedUsers.length : 0
          } users`}
      />
    </>
  );
};

export default SelectIndividuals;
