import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import Filter from './Filter';
import Overview from './Overview';
import PaymentMethod from './PaymentMethod';
import Timeline from './Timeline';
import RelatedPayments from './RelatedPayments';

import ZelleIcon from '../../../../assets/imgs/paymentIcon/Zelle.svg'
import CheckIcon from '../../../../assets/imgs/paymentIcon/Check.svg'
import CashIcon from '../../../../assets/imgs/paymentIcon/Cash.svg'
import Zelle from './Zelle';
import Check from './Check';
import Cash from './Cash';
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";
import CircleIcon from '@mui/icons-material/Circle';
import { useGetAllTransactionsQuery, useLazyGetPaymentDetailsQuery, useTransactionsSearchMutation } from '../../../../redux/features/transactions/transactionApi';
import { capitalizeWords, truncateText } from '../../../../hooks/HelperFunctions';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import useProtected from '../../../../hooks/useProtected';
import Tooltip from '../../../../components/Form/Tooltip/Tooltip';
import { useGetCouponsByCodesMutation } from '../../../../redux/features/coupons/couponApi';
import { useSearchParams } from 'react-router-dom';


const Transactions = () => {

    const { data, isLoading, isError, error } = useGetAllTransactionsQuery();
    const [getSearchDatas, getSearchDatasOptions] = useTransactionsSearchMutation()
    const [transactions, setTransactions] = useState([])
    const [coupons, setCoupons] = useState([])
    const [rows, setRows] = useState([]);
    const [resetsearchTerm, setResetSearchTerm] = useState(false);
    const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [transactionsDetails, setTransactionsDetails] = useState({});
    const [getCouponsByCodes, getCouponsByCodesOptions] = useGetCouponsByCodesMutation()
    const [getPaymentDetails, getPaymentDetailsOptions] = useLazyGetPaymentDetailsQuery(selectedId, {
        refetchOnMountOrArgChange: true,
    });

    const [searcParams] = useSearchParams()
    const feesID = searcParams.get("feesID")

    useProtected(error);
    useProtected(getSearchDatasOptions?.error);
    useProtected(getCouponsByCodesOptions?.error);
    useProtected(getPaymentDetailsOptions?.error);

    // useEffect(() => {
    //     if (!isLoading && !isError) {
    //         setTransactions(data.body.data)
    //     }
    // }, [data, isLoading, isError]);

    const getTransactions = () => {
        if (!isLoading && !isError && data) {
            setTransactions(data.body.data)
        }
    }

    const getTransactionsByfeesID = async (id) => {
        let data = {
            "cycle": [],
            "status": [],
            "dueDateStart": "",
            "dueDateEnd": "",
            "academicYearIds": [],
            "userUniqueID": [],
            "userType": "",
            "term": "",
            "feesnplanIds": id
        }

        try {
            const result = await getSearchDatas(data);
            if (!result?.data?.error) {
                setTransactions(result?.data?.body?.data)
            }

        } catch (error) {
            console.error(error?.message);

        }
    }

    useEffect(() => {
        if (feesID) {
            getTransactionsByfeesID(feesID)
        } else {
            getTransactions()
        }
    }, [feesID, data, isLoading, isError])

    useEffect(() => {
        if (transactions?.length > 0) {
            setRows(convertDataToCellRows(transactions));
        }
    }, [transactions])

    //*===================================== get Coupons By Codes ============================== *//
    const getCoupons = async (datas) => {
        try {
            let codes = { codes: datas }
            const result = await getCouponsByCodes(codes)
            if (!result?.data?.error) {
                setCoupons(result?.data?.body?.data)
            }
        } catch (error) {
            console.log(error?.message)
        }

    }
    //*===================================== get Coupons By Codes ============================== *//

    // DETAILS //
    const getSinglePaymentDetailData = async (id) => {
        try {
            const result = await getPaymentDetails(id)
            if (result?.data?.body?.data && !result?.data?.error) {
                setTransactionsDetails(result?.data?.body?.data);
                if (result?.data?.body?.data?.appliedCouponCodes?.length > 0) {
                    await getCoupons(result?.data?.body?.data?.appliedCouponCodes)
                }

            }
        } catch (error) {
            console.log(error?.message)
        }

    }
    const [openDetails, setOpenDetails] = useState(false);
    const handleDrawerOpenDetails = (id) => {
        setSelectedId(id)
        getSinglePaymentDetailData(id)
        setOpenDetails(true);
    };
    const handleDrawerCloseDetails = () => {
        setOpenDetails(false);
        setCoupons([])
        setTransactionsDetails({})
    };

    // UPDATE STATUS //
    const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
    const handleDrawerOpenUpdateStatus = () => {
        setOpenUpdateStatus(true);
    };
    const handleDrawerCloseUpdateStatus = () => {
        setOpenUpdateStatus(false);
    };


    // FILTER //
    const [openFilterDrawer, setOpenFilter] = useState(false);
    const [attendanceCodeOptionsUnfiltered, setAttendanceCodeOptionsUnfiltered] = useState([]);
    const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);
    const [roles, setRoles] = useState([])

    const handleFilterDrawerOpen = () => {
        setOpenFilter(true);
    };


    const [streams, setStream] = useState([])
    const [userTypes, setUserTypes] = useState([])
    const handleCloseFilterDrawer = async () => {
        setStream([])
        setUserTypes([])
        setOpenFilter(false);
        // setRows(convertDataToCellRows(data?.body?.data));
    };

    const filterHandler = async () => {
        //const result = await searchAttendance({ term: searchTerm, date: filterDate, userType: userTypes, streams: streams });
        // console.log(result?.data?.body?.data);
        // if (result?.data?.body?.data) {
        //   setRows(convertDataToCellRows(result?.data?.body?.data));
        // }
        setOpenFilter(false);
    }




    //* ============== search part start ==================================================== *//
    useEffect(() => {
        if (resetsearchTerm) {
            setTimeout(async () => {
                await handleResetSearch();
            }, 300);
            setResetSearchTerm(false);
        }
    }, [resetsearchTerm]);

    const handleSearchText = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };
    const handleResetSearch = async () => {
        setPageNumberFromOutSide(true);
        let data = {
            "cycle": [],
            "status": [],
            "dueDateStart": "",
            "dueDateEnd": "",
            "academicYearIds": [],
            "userUniqueID": [],
            "userType": "",
            "term": "",
            "feesnplanIds": ""
        }
        try {
            const result = await getSearchDatas(data);
            searchDataShowing(result);
        } catch (error) {
            console.error(error?.message);

        }
    };

    const handleSearch = async () => {
        setPageNumberFromOutSide(true);
        let data = {
            "cycle": [],
            "status": [],
            "dueDateStart": "",
            "dueDateEnd": "",
            "academicYearIds": [],
            "userUniqueID": [],
            "userType": "",
            "term": searchTerm,
            "feesnplanIds": ""
        }
        try {
            const result = await getSearchDatas(data);
            searchDataShowing(result);
        } catch (error) {
            console.error(error?.message);

        }
    };
    const searchDataShowing = (result) => {
        if (!result?.data?.error) {
            setRows(convertDataToCellRows(result?.data?.body?.data));
        } else {
            toast.error("Something went wrong");
        }
        setPageNumberFromOutSide(false);
    };
    //* ============== search part start ==================================================== *//










    const onClick = () => {
        alert('onclick working fine!');
    };

    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Parent', width: '20%', textAlign: 'left', },
        { id: 'calories', numeric: true, disablePadding: false, label: 'Student', width: '20%', textAlign: 'left', },
        { id: 'fat', numeric: true, disablePadding: false, label: 'Fee/Plan', width: '20%', textAlign: 'left', },
        { id: 'carbs', numeric: true, disablePadding: false, label: 'Method', width: '15%', },
        { id: 'carbs', numeric: true, disablePadding: false, label: 'Amount', width: '15%', },
        { id: 'carbs', numeric: true, disablePadding: false, label: 'status', width: '15%', },
        { id: 'carbs', numeric: true, disablePadding: false, label: 'Date', width: '15%', },
        { id: 'carbs', numeric: true, disablePadding: false, label: '', width: '15%', },
    ];


    const getStatusStyles = (status) => {
        switch (status?.toLowerCase()) {
            case "due":
                return { background: '#E8EAED', color: '#001233', linebackground: "#979DAC", label: 'Due', };
            case "success":
                return { background: '#E1F2E8', color: '#0B9444', linebackground: "#41AB37", label: 'Paid', };
            case "failed":
                return { background: '#F7E4E4', color: '#001233', linebackground: "#BA1A1A", label: 'Failed', };
            case "active":
                return { background: '#E1F2E8', color: '#0B9444', linebackground: "#41AB37", label: 'Active', };

            default:
                return {
                    background: "#EEEEEE",
                    color: "#999999",
                    linebackground: "#999999",
                    label: "N/A",
                };
        }
    };

    const convertDataToCellRows = (arr) => {
        if (arr?.length > 0) {
            return arr?.map((item) => {
                const studentDetails = item?.student;
                const paymentStatus = getStatusStyles(item?.status);
                // const fullFillMentStatus = getStatusStyles(
                //     item?.orderStatus === "cancelled" ? "cancelled" : "fulfilled"
                // );

                return {
                    cell: [
                        { linkText: capitalizeWords(item?.parent) || "N/A", linkTextStatus: true, link: '#', },
                        {
                            linkText: truncateText(`${capitalizeWords(
                                studentDetails?.firstName
                            )} ${capitalizeWords(studentDetails?.lastName)}`, 30) || "N/A", linkTextStatus: true, link: '#',
                        },
                        { text: capitalizeWords(item?.name) || "N/A" },
                        {
                            text: item?.paymentMethod || "N/A"
                        },
                        { text: `$${item?.amount}` },
                        { Status: true, statusLine: true, fontSize: '12px', ...paymentStatus },
                        {
                            text: item?.paymentDate ? (
                                moment(item?.paymentDate)?.format("DD-MMM-YYYY")
                            ) : item?.cycle === "onetime" && item?.dueDate ? (
                                <span className="paymentDateContainer">
                                    {item?.paymentDate
                                        ? moment(item?.paymentDate)?.format("DD-MMM-YYYY")
                                        : moment(item?.createdAt)?.format("DD-MMM-YYYY")}
                                    <Tooltip
                                        TooltipText={
                                            <span>
                                                Upcoming payment scheduled:{" "}
                                                <b>{moment(item?.dueDate)?.format("DD-MMM-YYYY")}</b>
                                            </span>
                                        }
                                        Tooltip={<QueryBuilderRoundedIcon fontSize="small" />}
                                    />
                                </span>
                            ) : (
                                "N/A"
                            ),
                        },

                        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

                    ],

                    //======= Action Date Start===== //
                    action: [
                        { label: 'View Details', onClick: () => handleDrawerOpenDetails(item?._id) },
                        { label: 'Update Status', onClick: handleDrawerOpenUpdateStatus },
                    ],
                    //======= Action Date End===== //
                }
            });
        } else {
            return [];
        }
    };
    // const rows = [
    //     {
    //         cell: [
    //             { linkText: 'Abdullah Mahmud', linkTextStatus: true, link: '#', },
    //             { linkText: 'Shayan Mahmud', linkTextStatus: true, link: '#', },
    //             { text: 'Tuition - Plan 1' },
    //             { text: 'Cash' },
    //             { text: '$100' },
    //             { text: '13-Aug-2024' },
    //             { Status: true, background: '#E1F2E8', color: '#0B9444', linebackground: "#41AB37", statusLine: true, label: 'Green', },
    //             { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

    //         ],

    //         //======= Action Date Start===== //
    //         action: [
    //             { label: 'View Details', onClick: handleDrawerOpenDetails },
    //             { label: 'Update Status', onClick: handleDrawerOpenUpdateStatus },
    //         ],
    //         //======= Action Date End===== //
    //     },

    //     {
    //         cell: [
    //             { linkText: 'Abdullah Mahmud', linkTextStatus: true, link: '#', },
    //             { linkText: 'Shayan Mahmud', linkTextStatus: true, link: '#', },
    //             { text: 'Tuition - Plan 1' },
    //             { text: 'Cash' },
    //             { text: '$100' },
    //             { text: '13-Aug-2024' },
    //             { Status: true, background: '#E8EAED', color: '#001233', linebackground: "#979DAC", statusLine: true, label: 'Due', },
    //             { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

    //         ],

    //         //======= Action Date Start===== //
    //         action: [
    //             { label: 'View Details', onClick: handleDrawerOpenDetails },
    //             { label: 'Update Status', onClick: handleDrawerOpenUpdateStatus },
    //         ],
    //         //======= Action Date End===== //
    //     },

    //     {
    //         cell: [
    //             { linkText: 'Abdullah Mahmud', linkTextStatus: true, link: '#', },
    //             { linkText: 'Shayan Mahmud', linkTextStatus: true, link: '#', },
    //             { text: 'Tuition - Plan 1' },
    //             { text: 'Cash' },
    //             { text: '$100' },
    //             { text: '13-Aug-2024' },
    //             { Status: true, background: '#F7E4E4', color: '#001233', linebackground: "#BA1A1A", statusLine: true, label: 'Failed', },
    //             { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

    //         ],

    //         //======= Action Date Start===== //
    //         action: [
    //             { label: 'View Details', onClick: handleDrawerOpenDetails },
    //             { label: 'Update Status', onClick: handleDrawerOpenUpdateStatus },
    //         ],
    //         //======= Action Date End===== //
    //     },
    // ];




    const tabData = [
        { lebel: "Overview", },
        { lebel: "Payment method", },
        { lebel: "Timeline", },
        { lebel: "Related payments", },
    ]

    const panelTab = [
        {
            id: 0,
            panleBody: <Overview transactionsDetails={transactionsDetails} coupons={coupons} handleDrawerOpenUpdateStatus={handleDrawerOpenUpdateStatus} />,
        },

        {
            id: 1,
            panleBody: <PaymentMethod />,
        },

        {
            id: 2,
            panleBody: <Timeline />,
        },

        {
            id: 3,
            panleBody: <RelatedPayments />,
        },
    ]



    const UpdateStatus = [
        {
            title: <img src={ZelleIcon} alt="zelleIcon" width={30} height={30} />,
            description: "Zelle",
        },

        {
            title: <img src={CheckIcon} alt="zelleIcon" width={30} height={30} />,
            description: "Cheque",
        },
        {
            title: <img src={CashIcon} alt="zelleIcon" width={30} height={30} />,
            description: "Cash",
        },
    ]


    const UpdateStatusPanel = [
        {
            id: 0,
            panleBody: <Zelle />,
        },

        {
            id: 1,
            panleBody: <Check />,
        },

        {
            id: 2,
            panleBody: <Cash />,
        },
    ]




    return (
        <>
            <Table
                title="Transactions"
                tableHeader={true}
                headCells={headCells}
                rows={rows}
                FilterButtonTop={true}
                onClickFilterButton={handleFilterDrawerOpen}
                footer={true}
                RowsPerPage={10}

                SearchBoxTop={true}
                handleSearchText={handleSearchText}
                handleSearch={handleSearch}
                setResetSearchTerm={setResetSearchTerm}
                setSearchTerm={setSearchTerm}
                resetsearchTerm={resetsearchTerm}
                pageNumberFromOutSide={pageNumberFromOutSide}

                emptyTableTitle="No users added yet"
                emptyTableNote="No items available"
                btnLabel="ADD USERS"
                showButton={true}
                btnLink="/invited-users"
            //onClickEmptyCard={handleOpenAttendanceEntry}

            />




            {/* Filter */}
            <SideDrawer
                open={openFilterDrawer}
                handleDrawerClose={handleCloseFilterDrawer}
                handleDrawerCloseButtonBottom={handleCloseFilterDrawer}
                title="Filter"
                buttonLabel="Apply"
                sideOpen="right"
                ButtonLabel="Apply"
                BtnColor="#0450E1"
                BtnTextcolor="#fff"
                cancelButtonLabel="Clear Filters"
                FooterWithoutTab={true}
                clickSaveButton={(e) => filterHandler()}
                BackArrow={false}


                body={
                    <>
                        <Filter
                            label="Status"
                            // checkboxesData={status}
                            streams={streams}
                            setStream={setStream}
                            userTypes={userTypes}
                            setUserTypes={setUserTypes}
                            roles={roles}
                            attendanceCodeOptionsUnfiltered={attendanceCodeOptionsUnfiltered}
                            attendanceCodeOptions={attendanceCodeOptions}

                        />
                    </>
                }
            />


            {/* DETAILS DRAWER*/}
            <SideDrawer
                open={openDetails}
                handleDrawerClose={handleDrawerCloseDetails}
                title={<div className='DrawerPriceHeader'>{capitalizeWords(transactionsDetails?.name)} <CircleIcon /> ${`${(transactionsDetails?.amount)} USD`}
                    {(transactionsDetails?.status === "due") && (<div className="DrawerChipBox DrawerGreyChip"><span></span>Due</div>)}
                    {(transactionsDetails?.status === "success" || transactionsDetails?.status === "active") && (<div className="DrawerChipBox DrawerGreenChip"><span></span>{capitalizeWords(transactionsDetails?.status === "success" ? "Paid" : transactionsDetails?.status)}</div>)}
                </div>}
                sideOpen="right"
                ButtonLabel="Close"
                BtnColor="#0450E1"
                BtnTextcolor="#fff"
                // cancelButtonLabel="Cancel"
                FooterWithoutTab={true}
                clickSaveButton={(e) => filterHandler()}
                BackArrow={false}
                TabsStatus={true}
                TabsPannelStatus={true}
                panelTab={panelTab}
                tabData={tabData}
                padding="0"
            />


            {/* UPDATE STATUS*/}
            <SideDrawer
                open={openUpdateStatus}
                handleDrawerClose={handleDrawerCloseUpdateStatus}
                title={<div className='DrawerPriceHeader'>Tuition - Plan 1 <CircleIcon /> $100 USD
                    <div className="DrawerChipBox DrawerGreyChip"><span></span>Due</div>
                    <div className="DrawerChipBox DrawerGreenChip"><span></span>Paid</div>
                </div>}
                sideOpen="right"
                ButtonLabel="Close"
                BtnColor="#0450E1"
                BtnTextcolor="#fff"
                // cancelButtonLabel="Cancel"
                FooterWithoutTab={true}
                clickSaveButton={(e) => filterHandler()}
                BackArrow={false}
                CheckBoxTabsStatus={true}
                TabsPannelStatus={true}
                panelTab={UpdateStatusPanel}
                tabData={UpdateStatus}
            />

        </>
    );
};

export default Transactions;
