import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormHelperText, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import './SelectField.css';

function SelectField(props) {
  // const [age, setAge] = React.useState('');
  // const handleChanges = (event) => {
  //   setAge(event.target.value);
  // };

  // const options = [
  //   {
  //     label: "Twenty",
  //     value: "Twenty",
  //   },

  //   {
  //     label: "Twentydd",
  //     value: "Twentyd",
  //   },

  // ]

  //   < SelectField
  // label = "Month"
  //FieldLabel={true}
  // options = { options }
  // handleChange = { handleChanges }
  //   />

  const {
    label,
    validation,
    required,
    options,
    selectedOption,
    handleChange,
    background,
    error,
    helperText,
    name,
    isDisabled,
    placeholder,
    errorBorderColor

  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState('');
  const [selectedOpt, setSelectedOpt] = useState("")

  useEffect(() => {
    if (validation) {
      if (!selectedOption) {
        setValidationError(true);
        setValidationText('Required');
      } else {
        setValidationError(false);
        setValidationText('');
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (validation) {
      if (selectedOption === undefined || selectedOption === "") {
        setValidationError(true);
        setValidationText('Required');
      } else {
        setValidationError(false);
        setValidationText('');
      }
    }
  }, [selectedOption]);

  // console.log("selectField", selectedOption, 22)

  return (
    <div className="SelectFiled">
      {props.FieldLabel && (
        <div className="FieldLabel">
          {label} {(validation || required) && <span>*</span>}
        </div>
      )}
      <FormControl error={error || validationError} className={props.SelectError}>
        <InputLabel className='SelectFieldPlaceHolder'>{props.placeholder}</InputLabel>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={selectedOption === "" || selectedOption === undefined ? "default" : selectedOption}
          onChange={handleChange}
          error={error || validationError}
          name={name}
          disabled={isDisabled}
          defaultValue={props.defaultValue}
        // defaultValue="default"
        >
          {/* <MenuItem disabled value="">
            {placeholder || 'Select'}
          </MenuItem> */}
          <MenuItem value="default" sx={{ display: "none" }}>
            <span style={{ color: '#C8C6CA' }}>
              {props.selectPlaceholder ? props.selectPlaceholder : 'Select'}
            </span>
          </MenuItem>
          {options?.map((item, i) => (
            <MenuItem key={item.value} value={item.value} style={{ display: "block", padding: "8px 15px" }} className='SelectMenu'>
              {item.label} {props.CurrentChip && <div className='SelectCurrentChip'>Current</div>}
            </MenuItem>
          ))}
          <div onClick={props.onClickAddNew}>
            {props.AddNew &&
              <div className='SelectFieldAddNew' onClick={props.onClickAddNew}><AddRoundedIcon /> Add New</div>
            }
          </div>

        </Select>
        {(helperText || validationText) && (
          <FormHelperText >{helperText || validationText}</FormHelperText>
        )}
      </FormControl>



    </div>
  );
}

export default SelectField;
