import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const tableLeftCellStyle = { color: '#5C677D', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }
const tableRightCellStyle = { color: '#001233', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }
const noDataSpanStyle = { color: '#BBBFC9', fontSize: '16px', fontWeight: 400 }

const GuardiansDetailsContact = ({ userDetails }) => {
    return (
        <>   <TableContainer component={Paper} sx={{ mt: 2, "&.MuiPaper-root": { boxShadow: 'none' } }}>
            <Table aria-label="simple table" sx={{ width: '100% ' }} >
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ fontSize: '18px', fontWeight: '600', color: '#1B1B1F' }}>Contact</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="left" sx={tableLeftCellStyle}>Preferred contact</TableCell>
                        <TableCell align="left" sx={tableRightCellStyle}>{userDetails?.preferredContact || (
                            <Typography sx={noDataSpanStyle}>No data available</Typography>
                        )}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={tableLeftCellStyle}>Email</TableCell>
                        <TableCell align="left" sx={tableRightCellStyle}> {userDetails?.email || (
                            <Typography sx={noDataSpanStyle}>No data available</Typography>
                        )}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={tableLeftCellStyle}>Cell/Mobile Phone</TableCell>
                        <TableCell align="left" sx={tableRightCellStyle}> +{userDetails?.cellPhone || (
                            <Typography sx={noDataSpanStyle}>No data available</Typography>
                        )}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={tableLeftCellStyle}>Work Phone</TableCell>
                        <TableCell align="left" sx={tableRightCellStyle}>+{userDetails?.workPhone || (
                            <Typography sx={noDataSpanStyle}>No data available</Typography>
                        )}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={tableLeftCellStyle}>Home Phone</TableCell>
                        <TableCell align="left" sx={tableRightCellStyle}>+{userDetails?.homePhone || (
                            <Typography sx={noDataSpanStyle}>No data available</Typography>
                        )}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer></>
    )
}

export default GuardiansDetailsContact