import React, { useState, useCallback } from 'react';
import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '../../../components/Form/TextField/TextField'
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import Buttons from '../../../components/Form/Button/Button';
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import ComboBox from '../../../components/Form/ComboBox/ComboBox';
import { dateOptions, getDataMap, getDates, getDates2, getMonths, getYears } from "../../../hooks/HelperFunctions";
import { getProfileAvatarFromName, mapMonth, monthArray, daysArray } from "../../../utils/helpers";
import { countries } from "../../../assets/data/countries";
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';
import GooglePlaceField from '../../../components/Form/GooglePlaceField/GooglePlaceField';
import { useMemo } from 'react';
import moment from 'moment-timezone';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import axios from 'axios';
import config from "../../../config";

import Cropper from 'react-easy-crop';
import getCroppedImg from "../../../utils/extra";

import WebcamComponent from "../../../components/Webcam/WebcamComponent";

import { useParams } from "react-router-dom";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useEffect } from 'react';
import { toast } from 'react-toastify';


import SelectField from '../../../components/Form/SelectField/SelectField';

const UsersProfileEdit = (props) => {

    const { id } = useParams();

    const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, birthDate,
        setBirthDate,
        birthMonth,
        setBirthMonth,
        birthYear,
        setBirthYear, manualAddress, setManualAddress, errors, birthDayError } = props;

    const [selectedValue, setSelectedValue] = React.useState(false);
    const [openSelectMedia, setOpenSelectMedia] = useState(false);
    const [openNewMedia, setOpenNewMedia] = useState(false);
    const [mediaItem, setMediaItem] = useState({});
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);


    const getMediaItem = (item) => {
        // setting the media item
        setMediaItem(item);
    };

    const handleMediaUploadClose = () => {
        setOpenNewMedia(false);
    };

    const handleOpenNewMedia = (status) => {
        setOpenNewMedia(!status);
        handleDrawerOpenSelectMedia(status);
    };

    const handleDrawerOpenSelectMedia = () => {
        setOpenSelectMedia(true);
    };

    const handleDrawerOpenNewMedia = () => {
        console.log("handleDrawerOpenNewMedia");
        setOpenNewMedia(true);
    };

    const handleChanges = (fieldName) => (event) => {
        const fieldData = event.target.value;
        if (fieldName === "ext" && fieldData?.length > 5) {
            return false;
        }
        setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
        // setDisableSaveBtn(false);
    };



    const clickSelectMediaItem = () => {
        //check if any item is selected
        if (selectedValue) {
            if (mediaItem?.mimeType?.startsWith("image/")) {
                let temp = { ...personalInfo };
                temp.profilePic = mediaItem.url;
                temp.imageName = mediaItem.name;
                setPersonalInfo(temp);
                setOpenSelectMedia(false)
            } else {
                toast.error("Only image file can be accepted")
            }
        } else {
            alert("Please select any media");
        }
    };


    const handleChangeWithData = (fieldName, fieldData) => {
        setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
        // setDisableSaveBtn(false);
    };

    const handleChangesSelect = (e, fieldName) => {
        console.log(e, fieldName);
        setPersonalInfo({ ...personalInfo, [fieldName]: e.target.value });
    }

    //handleDrawerCloseSelectMedia
    const handleDrawerCloseSelectMedia = () => {
        setOpenSelectMedia(false);
    };

    const highestEducation = [
        { label: 'None', value: 'None' },
        { label: 'Less than high school diploma (or equivalent)', value: 'Less than high school diploma (or equivalent)' },
        { label: 'High school diploma (or equivalent)', value: 'High school diploma (or equivalent)' },
        { label: 'Some college but no degree', value: 'Some college but no degree' },
        { label: 'Associate Degree (e.g., AA, AS)', value: 'Associate Degree (e.g., AA, AS)' },
        { label: "Bachelor's degree (e.g., BA, AB, BS)", value: "Bachelor's degree (e.g., BA, AB, BS)" },
        { label: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)", value: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)" },
        { label: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0", value: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0" },
        { label: "Doctorate degree (e.g., PhD, EdD)", value: "Doctorate degree (e.g., PhD, EdD)" },
    ]
    // useMemo(() => {
    //     const names = moment.tz.names();
    //     let offsetTmz = [];

    //     for (let i in names) {
    //         offsetTmz = [...offsetTmz, `${names[i]} (GMT${moment.tz(names[i]).format('Z')})`]
    //         console.log(`${names[i]} (GMT${moment.tz(names[i]).format('Z')})`, 345)
    //     }

    //     // console.log({ offsetTmz })
    //     setTimeZoneOptions(offsetTmz.map(item => ({ label: item, value: item })))
    // }, [])
    useMemo(() => {
        const names = moment.tz.names();
        let groupedTmz = {};

        // Group timezones by the first letter
        names.forEach(name => {
            const offset = moment.tz(name).utcOffset();
            const firstLetter = name[0].toUpperCase();
            if (!groupedTmz[firstLetter]) {
                groupedTmz[firstLetter] = [];
            }
            groupedTmz[firstLetter].push({ name, offset, formatted: `${name} (GMT${moment.tz(name).format('Z')})` });
        });

        // Sort each group by GMT offset
        Object.keys(groupedTmz).forEach(letter => {
            groupedTmz[letter].sort((a, b) => a.offset - b.offset);
        });

        // Flatten the grouped and sorted timezones into a single array
        let offsetTmz = [];
        Object.keys(groupedTmz).sort().forEach(letter => {
            offsetTmz = [...offsetTmz, ...groupedTmz[letter]];
        });

        // console.log({ offsetTmz }, 345);
        setTimeZoneOptions(offsetTmz.map(item => ({ label: item.formatted, value: item.formatted })));
    }, []);

    const checkJsonStringOrNot = (address) => {

        if (typeof address === 'string') {

            if (address.length == 0) {
                return "";
            }

            if (/^[\],:{}\s]*$/.test(address.replace(/\\["\\\/bfnrtu]/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

                return JSON.parse(address);

            } else {

                return {};

            }
        } else {
            return address;
        }

    }


    const [imageFromWebcam, setImageFromWebcam] = useState(null);
    const [imageFromCropper, setImageFromCropper] = useState(null);
    const [imageFromCropperSrc, setImageFromCropperSrc] = useState(null);
    const [imageFromCropperName, setImageFromCropperName] = useState("");


    const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);
    const [showTakePhotoModal, setShowTakePhotoModal] = useState(false);


    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState()
    const [cropSize, setCropSize] = useState({ width: 0, height: 0 })
    const [mediaSize, setMediaSize] = useState({
        width: 0,
        height: 0,
        naturalWidth: 0,
        naturalHeight: 0,
    })
    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels)
        }, [])

    const setCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageFromWebcam,
                croppedAreaPixels,
                0
            );
            setImageFromWebcam(null);

            setImageFromCropper(croppedImage);
            setImageFromCropperSrc(URL.createObjectURL(croppedImage));

            const currentDate = new Date();
            const datestamp = `${(currentDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}${currentDate
                    .getDate()
                    .toString()
                    .padStart(2, "0")}${currentDate.getFullYear()}`;

            const firstName = personalInfo?.firstName.toLowerCase();

            const lastName = personalInfo?.lastName.toLowerCase();

            const fileName = `${personalInfo?.userUniqueID}-${firstName}-${lastName}-${datestamp}.jpeg`

            setImageFromCropperName(fileName);



            const formData = new FormData();

            formData.append('file', croppedImage, fileName);

            const apiURL = `${config.api_url}/files/uploadProfileImage/${id}`;

            console.log(apiURL);

            axios.post(apiURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {

                    if (response?.data?.body?.data?.cdnUrl) {
                        if (imageFromCropperSrc) {
                            URL.revokeObjectURL(imageFromCropperSrc)
                            setImageFromCropper(null);
                            setImageFromCropperSrc(null);
                            setImageFromCropperName("");
                        }

                        let temp = { ...personalInfo };
                        temp.profilePic = mediaItem.url;
                        temp.imageName = mediaItem.name;

                        setPersonalInfo(temp);
                    }
                })
                .catch((error) => {
                    console.error('Error uploading image:', error);

                });


            setCrop({ x: 0, y: 0 });
            setZoom(1);



        } catch (e) {
            console.error(e)
        }
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const handleOnClickTakePhoto = () => {
        setShowTakePhotoModal(true);
    }






    const [age, setAge] = React.useState('');
    const handleChangesGraduation = (event) => {
        setAge(event.target.value);
    };
    let graduationlevel = [
        {
            label: "Elementary",
            value: "Elementary",
        },

        {
            label: "Middle School",
            value: "Middle School",
        },
        {
            label: "High School",
            value: "High School",
        },
        {
            label: "Vocational Training",
            value: "Vocational Training",
        },
        {
            label: "Undergraduate Programs",
            value: "Undergraduate Programs",
        },
        {
            label: "Master's Programs",
            value: "Master's Programs",
        },
        {
            label: "Professional Programs",
            value: "Professional Programs",
        },
        {
            label: "Doctoral Programs",
            value: "Doctoral Programs",
        },

    ]
    const [graduationlevelOptions, setGraduationlevelOptions] = useState(graduationlevel)


    useEffect(() => {
        if (personalInfo?.userType === "student") {
            const filterData = graduationlevelOptions?.filter((option) => option?.label === "Elementary" || option?.label === "High School" || option?.label === "Middle School")
            setGraduationlevelOptions(filterData)

        }
    }, [personalInfo?.userType])
    const generateGraduationYears = () => {
        const currentYear = new Date().getFullYear();
        const graduationYears = [];

        for (let i = 0; i <= 12; i++) {
            const year = currentYear + i;
            graduationYears.push({
                label: year.toString(),
                value: year.toString(),
            });
        }

        return graduationYears;
    }
    const graduationyear = [
        {
            label: "2024",
            value: "2024",
        },
        {
            label: "2025",
            value: "2025",
        },
        {
            label: "2026",
            value: "2026",
        },
        {
            label: "2027",
            value: "2027",
        },
        {
            label: "2028",
            value: "2028",
        },
        {
            label: "2029",
            value: "2029",
        },
        {
            label: "2030",
            value: "2030",
        },
        {
            label: "2031",
            value: "2031",
        },
        {
            label: "2032",
            value: "2032",
        },
        {
            label: "2033",
            value: "2033",
        },
        {
            label: "2034",
            value: "2034",
        },
        {
            label: "2035",
            value: "2035",
        },
        {
            label: "2036",
            value: "2036",
        },



    ]

    const contactOptions = [
        {
            label: `${personalInfo?.email} (Email)`,
            value: `${personalInfo?.email} (Email)`,
        },
        ...(personalInfo?.homePhone?.length > 8
            ? [
                {
                    label: `+${personalInfo.homePhone} (Home Phone)`,
                    value: `+${personalInfo.homePhone} (Home Phone)`,
                },
            ]
            : []),
        ...(personalInfo?.workPhone?.length > 8
            ? [
                {
                    label: `+${personalInfo.workPhone} (Work Phone)`,
                    value: `+${personalInfo.workPhone} (Work Phone)`,
                },
            ]
            : []),
        ...(personalInfo?.cellPhone?.length > 8
            ? [
                {
                    label: `+${personalInfo.cellPhone} (Cell Phone)`,
                    value: `+${personalInfo.cellPhone} (Cell Phone)`,
                },
            ]
            : []),
    ];

    const [preferredContactOptions, setPreferredContactOptions] =
        useState(contactOptions);

    useEffect(() => {
        setPreferredContactOptions(contactOptions);
    }, [personalInfo.homePhone, personalInfo.workPhone, personalInfo.cellPhone]);

    useEffect(() => {
        if (personalInfo?.preferredContact) {
            const input = personalInfo.preferredContact;
            const match = input.match(/\(([^)]+)\)/);
            const contactType = match ? match[1] : null;
            const preferredContactNumber = input.split(" (")[0];
            const phoneNumbers = {
                "Home Phone": personalInfo.homePhone,
                "Work Phone": personalInfo.workPhone,
                "Cell Phone": personalInfo.cellPhone,
            };

            if (contactType && phoneNumbers[contactType]) {
                const exactNumber = `+${phoneNumbers[contactType]}`;

                if (preferredContactNumber !== exactNumber) {
                    setPersonalInfo((prev) => ({
                        ...prev,
                        preferredContact: "",
                    }));
                }
            }
        }
    }, [personalInfo]);
    const onChangeHandler = (val, e, fieldName) => {
        let newObject = { ...personalInfo, [fieldName]: e.target.value };
        setPersonalInfo(newObject);
    };

    return (
        <>

            {/* <p><b>ID : {id}</b></p> */}

            <Modal
                disableEnforceFocus
                open={showTakePhotoModal}
                onClose={() => { setShowTakePhotoModal(false) }}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <WebcamComponent width={500} height={400}
                        setImageSrc={setImageFromWebcam}
                        onCaptured={() => {
                            setShowEditPhotoModal(true);
                        }}
                    >

                    </WebcamComponent>

                    {imageFromWebcam != null ?

                        <Modal
                            disableEnforceFocus
                            open={imageFromWebcam != null && showEditPhotoModal}
                            onClose={() => { setShowEditPhotoModal(false) }}
                            aria-labelledby="child-modal1-title"
                            aria-describedby="child-modal1-description"
                        >
                            <Box sx={{ ...style, width: 600 }}>
                                <div
                                    style={{ "position": "relative", width: 500, height: 400 }}
                                >
                                    <Cropper
                                        image={imageFromWebcam}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                        setMediaSize={setMediaSize}
                                        setCropSize={setCropSize}
                                    />
                                </div>

                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => {
                                        setShowEditPhotoModal(false);
                                        setShowTakePhotoModal(false);
                                        setCroppedImage();
                                    }}
                                >
                                    Upload
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => {
                                        setShowEditPhotoModal(false);
                                        setShowTakePhotoModal(true);
                                        setImageFromWebcam(null);
                                    }}
                                >
                                    Retake
                                </Button>

                            </Box>
                        </Modal>

                        : <></>}


                </Box>
            </Modal>


            <div className="ModalForm">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="First name"
                            required={true}
                            helperText={errors?.firstName}
                            error={errors?.firstName}
                            value={personalInfo?.firstName}
                            handleChange={(val, e) => handleChanges("firstName")(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Middle name"
                            required={false}
                            value={personalInfo?.middleName}
                            handleChange={(val, e) => handleChanges("middleName")(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Last name"
                            required={true}
                            helperText={errors?.lastName}
                            error={errors?.lastName}
                            value={personalInfo?.lastName}
                            handleChange={(val, e) => handleChanges("lastName")(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <div class="FieldLabel">Profile photo</div>

                        <div className="UploadPhotoCard">

                            <div className="UploadPhotoCardImg">
                                <Avatar
                                    sx={{ bgcolor: "deepPurple[300]", width: 96, height: 96 }}
                                    src={personalInfo?.profilePic ? personalInfo?.profilePic : ""}
                                >{getProfileAvatarFromName(personalInfo)}</Avatar>
                            </div>

                            {personalInfo?.profilePic && (
                                <div className="UploadPhotoFiled Edituser">
                                    <div className="UploadPhotoImgCol">
                                        <span><img src={personalInfo?.profilePic ? personalInfo?.profilePic : ""} /></span>
                                        <div className="ImgDetails">Profile Image</div>
                                    </div>
                                    <a className="UploadPhotoFiledDelete">
                                        <DeleteForeverRoundedIcon onClick={() => {
                                            let temp = { ...personalInfo };
                                            temp.profilePic = "";
                                            temp.imageName = "";
                                            setPersonalInfo(temp);
                                        }} />
                                    </a>
                                </div>
                            )}


                            {!personalInfo?.profilePic && (

                                <div className="UplaodPhotoModal">
                                    <div>
                                        <Buttons
                                            label="Add from media library"
                                            buttonColor="transparent"
                                            border="#E4E8EC 1px solid"
                                            icon={<AddRoundedIcon />}
                                            color="#004FE0"
                                            width="100%"
                                            height="70px"
                                            fontSize="14px"
                                            link=""
                                            onClick={() => handleDrawerOpenSelectMedia()}
                                        />

                                        <SideDrawer
                                            title="Media Library"
                                            ButtonLabel="Add"
                                            //clickSaveButton={clickSaveButton}
                                            cancelButtonLabel="Cancel"
                                            sideOpen="right"
                                            open={openSelectMedia}
                                            handleDrawerClose={handleDrawerCloseSelectMedia}
                                            FooterWithoutTab={true}
                                            LeftButton={true}
                                            BackArrow={true}
                                            CloseArrowHide={true}
                                            ButtonLeft="Upload New"
                                            ButtonLeftIcon={<FileUploadOutlinedIcon />}
                                            clickButtonLeft={handleDrawerOpenNewMedia}
                                            clickSaveButton={clickSelectMediaItem}
                                            padding="0"
                                            body={
                                                <MediaLibrarySelectMedia
                                                    onClickRadioIcon={setSelectedValue}
                                                    onItemSelected={getMediaItem}
                                                />
                                            }
                                        />

                                        <Externaluse
                                            open={openNewMedia}
                                            handleCloseButton={handleMediaUploadClose}
                                            handleOpenNewMedia={(handleOpenNewMedia)}
                                        />

                                    </div>
                                </div>
                            )}





                        </div>







                        <div className="UplaodPhotoModal">
                            {/* <FileUplaoder
                                        filePath={`students/profile-images/`}
                                        uploadButton="Upload Photo"
                                        // file={personalInfo.image}
                                        fileType="image"
                                        onUpload={(filePath) => handleChangeWithData("image", filePath)}
                                        setSelectedFile={fileSelectedHandler}
                                        helpertext="Maximum size of 10 MB. JPG, JPEG, or PNG."
                                        showUploadButton={false}
                                        showThumb={true}
                                /> */}
                            {/* <Buttons
                                        label="Add From Media Library"
                                        buttonColor="transparent"
                                        border="#E4E8EC 1px solid"
                                        // icon={<AddRoundedIcon />}
                                        color="#004FE0"
                                        width="100%"
                                        height="70px"
                                        fontSize="14px"
                                        link=""
                                        onClick={() => handleDrawerOpenSelectMedia()}
                                /> */}

                        </div>

                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={6}>
                        <div class="FieldLabel"></div>
                        <Buttons
                            label="Take Photo"
                            buttonColor="transparent"
                            border="#E4E8EC 1px solid"
                            icon={<PhotoCameraRoundedIcon />}
                            //textTransform="initial"
                            color="#004FE0"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={() => handleOnClickTakePhoto()}
                        />
                    </Grid> */}


                    {(personalInfo?.userType === 'student') &&
                        <Grid item xs={12}>
                            <TextField
                                label="NYSED ID #"
                                required={false}
                                value={personalInfo?.nysedId}
                                handleChange={(val, e) => handleChanges("nysedId")(e)}
                            />
                        </Grid>
                    }

                    {personalInfo?.userType !== "parent" && (
                        <>
                            <Grid item xs={12} md={6}>
                                <SelectField
                                    label="Graduation level"
                                    FieldLabel={true}
                                    selectedOption={personalInfo?.graduationLevel}
                                    options={graduationlevelOptions}
                                    handleChange={(e) => handleChangesSelect(e, "graduationLevel")}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <SelectField
                                    label="Graduation year"
                                    FieldLabel={true}
                                    selectedOption={personalInfo?.graduationYear}
                                    options={generateGraduationYears()}
                                    handleChange={(e) => handleChangesSelect(e, "graduationYear")}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={12} md={12}>
                        <div className="RedioButton">
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label" className="FieldLabel">Gender</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={personalInfo?.gender}
                                    onChange={handleChanges("gender")}
                                >
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />

                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {/* <SelectField
                                label="Birthday"
                                validation={false}
                                placeholder="Month"
                                options={getMonths()}
                                selectedOption={personalInfo.birthMonth}
                                handleChange={handleChanges("birthMonth")}
                        /> */}

                        <ComboBox
                            label="DOB"
                            placeholder="Month"
                            validation={false}
                            options={getMonths()}
                            selectedOption={birthMonth}
                            error={birthDayError ? true : false}
                            helperText={birthDayError ? "Please select your complete date of birth." : ""}
                            handleChange={(fieldData) => setBirthMonth(fieldData)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>

                        {/* <ComboBox
                            label=""
                            placeholder="Day"
                            validation={false}
                            options={getDates()}
                            selectedOption={birthDate && birthDate}
                            handleChange={(fieldData) => setBirthDate(fieldData)}
                        /> */}
                        {/* <p>BirthDate:  {birthDate}</p> */}
                        <ComboBox
                            label=""
                            placeholder="Day"
                            validation={false}
                            options={dateOptions(birthMonth, birthYear)}
                            selectedOption={birthDate && birthDate}
                            error={birthDayError ? true : false}
                            handleChange={(fieldData) => setBirthDate(fieldData)}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {/* <SelectField
                                placeholder="Year"
                                value={personalInfo.birthYear}
                                handleChange={handleChanges("birthYear")}
                                options={getYears()}
                                selectedOption={personalInfo.birthYear? personalInfo.birthYear : "2007"}
                        /> */}
                        <ComboBox
                            placeholder="Year"
                            validation={false}
                            options={getYears()}
                            selectedOption={birthYear}
                            error={birthDayError ? true : false}
                            handleChange={(fieldData) => setBirthYear(fieldData)}
                        />
                    </Grid>
                    {
                        personalInfo?.userType === "parent" ?
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="FormFieldContainer">
                                        <div className="FloatLabelCol FieldLabel">
                                            Preferred Contact<span></span>
                                        </div>
                                        <div className="FormFieldCol">
                                            <SelectField
                                                label="Preferred Contact"
                                                name="preferredContact"
                                                options={preferredContactOptions}
                                                selectedOption={personalInfo?.preferredContact}
                                                handleChange={(val) =>
                                                    onChangeHandler(val, val, "preferredContact")
                                                }
                                            />
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        label="Email address"
                                        required={false}
                                        value={personalInfo?.email}
                                        handleChange={(val, e) => console.log('not editable')}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <PhoneInputField
                                        LabelStatus={true}
                                        labelFixed="Cell/Mobile phone"
                                        phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                        country={"us"}
                                        value={personalInfo?.cellPhone}
                                        countryCodeEditable={false}
                                        onChange={(value, country, e, formattedValue) =>
                                            handleChangeWithData("cellPhone", value)
                                        }
                                        onBlur={() => console.log("onBlur")}
                                        // onKeyDown={handleChanges("phone")}
                                        variant="outlined"
                                        onlyCountries={["us", "ca", "bd"]}
                                        containerStyle={{}}
                                        buttonStyle={{}}
                                        inputStyle={{
                                            marginLeft: "0px",
                                            height: "40px",
                                            width: "100%",
                                            fontFamily: "Roboto-Regular",
                                            fontSize: "16px",
                                        }}
                                        containerClass={{}}
                                        errorText={"Phone number is required"}
                                        errorStyles={{ marginBottom: "5px" }}
                                        autoFormat={true}
                                        disableDropdown={false}
                                        disabled={false}
                                        disableCountryCode={false}
                                        enableSearch={false}
                                        disableSearchIcon={false}
                                        placeholder={""}
                                        inputProps={{}}
                                        specialLabel={"Primary Phone"}
                                    // preferredCountries={[]}
                                    // excludeCountries={[]}
                                    // labelFixed="Phone number"
                                    // LabelStatus={true}
                                    //helpertext="Helper text"
                                    //error="error"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <PhoneInputField
                                        LabelStatus={true}
                                        labelFixed="Work Phone"
                                        phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                        country={"us"}
                                        value={personalInfo?.workPhone}
                                        countryCodeEditable={false}
                                        onChange={(value, country, e, formattedValue) =>
                                            handleChangeWithData("workPhone", value)
                                        }
                                        onBlur={() => console.log("onBlur")}
                                        // onKeyDown={handleChanges("phone")}
                                        variant="outlined"
                                        onlyCountries={["us", "ca", "bd"]}
                                        containerStyle={{}}
                                        buttonStyle={{}}
                                        inputStyle={{
                                            marginLeft: "0px",
                                            height: "40px",
                                            width: "100%",
                                            fontFamily: "Roboto-Regular",
                                            fontSize: "16px",
                                        }}
                                        containerClass={{}}
                                        errorText={"Phone number is required"}
                                        errorStyles={{ marginBottom: "5px" }}
                                        autoFormat={true}
                                        disableDropdown={false}
                                        disabled={false}
                                        disableCountryCode={false}
                                        enableSearch={false}
                                        disableSearchIcon={false}
                                        placeholder={""}
                                        inputProps={{}}
                                        specialLabel={"Primary Phone"}

                                    />

                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label={<span style={{ display: 'none' }}></span>}
                                        placeholder="Ext."
                                        required={false}
                                        number={true}
                                        value={personalInfo?.ext}
                                        handleChange={(val, e) => handleChanges("ext")(e)}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <PhoneInputField
                                        LabelStatus={true}
                                        labelFixed="Home phone"
                                        phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                        country={"us"}
                                        value={personalInfo?.homePhone}
                                        countryCodeEditable={false}
                                        onChange={(value, country, e, formattedValue) =>
                                            handleChangeWithData("homePhone", value)
                                        }
                                        onBlur={() => console.log("onBlur")}
                                        // onKeyDown={handleChanges("phone")}
                                        variant="outlined"
                                        onlyCountries={["us", "ca", "bd"]}
                                        containerStyle={{}}
                                        buttonStyle={{}}
                                        inputStyle={{
                                            marginLeft: "0px",
                                            height: "40px",
                                            width: "100%",
                                            fontFamily: "Roboto-Regular",
                                            fontSize: "16px",
                                        }}
                                        containerClass={{}}
                                        errorText={"Phone number is required"}
                                        errorStyles={{ marginBottom: "5px" }}
                                        autoFormat={true}
                                        disableDropdown={false}
                                        disabled={false}
                                        disableCountryCode={false}
                                        enableSearch={false}
                                        disableSearchIcon={false}
                                        placeholder={""}
                                        inputProps={{}}
                                        specialLabel={"Primary Phone"}
                                    // preferredCountries={[]}
                                    // excludeCountries={[]}
                                    // labelFixed="Phone number"
                                    //helpertext="Helper text"
                                    //error="error"
                                    />
                                </Grid>
                            </>
                            : <> <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email address"
                                    required={false}
                                    value={personalInfo?.email}
                                    handleChange={(val, e) => console.log('not editable')}
                                    disabled
                                />
                            </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <PhoneInputField
                                        phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                        country={'us'}
                                        value={personalInfo?.phone}
                                        countryCodeEditable={false}
                                        onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", value)}
                                        onBlur={() => console.log("onBlur")}
                                        // onKeyDown={handleChanges("phone")}
                                        variant="outlined"
                                        onlyCountries={["us", "ca", "bd"]}
                                        containerStyle={{}}
                                        buttonStyle={{}}
                                        inputStyle={{
                                            marginLeft: "0px",
                                            height: "40px",
                                            width: "100%",
                                            fontFamily: "Roboto-Regular",
                                            fontSize: "16px",
                                        }}
                                        containerClass={{}}
                                        errorText={"Phone number is required"}
                                        errorStyles={{ marginBottom: "5px" }}
                                        autoFormat={true}
                                        disableDropdown={false}
                                        disabled={false}
                                        disableCountryCode={false}
                                        enableSearch={false}
                                        disableSearchIcon={false}
                                        placeholder={""}
                                        inputProps={{}}
                                        specialLabel={'Primary Phone'}
                                        // preferredCountries={[]}
                                        // excludeCountries={[]}
                                        labelFixed="Phone number"
                                        LabelStatus={true}
                                    //helpertext="Helper text"
                                    //error="error"
                                    />
                                </Grid></>
                    }

                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Select country"
                            validation={true}
                            options={countries}
                            selectedOption={personalInfo?.country}

                            helperText={errors?.country}
                            error={errors?.country}
                            handleChange={(fieldData) => handleChangeWithData("country", fieldData)}
                            placeholder="Select Country"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>

                        <div className='GoogleField'>
                            <TextField
                                label="Street address"
                                required={true}
                                value={personalInfo?.addressLine1}
                                handleChange={(val, e) => handleChanges("addressLine1")(e)}
                                helperText={errors?.addressLine1}
                                error={errors?.addressLine1}
                            />
                            {/* <GooglePlaceField
                                label="Street Address"
                                validation={false}
                                placeholder="Current resident address"
                                value={checkJsonStringOrNot(personalInfo?.address)}
                                handleChange={(address) => handleChangeWithData("address", address)}
                            /> */}
                        </div>

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Apt/Floor"
                            required={false}
                            value={personalInfo?.addressLine2}
                            handleChange={(val, e) => handleChanges("addressLine2")(e)}

                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="City/Town/Village"
                            required={true}
                            value={personalInfo?.city}
                            handleChange={(val, e) => handleChanges("city")(e)}
                            helperText={errors?.city}
                            error={errors?.city}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="State/Province/Territory"
                            required={true}
                            value={personalInfo?.state}
                            handleChange={(val, e) => handleChanges("state")(e)}
                            helperText={errors?.state}
                            error={errors?.state}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Zip/Postal code"
                            required={true}
                            value={personalInfo?.zip}
                            handleChange={(val, e) => handleChanges("zip")(e)}
                            helperText={errors?.zip}
                            error={errors?.zip}
                        />
                    </Grid>





                    {(personalInfo.userType !== 'student') && <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Highest education"
                            validation={false}
                            options={highestEducation}
                            selectedOption={personalInfo?.highestEducation}
                            handleChange={(fieldData) => handleChangeWithData("highestEducation", fieldData)}

                        />
                    </Grid>}
                    {(personalInfo.userType !== 'student') && <Grid item xs={12} sm={12} md={12}>
                        <div className='UperCase'>
                            <TextField
                                label="Occupation"
                                required={false}
                                value={personalInfo?.occupation}
                                handleChange={(val, e) => handleChanges("occupation")(e)}

                            />
                        </div>
                    </Grid>}

                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Select Timezone"
                            validation={true}
                            options={timeZoneOptions}
                            selectedOption={personalInfo?.timezones}
                            handleChange={(fieldData) => handleChangeWithData("timezones", fieldData)}
                            helperText={errors?.timezones}
                            error={errors?.timezones}
                        />

                    </Grid>

                </Grid>
            </div >
        </>
    )
}

export default UsersProfileEdit;
