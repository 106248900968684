import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddCategory from "./AddCategory";
import CircleIcon from '@mui/icons-material/Circle';
import { useAddCalenderCategoryMutation, useCheckCalenderCategoryNameMutation, useDeleteCalenderCategoryMutation, useEditCalenderCalenderMutation } from "../../../redux/features/calender/calenderApi";
import useProtected from "../../../hooks/useProtected";
import DraggableDialog from "../../../components/Dialog/Dialog";
import { toast } from "react-toastify";






const Categories = (props) => {



  const { calenderCategories, userTypes } = props


  const [checkCalenderCategoryName, checkCalenderCategoryNameOptions] = useCheckCalenderCategoryNameMutation()
  const [addCalenderCategory, addCalenderCategoryOptions] = useAddCalenderCategoryMutation()
  const [updateCalenderCategory, updateCalenderCategoryOptions] = useEditCalenderCalenderMutation()
  const [deleteCalenderCategory, deleteCalenderCategoryOptions] = useDeleteCalenderCategoryMutation()

  const [isSaving, setIsSaving] = useState(false);
  useProtected(addCalenderCategoryOptions.error);
  useProtected(updateCalenderCategoryOptions.error);
  useProtected(deleteCalenderCategoryOptions.error);
  useProtected(checkCalenderCategoryNameOptions.error);


  const initialCalenderCategoryObject = {
    "name": "",
    "color": "",
    "userType": [],
  }

  const initialCalenderCategoryErrorObject = {
    "name": "",
    "color": ""
  }

  const [rows, setRows] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const [editId, setEditId] = useState('')
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [calenderCategoryObject, setCalenderCategoryObject] = useState(initialCalenderCategoryObject)
  const [calenderCategoryErrorObject, setCalenderCategoryErrorObject] = useState(initialCalenderCategoryErrorObject)


  const handleDrawerOpenAddCategory = () => {
    setOpenAddCategory(true);
  };
  const handleDrawerCloseAddCategory = () => {
    setIsSaving(false)
    setOpenAddCategory(false);
  };


  useEffect(() => {
    if (calenderCategories?.length > 0) {
      let datas = [...calenderCategories];
      datas.sort((a, b) => {
        return new Date(b?.createdAt) - new Date(a?.createdAt);
      });
      setRows(convertDataToCellRows(datas))
    }
  }, [calenderCategories])

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };

  //======= Delete dialog ===== //

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name', width: '80%', textAlign: 'left', },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Color', width: '10%', textAlign: 'left', },
    { id: 'carbs', numeric: true, disablePadding: false, label: '', width: '10%', },
  ];

  const deleteCategoryClickHandler = (id) => {
    setDeleteId(id);

    handleOpenDelete()
  }

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map((item) => ({
        cell: [
          { text: item?.name || "N/A", },
          { text: <div className="TableTextWithIcon"><div style={{ color: item?.color || "N/A", display: "flex", }}><CircleIcon /></div></div>, },
          { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
        ],

        //======= Action Date Start===== //
        action: [
          { label: 'Edit', onClick: (e) => editCalenderCategoryHandler(item?._id) },
          { label: 'Delete', onClick: (e) => deleteCategoryClickHandler(item?._id) }
        ],
        //======= Action Date End===== //
      }));
    } else {
      return [];
    }

  };

  const closeDeleteDialogHandler = () => {
    setDeleteId('')
    handleCloseDeleteDialog()
  }


  const deleteCategoryConfirmHandler = async () => {
    let id = deleteId

    let result = await deleteCalenderCategory(id)
    console.log(result)
    if (result?.data) {
      toast.success('Category removed');
    } else {
      toast.error(result?.data?.message);
    }
    handleCloseDeleteDialog()
    setDeleteId('')
  }

  const checkNameExist = async () => {
    if (calenderCategoryObject.name === "") {
      return false
    }
    let searchResult = await checkCalenderCategoryName({ name: calenderCategoryObject.name });
    if (searchResult?.data && searchResult.data?.body?.data && searchResult.data?.body?.data?.length > 0) {
      if (searchResult.data?.body?.data[0]?.name === calenderCategoryObject.name && searchResult.data?.body?.data[0]?._id === editId) {
        return false
      }
      return true
    }

    return false
  }

  const isCategoryObjectValid = async () => {
    let error = 0
    let errorObject = { ...calenderCategoryErrorObject }

    if (calenderCategoryObject?.name === "") {
      error++
      errorObject = { ...errorObject, name: "Required" }
    } else {
      const nameExist = await checkNameExist()
      // const nameExist = !editScheduleMode ? await checkNameExist() : false
      if (nameExist) {
        error++
        errorObject = { ...errorObject, name: "The category name is already in use. Choose a difference name" }
      } else {
        errorObject = { ...errorObject, name: "" }
      }

    }

    if (calenderCategoryObject?.color === "") {
      error++
      errorObject = { ...errorObject, color: "Required" }
    } else {
      errorObject = { ...errorObject, color: "" }
    }

    setCalenderCategoryErrorObject({ ...calenderCategoryErrorObject, ...errorObject })

    return error > 0 ? false : true
  }

  const editCalenderCategoryHandler = (id) => {
    setEditId(id)
    handleDrawerOpenAddCategory()
  }

  const addCategoryHandler = async () => {
    if (isSaving) return
    let data = { ...calenderCategoryObject }

    setIsSaving(true)
    // const nameExist = await checkNameExist()
    let isValid = await isCategoryObjectValid()
    if (!isValid) {
      setIsSaving(false)
      return false
    }

    let result = (editId === "") ? await addCalenderCategory(data) : await updateCalenderCategory({ ...data, id: editId })

    if (result?.data && result?.data?.error === false) {
      (editId === "") ? toast.success('Category added') : toast.success('Category updated')

      setCalenderCategoryObject(initialCalenderCategoryObject)
      handleDrawerCloseAddCategory()

      setEditId('')
      setCalenderCategoryErrorObject(initialCalenderCategoryErrorObject)
    }
  }

  useEffect(() => {
    console.log('calenderCategoryObject : ', calenderCategoryObject)
  }, [calenderCategoryObject])


  const CloseAddDrawerHandler = () => {
    handleDrawerCloseAddCategory()
    setEditId('')
    setCalenderCategoryObject(initialCalenderCategoryObject)
    setCalenderCategoryErrorObject(initialCalenderCategoryErrorObject)
  }

  return (
    <>
      <div className="TableSection">
        <Table
          tableHeader={true}
          AddButton={true}
          addLabel="Add Category"
          icon={<AddRoundedIcon />}
          headCells={headCells}
          rows={rows}
          Checkbox={true}
          footer={true}
          RowsPerPage={10}
          onClick={handleDrawerOpenAddCategory}



        />
      </div>


      {/* AddCalendars Drawer */}
      <SideDrawer
        open={openAddCategory}
        handleDrawerClose={(e) => CloseAddDrawerHandler()}
        title={editId !== "" ? "Update Category" : "Add Category"}
        ButtonLabel={editId !== "" ? "Edit now" : "Add now"}
        sideOpen="right"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => addCategoryHandler()}
        BackArrow={true}
        CloseArrowHide={true}
        body={
          <>
            <AddCategory
              editId={editId}
              userTypes={userTypes}
              calenderCategoryObject={calenderCategoryObject}
              setCalenderCategoryObject={setCalenderCategoryObject}
              calenderCategoryErrorObject={calenderCategoryErrorObject}
              setCalenderCategoryErrorObject={setCalenderCategoryErrorObject}
              calenderCategories={calenderCategories} />
          </>
        }
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={(e) => closeDeleteDialogHandler()}
        handleConfirmCloseDialog={(e) => deleteCategoryConfirmHandler()}
        title="Remove category?"
        body={<div>Are you sure you want to remove this category?</div>}
        ModalFooter={true}
        actionButton="REMOVE"
      />



    </>
  );
};

export default Categories;
