import React, { useState, useCallback, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import TextField from '../../../components/Form/TextField/TextField'
import SelectField from '../../../components/Form/SelectField/SelectField'
import Buttons from '../../../components/Form/Button/Button';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CircleIcon from '@mui/icons-material/Circle';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { toast } from 'react-toastify';
import { convertFileSize } from '../../../components/MediaLibrary/Functions';
import emailValidation, { capitalizeWords } from '../../../hooks/HelperFunctions';

import useProtected from '../../../hooks/useProtected';
import { useSaveAccountDataMutation } from '../../../redux/features/fees/feeApi';
import { useSelector } from 'react-redux';
import { useGetSchoolQuery } from '../../../redux/features/schools/schoolApi';

const ACHAuthorizationForm = ({ authorizeAccount, setAuthorizeAccount, errors, handleDrawerClosePayment, setErrors, errorStatus, setErrorStatus, isSaving, setIsSaving, setSaveAccountSuccess, refetch, openSelectMedia, setOpenSelectMedia, openNewMedia, setOpenNewMedia }) => {
    const { schoolId } = useSelector(state => state.auth.user);
    const [schoolInfo, setSchoolInfo] = useState({})
    const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId, {
        skip: !schoolId
    })
    const [saveAccountData, saveAccountDataOptions] = useSaveAccountDataMutation()
    useProtected(error);
    useProtected(saveAccountDataOptions?.error);

    useEffect(() => {
        if (!isLoading && data && !error) {
            setSchoolInfo(data?.body?.data)
        }
    }, [data, error, isLoading])

    const [accountType, setAccountType] = React.useState(authorizeAccount?.accountType);
    const options = [
        {
            label: "Individual",
            value: "Individual",
        },
        {
            label: "Company",
            value: "Company",
        },

    ]

    //* ====================== getting  authorization values ===============================  *//
    const handleSelect = (event) => {
        setAuthorizeAccount({ ...authorizeAccount, accountType: event.target.value });
        setAccountType(event.target.value)
    };
    const handleCheckAuth = (event) => {
        setAuthorizeAccount({
            ...authorizeAccount, checkedAuthorizationForm: event.target.checked
        });
    };
    const handleChanges = (fieldName) => (event) => {
        const fieldData = event.target.value;
        if (fieldName === "routingNumber" || fieldName === "accountNumber" || fieldName === "confirmAccountNumber") {
            const numberRegex = /^[0-9]*\.?[0-9]*$/;
            if (!numberRegex.test(fieldData)) {
                return false;
            }
        }
        setAuthorizeAccount({ ...authorizeAccount, [fieldName]: fieldData });

    };
    //* ====================== getting  authorization values ===============================  */


    //* ====================== MEDIA LIBRARY  ===============================  */
    const [selectedValue, setSelectedValue] = React.useState(false);
    // const [openSelectMedia, setOpenSelectMedia] = useState(false);
    const [mediaItem, setMediaItem] = useState({});

    const handleDrawerOpenSelectMedia = () => {
        setOpenSelectMedia(true);
    };

    const handleDrawerCloseSelectMedia = () => {
        setOpenSelectMedia(false);
        //setOpen(true);
    };

    const handleDrawerOpenNewMedia = () => {
        //setOpen(false);
        setOpenNewMedia(true);
    };

    const handleMediaUploadClose = () => {
        setOpenNewMedia(false);
    };

    const handleOpenNewMedia = (status) => {
        //setOpen(false);
        setOpenNewMedia(!status);
        handleDrawerOpenSelectMedia(status);
    };

    const getMediaItem = (item) => {
        setMediaItem(item);
    };


    const clickSelectMediaItem = () => {

        if (selectedValue) {
            setAuthorizeAccount({
                ...authorizeAccount,
                authorizationForm: mediaItem
            });
            setOpenSelectMedia(false);
            // if (mediaItem?.mimeType?.startsWith("image/")) {
            // } else {
            //     toast.error("Only image file can be accepted")
            // }
        }
    };

    const handleDeleteAuthorizationForm = () => {
        setAuthorizeAccount({
            ...authorizeAccount,
            authorizationForm: ""
        });
    }

    //* ====================== MEDIA LIBRARY  ===============================  */

    //* ====================== check errors ===============================  *//

    useEffect(() => {
        if (errorStatus) {
            isValidAccountData()
        }
    }, [errorStatus, authorizeAccount])
    const isValidAccountData = async () => {
        let error = 0
        let errorObejct = { ...errors }

        if (authorizeAccount?.accountHolderName === "") {
            error++
            errorObejct.accountHolderName = "Please enter a name"
        } else {
            errorObejct.accountHolderName = ""
        }
        if (authorizeAccount?.routingNumber === "") {
            error++
            errorObejct.routingNumber = "Please enter a routing number"
        } else if (authorizeAccount?.routingNumber?.length !== 9) {
            error++
            errorObejct.routingNumber = "Please enter a valid routing number"
        }

        else {
            errorObejct.routingNumber = ""
        }
        if (authorizeAccount?.accountNumber === "") {
            error++
            errorObejct.accountNumber = "Please enter your account number"
        } else if (authorizeAccount?.accountNumber?.length < 4 || authorizeAccount?.accountNumber?.length > 17) {
            error++
            errorObejct.accountNumber = "Please enter a valid account number"
        }
        else {
            errorObejct.accountNumber = ""
        }
        if (authorizeAccount?.accountNumber !== authorizeAccount?.confirmAccountNumber && authorizeAccount?.accountNumber) {
            error++
            errorObejct.confirmAccountNumber = "Your account numbers don't match"
        } else {
            errorObejct.confirmAccountNumber = ""
        }
        if (authorizeAccount?.email && !emailValidation(authorizeAccount?.email)) {
            error++
            errorObejct.email = "Enter a valid email"
        } else {
            errorObejct.email = ""
        }
        if (!authorizeAccount?.checkedAuthorizationForm) {
            error++
            errorObejct.checkedAuthorizationForm = "Check this box to authorize"
        } else {
            errorObejct.checkedAuthorizationForm = ""
        }

        setErrors({ ...errors, ...errorObejct })
        return error > 0 ? false : true
    }

    //* ====================== check errors ===============================  *//

    //* ====================== save authorizeAccount data ===============================  *//
    const handleSaveAccount = async () => {
        setErrorStatus(true)
        if (isSaving) return
        const isValid = await isValidAccountData()
        if (!isValid) {
            setIsSaving(false)
            return false
        }
        let data = {
            ...authorizeAccount,
            accountType: authorizeAccount?.accountType?.toLowerCase()
        }
        delete data.confirmAccountNumber;

        try {
            const result = await saveAccountData(data)
            if (!result?.data?.error && result?.data?.body?.data) {
                refetch()
                toast.success("Account Authorized successfully")
                setSaveAccountSuccess(true)
                handleDrawerClosePayment()
            } else {
                toast.error(result?.data?.message)
            }
        } catch (error) {
            console.log(error?.message)
        }

    }
    //* ====================== save authorizeAccount data ===============================  *//
    return (
        <>

            <Grid container spacing={2}>

                <Grid item xs={12} md={12}>
                    <SelectField
                        label={<span style={{ fontWeight: 400, color: '#33415C' }}>Account holder type</span>}
                        required={true}
                        FieldLabel={true}
                        options={options}
                        selectedOption={accountType}
                        handleChange={handleSelect}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={<span style={{ fontWeight: 400, color: '#33415C' }}>Account holder name</span>}
                        placeholder="Full name"
                        required={true}
                        error={errors?.accountHolderName}
                        helperText={errors?.accountHolderName ? errors?.accountHolderName : <span style={{ fontSize: '12px', color: '#7D8597' }}>Must match the name on your bank account exactly</span>}
                        value={authorizeAccount?.accountHolderName}
                        handleChange={(val, e) => handleChanges("accountHolderName")(e)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={<span style={{ fontWeight: 400, color: '#33415C' }}>Routing no.</span>}
                        placeholder="110000000"
                        required={true}
                        number="number"
                        helperText={errors?.routingNumber}
                        error={errors?.routingNumber}
                        value={authorizeAccount?.routingNumber}
                        handleChange={(val, e) => handleChanges("routingNumber")(e)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={<span style={{ fontWeight: 400, color: '#33415C' }}>Account no.</span>}
                        number="number"
                        required={true}
                        placeholder="000123456789"
                        helperText={errors?.accountNumber}
                        error={errors?.accountNumber}
                        value={authorizeAccount?.accountNumber}
                        handleChange={(val, e) => handleChanges("accountNumber")(e)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={<span style={{ fontWeight: 400, color: '#33415C' }}>Confirm account no.</span>}
                        required={true}
                        number="number"
                        placeholder="000123456789"
                        helperText={errors?.confirmAccountNumber}
                        error={errors?.confirmAccountNumber}
                        value={authorizeAccount?.confirmAccountNumber}
                        handleChange={(val, e) => handleChanges("confirmAccountNumber")(e)}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                        label={<span style={{ fontWeight: 400, color: '#33415C' }}>Email address</span>}
                        placeholder="Email address"
                        helperText={errors?.email}
                        error={errors?.email}
                        value={authorizeAccount?.email}
                        handleChange={(val, e) => handleChanges("email")(e)}
                    />
                </Grid>



                <Grid item xs={12} sm={12} md={12}>
                    {
                        authorizeAccount?.authorizationForm ? <>
                            <div style={{ fontSize: '12px', fontWeight: '400', color: '#33415C' }}>Upload authorization form</div>

                            <div className='UploadedDetails'>

                                <div className='UploadedDetailsLeft'>
                                    <div className='UploadedDetailIcon'><AttachmentIcon /></div>
                                    <div className='UploadedDetailText'>
                                        <h1>{capitalizeWords(authorizeAccount?.authorizationForm?.name)}</h1>
                                        <p>{convertFileSize(authorizeAccount?.authorizationForm?.size)}<CircleIcon />{authorizeAccount?.authorizationForm?.originalFileName}</p>
                                    </div>
                                </div>

                                <div className='UploadedDetailsRight'>
                                    <Button color="inherit" size="small">
                                        <DeleteForeverIcon onClick={handleDeleteAuthorizationForm} />
                                    </Button>
                                </div>

                            </div>
                        </> : <div>
                            <span style={{ fontSize: '12px', fontWeight: '400', color: '#33415C' }}>Upload authorization form</span>
                            <Buttons
                                label="Upload"
                                buttonColor="transparent"
                                border="#E8EAED 1px solid"
                                icon={<AddRoundedIcon />}
                                //textTransform="initial"
                                color="#004FE0"
                                width="100%"
                                height="48px"
                                fontSize="14px"
                                onClick={() => handleDrawerOpenSelectMedia()}
                            />

                        </div>
                    }
                    <Box sx={{ paddingTop: '12px', "& .MuiFormControlLabel-root": { alignItems: 'flex-start', gap: '8px' }, "& .MuiTypography-root": { fontSize: '14px', color: '#1B1B1F' }, "& .MuiCheckbox-root": { padding: '0px 0px 0px 12px' } }}>
                        <FormControlLabel
                            value={authorizeAccount?.checkedAuthorizationForm}
                            control={<Checkbox />}
                            label={`I authorize ${schoolInfo?.name} to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits`}
                            labelPlacement="end"
                            onChange={(e) => handleCheckAuth(e)}
                        />
                    </Box>
                    {
                        errors?.checkedAuthorizationForm !== "" && (<p style={{ color: "#d32f2f", fontSize: '12px' }}>{errors?.checkedAuthorizationForm}</p>)
                    }
                </Grid>

                <SideDrawer
                    title="Media Library"
                    ButtonLabel="Add"
                    cancelButtonLabel="Cancel"
                    sideOpen="right"
                    drawerWidth="696px"
                    open={openSelectMedia}
                    handleDrawerClose={handleDrawerCloseSelectMedia}
                    FooterWithoutTab={true}
                    LeftButton={true}
                    ButtonLeft="Upload New"
                    ButtonLeftIcon={<FileUploadOutlinedIcon />}
                    clickButtonLeft={handleDrawerOpenNewMedia}
                    clickSaveButton={clickSelectMediaItem}
                    body={
                        <MediaLibrarySelectMedia
                            onClickRadioIcon={setSelectedValue}
                            onItemSelected={getMediaItem}
                        />
                    }
                />

                <Externaluse
                    open={openNewMedia}
                    handleCloseButton={handleMediaUploadClose}
                    handleOpenNewMedia={(handleOpenNewMedia)}
                />


                {/* <Grid item xs={12} sm={12} md={12}>
                    <div className='CheckBoxTopAlign'>
                        <CheckBox
                            label="I authorize [School Full Name] to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits"
                            checked={state.checkedB}
                            onChange={handleChangeCheck}
                            color="primary"
                        />
                    </div>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12}>
                    <Buttons
                        label="AUTHORIZE"
                        buttonColor="#0450E1"
                        border="#0450E1 1px solid"
                        // icon={<AddRoundedIcon />}
                        //textTransform="initial"
                        color="#fff"
                        width="100%"
                        height="40px"
                        fontSize="14px"
                        onClick={() => handleSaveAccount()}
                    />
                </Grid>

            </Grid>

        </>
    )
}

export default ACHAuthorizationForm;
