import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CircleIcon from '@mui/icons-material/Circle';



const Timeline = () => {

    const headCells = [

    ];

    const rows = [
        {
            cell: [
                {
                    text: 'A payment of $100 by check was recorded by [Admin FullName]', textcolor: "#5C677D", fontSize: '12px',
                    iconstart: <CircleIcon style={{ fontSize: "6px", marginRight: "8px", }} />,
                },
                { text: '8-Nov-2024, 10:00 AM', fontSize: '12px', textcolor: "#5C677D", TextAlign: "right" },

            ],
        },

        {
            cell: [
                {
                    text: 'Status is updated to Paid by [Admin FullName]', textcolor: "#5C677D", fontSize: '12px',
                    iconstart: <CircleIcon style={{ fontSize: "6px", marginRight: "8px", }} />,
                },
                { text: '8-Nov-2024, 10:00 AM', fontSize: '12px', textcolor: "#5C677D", TextAlign: "right" },

            ],
        },

        {
            cell: [
                {
                    text: 'Third payment attempt has failed', textcolor: "#5C677D", fontSize: '12px',
                    iconstart: <CircleIcon style={{ fontSize: "6px", marginRight: "8px", }} />,
                },
                { text: '8-Nov-2024, 10:00 AM', fontSize: '12px', textcolor: "#5C677D", TextAlign: "right" },

            ],
        },

        {
            cell: [
                {
                    text: '2nd payment attempt has succeeded/failed', textcolor: "#5C677D", fontSize: '12px',
                    iconstart: <CircleIcon style={{ fontSize: "6px", marginRight: "8px", }} />,
                },
                { text: '8-Nov-2024, 10:00 AM', fontSize: '12px', textcolor: "#5C677D", TextAlign: "right" },

            ],
        },

        {
            cell: [
                {
                    text: 'First payment attempt has failed', textcolor: "#5C677D", fontSize: '12px',
                    iconstart: <CircleIcon style={{ fontSize: "6px", marginRight: "8px", }} />,
                },
                { text: '8-Nov-2024, 10:00 AM', fontSize: '12px', textcolor: "#5C677D", TextAlign: "right" },

            ],
        },

        {
            cell: [
                {
                    text: 'A $100 USD invoice was created by [Admin FullName]', textcolor: "#5C677D", fontSize: '12px',
                    iconstart: <CircleIcon style={{ fontSize: "6px", marginRight: "8px", }} />,
                },
                { text: '8-Nov-2024, 10:00 AM', fontSize: '12px', textcolor: "#5C677D", TextAlign: "right" },

            ],
        },
    ];

    return (
        <>

            <Table
                headCells={headCells}
                rows={rows}
                footer={true}
                innerHeader={true}
                innerTitle={<span style={{ fontSize: '20px' }}>Timeline</span>}
                RowsPerPage={10}

                emptyTableTitle="No users added yet"
                emptyTableNote="No items available"
                btnLabel="ADD USERS"
                showButton={true}
                btnLink="/invited-users"
            //onClickEmptyCard={handleOpenAttendanceEntry}


            />

        </>
    );
};

export default Timeline;
