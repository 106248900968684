import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import SelectField from '../Form/SelectField/SelectField';
import Tooltips from '@mui/material/Tooltip';
import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded';
import "./EditAbleTable.css"
import { TextField } from '@mui/material';




// const [age, setAge] = React.useState('');
// const handleChanges = (event) => {
//   setAge(event.target.value);
// };

// const options = [
//   {
//     label: "Twenty",
//     value: "Twenty",
//   },

//   {
//     label: "Twentydd",
//     value: "Twentyd",
//   },

// ]


function EditAbleTableCell(props) {






  return (
    <>
      {
        props.cell.map((item, i) => (
          <TableCell key={i}>

            {item.TextField && (
              <div className="EditAbleTableCell">
                <div className={item.error}>

                  <input
                    type={item.type}
                    ref={item.ref}
                    id={item?.id}
                    // type='email'
                    className="EditAbleTableTextField"
                    // value={item.value}
                    value={item.TextFieldValue}
                    placeholder={item.placeholder}
                    style={{ textTransform: item.textTransform, }}
                    onChange={item.onChange}
                    autocomplete={item?.autocomplete ? item?.autocomplete : "off"}
                    onBlur={(e) => {
                      if (item.onBlur) {
                        item.onBlur(e)
                      }
                    }}
                  />
                  {
                    item.errorTooltip && (
                      <div className='errorTooltip'>
                        <Tooltips
                          title={item.tooltipMessage}
                          placement="bottom"
                          arrow
                        >
                          <div><AnnouncementRoundedIcon fontSize='small' sx={{ color: '#BA1A1A !important' }} /></div>
                        </Tooltips>
                      </div>
                    )
                  }
                </div>
              </div>
            )}

            {item.SelectFiled && (
              <span className="EditAbleTableSelectFiled" >
                <SelectField
                  isDisabled={item.isDisabled}
                  selectedOption={item.selectedOption}
                  options={item.options}
                  handleChange={item.SelectHandleChanges}
                  label={item.placeholder}
                  error={item.error}
                  SelectError={item.SelectError}
                  placeholder={item.placeholder}
                // errorBorderColor="#ba1b1a"
                />
              </span>
            )}
            {item.RemoveButton && (
              <div className="EditAbleTableField">
                {props.RemoveButton}
              </div>
            )}

          </TableCell>
        ))
      }
    </>
  );
}

export default EditAbleTableCell;
